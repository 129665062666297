import styled, { css } from 'styled-components';
import { useTheme } from 'styled-components';

const SquareButton = ({
  colorType,
  text,
  onClick,
}: {
  colorType: 'ACTIVE' | 'DISACTIVE' | 'ACTIVE_WARN';
  text: string;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const color =
    colorType === 'ACTIVE'
      ? {
          bgColor: theme.colors.blue,
          borderColor: theme.colors.blue,
          bgColorHover: theme.colors.blue_hover,
          borderColorHover: theme.colors.blue_hover,
          fontColor: theme.colors.white,
        }
      : colorType === 'ACTIVE_WARN'
      ? {
          bgColor: theme.colors.grapeFruit,
          borderColor: theme.colors.grapeFruit,
          bgColorHover: theme.colors.grapeFruit_hover,
          borderColorHover: theme.colors.grapeFruit_hover,
          fontColor: theme.colors.white,
        }
      : {
          bgColor: theme.colors.white,
          borderColor: theme.colors.charcoal,
          bgColorHover: theme.colors.white,
          borderColorHover: theme.colors.charcoal,
          fontColor: theme.colors.charcoal,
        };

  return (
    <Container color={color} onClick={onClick}>
      {text}
    </Container>
  );
};

export default SquareButton;

const Container = styled.div<{
  color: {
    bgColor: string;
    borderColor: string;
    fontColor: string;
    bgColorHover: string;
    borderColorHover: string;
  };
}>`
  width: 100px;
  height: 25px;
  display: flex;
  font-size: ${({ theme }) => theme.fontsize.header_semi};
  font-weight: 800;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color.bgColor};
  border: ${({ color }) => `1px solid ${color.borderColor}`};
  color: ${({ color }) => color.fontColor};
  cursor: pointer;

  &:hover {
    background-color: ${({ color }) => color.bgColorHover};
    border-color: ${({ color }) => color.borderColorHover};
  }
`;
