import {
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { orderData, OrderHeaderType } from '../TableTestdata';
import {
  ColumnDef,
  Row,
  RowData,
  TableMeta,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as Styled from '../TableStyled';
import TableConfig from '../TableConfig';
import { useHeaderButtons } from '../../header/HeaderButtonProvider';
import SquareButton from '../../button/SquareButton';
import { AiFillCaretRight } from 'react-icons/ai';
import { AiFillCaretDown } from 'react-icons/ai';
import ListTooltip from '../../common/ListTooltip';
import { ValueWithKey } from '../TableTestdata';
import { traderData } from '../TableTestdata';
import TraderUploadModal from '../../common/modal/ModalComponents/TraderUploadModal';
import axios from 'axios';
import { useTheme } from 'styled-components';
import { toast } from 'react-hot-toast';
import {
  keyMapping,
  orderListGetApi,
  orderListPostApi,
  OrderUploadResponseType,
  traderListGetApi,
  traderListPostApi,
  TraderType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { OrderUploadModal } from '../../common/modal/ModalComponents/OrderUploadModal';
//주문번호 예시 20241104-1

import { BsChevronDoubleLeft } from 'react-icons/bs';
import { BsChevronDoubleRight } from 'react-icons/bs';
import { BsChevronLeft } from 'react-icons/bs';
import { BsChevronRight } from 'react-icons/bs';

import {
  OrderListType,
  OrderListUpdateRequestDto,
} from '../../../api/apiConfig';
import { PaginationState } from '../Pagination';
import {
  formatCurrency,
  formatEightDigitStringToSlashForm,
  getYearMonthDateFromDate,
} from '../../../function/replaceString';

function mapKey(inputKey: string): keyof OrderListType | undefined {
  return keyMapping[inputKey];
}

export const OrderTable = () => {
  const theme = useTheme();
  const { setButtons } = useHeaderButtons();

  const { fetch: apiGetOrderList } = useFetch({
    fetchFunction: orderListGetApi,
    onSuccess: (data: OrderListType[]) => setData(data),
  });

  const { fetch: apiPostOrderList } = useFetch({
    fetchFunction: orderListPostApi,
    onSuccess: (data: OrderUploadResponseType) => {
      setUpdatedData(data);
      // toast('업데이트에 성공하였습니다.');
      // setData(data);
    },
  });

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const [modalType, setModalType] = useState<'edit' | 'add' | null>(null);

  //모든 테이블값
  const [data, setData] = useState<OrderListType[]>([]);
  //엑셀 추출값
  const [excelData, setExcelData] = useState<OrderListType[]>([]);
  //업데이트 리스폰스로서 하이라이트용도
  const [updatedData, setUpdatedData] =
    useState<OrderUploadResponseType | null>(null);

  //테이블 옵션값 ~~~
  const [headerOverflowType, setHeaderOverflowType] = useState<'wrap' | 'hide'>(
    'hide'
  );
  const [bodyOverflowType, setBodyOverflowType] = useState<'wrap' | 'hide'>(
    'hide'
  );
  const [bodyAlignHoriType, setBodyAlignHoriType] = useState<
    'left' | 'center' | 'right'
  >('left');

  const [cellHoriPaddValue, setCellHoriPaddValue] = useState<number>(5);
  const [bodyVertPaddValue, setBodyVertPaddValue] = useState<number>(5);

  const [showConfig, setShowConfig] = useState<boolean>(false);

  async function groupOrderWithItemSubrow(data: OrderListType[]) {
    const groupedOrders = data.reduce<OrderListType[]>((acc, order) => {
      const {
        PROD_DES,
        SIZE_DES,
        PROD_CD,
        CLASS_CD,
        CLASS_CD_DES,
        CLASS_CD2,
        CLASS_CD2_DES,
        CLASS_CD3,
        CLASS_CD3_DES,
        QTY,
        PRICE,
        SUPPLY_AMT,
        ORDER_ID,
        ...rest
      } = order;

      // 이미 ORDER_ID가 존재하는 항목을 찾아서 해당 `subRows`에 품목 정보를 추가
      const existingOrder = acc.find((item) => item.ORDER_ID === ORDER_ID);
      if (existingOrder) {
        if (
          existingOrder.subRows?.some((subrow) => subrow.PROD_CD === PROD_CD)
        ) {
          existingOrder.duplicated = true;
        }
        existingOrder.subRows?.push({
          PROD_DES,
          SIZE_DES,
          PROD_CD,
          CLASS_CD,
          CLASS_CD_DES,
          CLASS_CD2,
          CLASS_CD2_DES,
          CLASS_CD3,
          CLASS_CD3_DES,
          QTY,
          PRICE,
          SUPPLY_AMT,
          subRows: [], // 빈 배열로 초기화
        });
      } else {
        // 없으면 새로운 항목 추가
        acc.push({
          ORDER_ID,
          duplicated: false,
          subRows: [
            {
              PROD_DES,
              SIZE_DES,
              PROD_CD,
              CLASS_CD,
              CLASS_CD_DES,
              CLASS_CD2,
              CLASS_CD2_DES,
              CLASS_CD3,
              CLASS_CD3_DES,
              QTY,
              PRICE,
              SUPPLY_AMT,
              subRows: [], // 빈 배열로 초기화
            },
          ],
          ...rest, // 나머지 주문 정보
        });
      }

      return acc;
    }, []);
    return groupedOrders;
  }

  // useEffect(() => {
  //   if (excelData.length > 0) {
  //   }
  // }, [excelData]);

  useEffect(() => {
    // if (updatedData.length > 0) {
    //   setData((prev) => {
    //     //변경된(response응답받은) 데이터들의 거래처코드 저장
    //     const updatedCodes = new Set(updatedData.map((data) => data.ORDER_ID));

    //     //기존 항목에서 필터링. 변경사항들의 인덱스를 나란히 유지하기위해 일단 지워주는 작업
    //     const filteredPrev = prev.filter(
    //       (item) => !updatedCodes.has(item.ORDER_ID)
    //     );

    //     //updatedData들에 updateFlag를 설정하여 새 배열 생성
    //     const updatedWithFlag = updatedData.map((item) => ({
    //       ...item,
    //       updateFlag: true, // updateFlag를 true로 설정
    //     }));

    //     //state는 비워줌
    //     setUpdatedData([]);
    //     //변경된 데이터들을 위로하여 재배치
    //     return [...updatedWithFlag, ...filteredPrev];
    //   });
    // }
    if (!updatedData) return;
    const changedOrderSN = updatedData.updatedOrInsertedOrders
      .map((order) => order.ORDER_ID)
      .filter((sn) => sn !== undefined);
    let newData: OrderListType[] = data.filter(
      (prev) => !changedOrderSN.includes(prev.ORDER_ID!)
    );

    console.log('updatedData', updatedData.updatedOrInsertedOrders);
    setData([...updatedData.updatedOrInsertedOrders, ...newData]);
  }, [updatedData]);

  useEffect(() => {
    apiGetOrderList();
  }, []);

  const IndeterminateCheckbox = ({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type='checkbox'
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    );
  };

  const columns = useMemo<ColumnDef<OrderListType>[]>(
    () => [
      //   {
      //     header: 'No',
      //     id: 'NO',
      //     size: 46,
      //     cell: ({ cell, row, getValue }) => (
      //       <Styled.NoBodyCell>{row.index + 1}</Styled.NoBodyCell>
      //     ),
      //   },
      {
        //TH Cell
        header: ({ table }) => (
          <>
            {/* <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />{' '} */}
            No
          </>
        ),
        cell: ({ row, getValue }) => (
          <Styled.NoBodyCell style={{ paddingLeft: `${row.depth * 2}rem` }}>
            {row.getCanExpand() && (
              <Styled.NoText>{row.index + 1}</Styled.NoText>
            )}
          </Styled.NoBodyCell>
          // <Styled.NoBodyCell style={{ paddingLeft: `${row.depth * 2}rem` }}>
          //   {/* <IndeterminateCheckbox
          //     {...{
          //       checked: row.getIsSelected(),
          //       indeterminate: row.getIsSomeSelected(),
          //       onChange: row.getToggleSelectedHandler(),
          //     }}
          //   />{' '} */}
          //   {row.getCanExpand() && <Styled.NoText>{row.index}</Styled.NoText>}
          //   {row.getCanExpand() && (
          //     <span
          //       {...{
          //         onClick: row.getToggleExpandedHandler(),
          //         style: {
          //           cursor: 'pointer',
          //           display: 'flex',
          //           alignItems: 'center',
          //         },
          //       }}
          //     >
          //       {row.getIsExpanded() ? (
          //         <AiFillCaretDown size={20} />
          //       ) : (
          //         <AiFillCaretRight size={20} />
          //       )}
          //     </span>
          //   )}
          // </Styled.NoBodyCell>
        ),
        id: 'NO',
        size: 79,
      },
      {
        header: '주문번호',
        accessorKey: 'ORDER_ID',
        size: 84,
      },
      {
        header: '거래처명',
        accessorKey: 'CUST_DES',
        size: 180,
      },
      {
        header: '담당자명',
        accessorKey: 'EMP_NAME',
        size: 65,
      },
      {
        header: '품목명',
        accessorKey: 'PROD_DES',
        size: 140,
      },
      {
        header: '규격',
        accessorKey: 'SIZE_DES',
        size: 140,
      },
      {
        header: '수량',
        accessorKey: 'QTY',
        size: 48,
      },
      {
        header: '단가',
        accessorKey: 'PRICE',
        size: 53,
        cell: (price) => formatCurrency(price.getValue() as number),
      },
      {
        header: '공급가액',
        accessorKey: 'SUPPLY_AMT',
        size: 73,
        cell: (supplyAmt) => formatCurrency(supplyAmt.getValue() as number),
      },
      {
        header: '납기일자',
        accessorKey: 'TIME_DATE',
        size: 140,
        cell: (timeDate) =>
          formatEightDigitStringToSlashForm(timeDate.getValue() as string),
      },
      {
        header: '주문방법',
        accessorKey: 'ORDER_TYPE',
        size: 66,
      },
      {
        header: '배송방법',
        accessorKey: 'DELIVERY_TYPE',
        size: 66,
      },
      {
        header: '주문특이사항',
        accessorKey: 'DESCRIPTION',
        size: 420,
      },
      {
        header: '적요',
        accessorKey: 'REMARKS',
        size: 420,
      },
    ],
    []
  );

  //아마 페이지네이션
  function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
  }

  async function post(data: OrderListUpdateRequestDto) {
    const sortedOrderList = await groupOrderWithItemSubrow(data.orderList);
    if (sortedOrderList) {
      const sortedData = {
        startDt: data.startDt,
        endDt: data.endDt,
        orderList: await groupOrderWithItemSubrow(data.orderList),
      };
      apiPostOrderList(sortedData);
    }
  }

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  //~~페이지네이션

  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        <SquareButton
          text='주문 등록'
          colorType='ACTIVE'
          onClick={() => {
            setModalType('add');
            setShowModal(true);
          }}
        />
        {/* <SquareButton
            text='테스트'
            colorType='ACTIVE'
            onClick={() => {
              Toast.showSuccess('테스트');
            }}
          /> */}
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    state: {
      expanded: true,
      pagination,
    },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    autoResetPageIndex,
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    onPaginationChange: setPagination,
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Styled.PaginationButtonContainer>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BsChevronDoubleLeft />
          </Styled.PaginationButton>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <BsChevronLeft />
          </Styled.PaginationButton>
          {/* 중간인풋 */}
          <Styled.PaginationButton
            size={28}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <BsChevronRight />
          </Styled.PaginationButton>
          <Styled.PaginationButton
            size={28}
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            <BsChevronDoubleRight />
          </Styled.PaginationButton>
        </Styled.PaginationButtonContainer>
        <div
          style={{
            alignContent: 'center',
            marginLeft: '20px',
            marginRight: '40px',
          }}
        >
          {table.getPageCount()}페이지 중{' '}
          {table.getState().pagination.pageIndex + 1}페이지
        </div>
        <div className='flex-center'>페이지당 표시 데이터 수</div>
        <select
          id={'row-count'}
          value={pagination.pageSize}
          onChange={(e) => {
            setPagination((prev) => ({
              pageIndex: prev.pageIndex,
              pageSize: Number(e.target.value),
            }));
            table.firstPage();
          }}
          style={{ marginLeft: '14px' }}
        >
          <option key={'10'} value={10}>
            10개
          </option>
          <option key={'20'} value={20}>
            20개
          </option>
          <option key={'30'} value={30}>
            30개
          </option>
          <option key={'40'} value={40}>
            40개
          </option>
          <option key={'50'} value={50}>
            50개
          </option>
        </select>
      </div>

      {showModal && modalType && (
        <OrderUploadModal
          onClose={() => {
            setShowModal(false);
          }}
          onSelect={(item: OrderListUpdateRequestDto) => post(item)}
          mode='add'
        />
      )}
      <Styled.TableContainer>
        <Styled.TableOptionButton onClick={() => setShowConfig(!showConfig)}>
          설정
        </Styled.TableOptionButton>
        <TableConfig
          show={showConfig}
          Header_Overflow_Type={headerOverflowType}
          setHeaderOverflowType={(value: 'hide' | 'wrap') =>
            setHeaderOverflowType(value)
          }
          Body_Overflow_Type={bodyOverflowType}
          setBodyOverflowType={(value: 'hide' | 'wrap') =>
            setBodyOverflowType(value)
          }
          Body_Align_Hori_Type={bodyAlignHoriType}
          setBodyAlignHoriType={(value: 'left' | 'center' | 'right') =>
            setBodyAlignHoriType(value)
          }
          Cell_Hori_Padd_Value={cellHoriPaddValue}
          setCellHoriPaddValue={(value: number) => setCellHoriPaddValue(value)}
          Body_Vert_Padd_Value={bodyVertPaddValue}
          setBodyVertPaddValue={(value: number) => setBodyVertPaddValue(value)}
        />
        <Styled.TableDiv
          {...{
            style: {
              ...columnSizeVars,
              width: table.getTotalSize(),
            },
          }}
          headOverflowType={headerOverflowType}
          bodyOverflowType={bodyOverflowType}
          bodyAlignHoriType={bodyAlignHoriType}
          cellHoriPaddValue={cellHoriPaddValue}
          bodyVertPaddValue={bodyVertPaddValue}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Styled.ThDiv {...{ key: headerGroup.id }}>
                {headerGroup.headers.map((headerCell) =>
                  headerCell.isPlaceholder ? null : (
                    <Styled.ThCell
                      {...{
                        key: headerCell.id,
                        style: {
                          width: `calc(var(--header-${headerCell?.id}-size) * 1px)`,
                        },
                        className: 'th',
                      }}
                    >
                      {headerCell.isPlaceholder
                        ? null
                        : flexRender(
                            headerCell.column.columnDef.header,
                            headerCell.getContext()
                          )}
                      <Styled.ResizeHandler
                        {...{
                          onMouseDown: headerCell.getResizeHandler(),
                          className: `resizer ${
                            headerCell.column.getIsResizing()
                              ? 'isResizing'
                              : ''
                          }`,
                        }}
                      />
                    </Styled.ThCell>
                  )
                )}
              </Styled.ThDiv>
            );
          })}
          {table.getRowModel().rows.map((row) => (
            <>
              <Styled.TdDiv
                {...{
                  key: row.id,
                  style: {
                    // 전체 배경 색 변경 (변경된 필드가 있으면)
                    backgroundColor:
                      (row.original.changedOrderFields &&
                        row.original.changedOrderFields.length > 0) ||
                      row.original.subRows?.some(
                        (subRow) =>
                          subRow.changedItemFields &&
                          subRow.changedItemFields?.length > 0
                      )
                        ? theme.colors.lime
                        : 'transparent',
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const isMainRow = row.depth === 0;

                  // 서브로우에서 각 셀의 변경된 필드 확인 (셀이 속한 서브로우마다)
                  let subRowChangedFields = null;
                  if (!isMainRow) {
                    // 메인로우가 아닐 경우, 자기 자신에서만 변경된 필드를 찾음
                    subRowChangedFields = row.original.changedItemFields
                      ? row.original.changedItemFields.find(
                          (field) => mapKey(field) === cell.column.id
                        )
                      : null;
                  } else {
                    // 서브로우에서 찾는 경우
                    subRowChangedFields = row.original.subRows
                      ? row.original.subRows.reduce(
                          (acc, subRow, subRowIndex) => {
                            if (subRow.changedItemFields) {
                              if (
                                subRow.changedItemFields.find(
                                  (field) => mapKey(field) === cell.column.id
                                )
                              ) {
                                acc[subRowIndex] = true; // 해당 서브로우의 셀이 변경되었음을 표시
                              }
                            }
                            return acc;
                          },
                          {} as Record<number, boolean>
                        )
                      : null;
                  }

                  // 메인로우에서 변경된 필드 확인 (주문 필드 변경)
                  const isHighlighted =
                    (isMainRow &&
                      row.original.changedOrderFields &&
                      row.original.changedOrderFields.find(
                        (field) => mapKey(field) === cell.column.id
                      )) || // 메인로우의 changedOrderFields
                    (subRowChangedFields && subRowChangedFields[row.index]); // 서브로우의 changedItemFields와 일치하는 셀

                  // 커스텀 스타일 (변경된 셀에 하이라이트 적용)
                  return (
                    <Styled.TdCell
                      {...{
                        key: cell.id,
                        className: 'td',
                        style: {
                          // 해당 셀이 변경되었으면 배경 색을 노란색으로 하이라이트
                          backgroundColor: isMainRow
                            ? isHighlighted
                              ? 'rgb(253 255 85 / 26%)'
                              : // : '#3535350a'
                                theme.colors.white_odd
                            : 'transparent',
                          width: `calc(var(--header-${cell.column.id}-size) * 1px)`, // 셀 크기
                        },
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Styled.TdCell>
                  );
                })}
              </Styled.TdDiv>
            </>
          ))}

          {/* {table.getRowModel().rows.map((row) => (
            <>
              <Styled.TdDiv
                {...{
                  key: row.id,
                  style: {
                    backgroundColor: row.original.updateFlag
                      ? theme.colors.lime
                      : 'transparent',
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  // 특정 로직으로 row 정보 확인
                  const orderHighlightCell =
                    (row.original.changedOrderFields &&
                      row.original.changedOrderFields.length > 0 &&
                      row.original.changedOrderFields.map((key) => key)) ||
                    null;

                  const isHighlightedItem =
                    (row.original.subRows &&
                      row.original.subRows.some(
                        (subrow) =>
                          subrow.changedItemField &&
                          subrow.changedItemField.length > 0
                      ) &&
                      row.original.subRows
                        .map((subRow) =>
                          subRow.changedItemField?.map((key) => key)
                        )
                        .filter((item) => item !== undefined && item !== null)
                        .flat()) ||
                    null;

                  const isMainRow = row.depth === 0;

                  const key = cell.column.id;

                  // const customStyle = isHighlighted
                  //   ? { backgroundColor: 'yellow' } // 조건에 맞으면 커스텀 스타일
                  //   : {}; // 기본 스타일

                  return (
                    <Styled.TdCell
                      {...{
                        key: cell.id,
                        className: 'td',
                        style: {
                          width: `calc(var(--header-${cell.column.id}-size) * 1px)`,
                        },
                      }}
                    >
                      {flexRender(
                        // cell.column.columnDef.aggregatedCell ??
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Styled.TdCell>
                  );
                })}
              </Styled.TdDiv>
            </>
          ))} */}
        </Styled.TableDiv>
      </Styled.TableContainer>
    </>
  );
};
