import styled, { useTheme } from 'styled-components';
import { BsPersonCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/modules';
import { useEffect, useState } from 'react';
import { clearLoginUserData } from '../../store/modules/loginUserData';
import { useNavigate } from 'react-router-dom';

export const UserInfoTooltip = ({ show }: { show: boolean }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUserData = useSelector((state: RootState) => state.loginUserData);
  const [roleColor, setRoleColor] = useState<string | null>(null);
  const [roleString, setRoleString] = useState<string | null>(null);
  useEffect(() => {
    setRoleColor(
      loginUserData?.role === 'MANAGER'
        ? theme.colors.green
        : loginUserData?.role === 'ADMIN'
        ? theme.colors.red
        : loginUserData?.role === 'WORKER'
        ? theme.colors.blue
        : null
    );
    setRoleString(
      loginUserData?.role === 'MANAGER'
        ? '매니저'
        : loginUserData?.role === 'ADMIN'
        ? '관리자'
        : loginUserData?.role === 'WORKER'
        ? '작업자'
        : null
    );
  }, [loginUserData]);

  const options = [
    {
      title: '로그아웃',
      function: () => {
        dispatch(clearLoginUserData());
        navigate('/');
      },
    },
  ];

  return (
    <Container show={show}>
      <UserIcon>
        <BsPersonCircle color={roleColor || 'black'} size={30} />
      </UserIcon>
      <UserRoleString color={roleColor || 'black'}>{roleString}</UserRoleString>
      <UserRoleString color='black'>{loginUserData?.userName}</UserRoleString>
      <div
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: '#c8c8c8',
          margin: '10px 0px 0px 0px',
        }}
      />
      {options.map((option) => (
        <Options onClick={() => option.function()}>{option.title}</Options>
      ))}
    </Container>
  );
};

const Container = styled.div<{ show: boolean }>`
  overflow: hidden;
  cursor: auto;
  width: 300px;
  /* width: ${({ show }) => (show ? '300px' : '0px')}; */
  /* height: 440px; */
  /* height: ${({ show }) => (show ? '440px' : '0px')}; */
  background-color: white;
  border: 1px solid #c8c8c8;
  position: absolute;
  top: 40px;
  right: 0;

  transform: ${({ show }) => (show ? 'scale(1)' : 'scale(0)')};
  transform-origin: top right;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
`;

const UserIcon = styled.div`
  width: 100%;
  /* height: 21px; */
  text-align: center;
  margin-top: 32px;
`;

const UserRoleString = styled.h4<{ color: string }>`
  color: ${({ color }) => color};
  text-align: center;
  margin: 10px 0px;
`;

const Options = styled.h4`
  padding-left: 15px;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
  height: 40px;
  align-content: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white_hover};
  }
`;
