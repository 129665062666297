import styled, { css, useTheme } from 'styled-components';
import {
  BomRequestType,
  ItemBomEssType,
  ItemBomResponseEssType,
  ItemBomResponseType,
  ItemBomType,
  OrderListType,
  ParcelItemResponseType,
  ProductItem,
} from '../../../../api/apiConfig';
import Form from 'react-bootstrap/Form';
import ModalBase from '../ModalBase';
import {
  BiPlusCircle,
  BiSolidLeftArrow,
  BiSolidRightArrow,
  BiX,
} from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { ProdType } from '../../../table/Table';
import { FiTrash2 } from 'react-icons/fi';
import { IoIosCheckmark } from 'react-icons/io';
import { IoIosSquareOutline } from 'react-icons/io';

export const ParcelItemEditModal = ({
  itemProp,
  parcelItemProp,
  onClose,
  onSave,
}: {
  itemProp: ProductItem;
  parcelItemProp: ParcelItemResponseType;
  onClose: () => void;
  onSave: (item: ParcelItemResponseType) => void;
}) => {
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [item, setItem] = useState<ProductItem>(itemProp);

  const [label, setLabel] = useState<string>(parcelItemProp.label || '');
  const [sizeLabel, setSizeLabel] = useState<string>(
    parcelItemProp.sizeLabel || ''
  );
  const [unitLabel, setUnitLabel] = useState<string>(
    parcelItemProp.unitLabel || ''
  );
  const [maxQty, setMaxQty] = useState<number | null>(
    parcelItemProp.packageMaxQty || null
  );

  const [useMaxQty, setUseMaxQty] = useState<boolean>(false);

  const [parcelItem, setParcelItem] =
    useState<ParcelItemResponseType>(parcelItemProp);

  // function saveHandler() {
  //   if (!producedItem) return;
  //   if (boms.length < 1) return;
  //   if (boms.find((bom) => bom.consumedQty && bom.consumedQty < 1)) return;
  //   const requestDto: BomRequestType = {
  //     producedItemId: producedItem.itemId,
  //     consumedItems: boms.map((bom) => ({
  //       consumedItemId: bom.itemId,
  //       consumedQuantity: bom.consumedQty!,
  //     })),
  //   };
  //   onSave(requestDto);
  //   onClose();
  // }

  function maxQtyChangeHandler(
    type: 'decrease' | 'increase' | 'none',
    value?: number
  ) {
    // if (parcelItem === null) return;
    // setParcelItem((prev) => ({
    //   id: prev.id || null,
    //   label: prev.label || null,
    //   sizeLabel: prev.sizeLabel || null,
    //   unitLabel: prev.unitLabel || null,
    //   prodCd: prev.prodCd || item.PROD_CD,
    //   packageMaxQty:
    //     value !== undefined
    //       ? value
    //       : type === 'decrease'
    //       ? prev.packageMaxQty! - 1
    //       : prev.packageMaxQty! + 1,
    // }));

    if (!useMaxQty) return;
    setMaxQty((prev) => {
      if (prev === null) return value !== undefined ? value : 1; // 기본값 사용
      return value !== undefined
        ? value
        : type === 'decrease'
        ? prev - 1
        : prev + 1;
    });
  }

  useEffect(() => {
    setMaxQty(
      useMaxQty
        ? parcelItem.packageMaxQty
          ? parcelItem.packageMaxQty
          : 1
        : null
    );
  }, [useMaxQty]);

  function saveHandler() {
    const prop: ParcelItemResponseType = {
      id: parcelItem.id || null,
      label: label || null,
      sizeLabel: sizeLabel || null,
      unitLabel: unitLabel || null,
      prodCd: parcelItem.prodCd,
      packageMaxQty: maxQty && maxQty > 0 ? maxQty : null,
    };
    onSave(prop);
  }

  return (
    <ModalBase>
      <Container>
        <ContainerHeader>
          <BiX
            color={'white'}
            size={40}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </ContainerHeader>
        <ContainerBody>
          <Header>품목 정보</Header>
          <Body>
            <div className='row'>
              <h4 className='row-label'>품목명</h4>
              <h4 className='row-value'>{item.PROD_DES}</h4>
            </div>
            <div className='row'>
              <h4 className='row-label'>규격명</h4>
              <h4 className='row-value'>{item.SIZE_DES}</h4>
            </div>
            {/* <div className='row'>
              <div className='row-label'>배송지 주소</div>
              <div className='row-value'>{item?.receiverAddress}</div>
            </div>
            <div className='row'>
              <div className='row-label'>주문 특이사항</div>
              <div className='row-value'>{item?.deliveryRemarks}</div>
            </div> */}
          </Body>
          <Header>소모품 정보</Header>
          <Body>
            <div className='row'>
              <h4 className='row-label'>엑셀 변환될 이름</h4>
              <input
                className='row-value'
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
            <div className='row'>
              <h4 className='row-label'>엑셀 변환될 규격</h4>
              <input
                className='row-value'
                value={sizeLabel}
                onChange={(e) => setSizeLabel(e.target.value)}
              />
            </div>
            <div className='row'>
              <h4 className='row-label'>엑셀 변환될 단위</h4>
              <input
                className='row-value'
                value={unitLabel}
                onChange={(e) => setUnitLabel(e.target.value)}
              />
            </div>
            <div className='row'>
              <h4
                className='row-label'
                style={{ alignItems: 'flex-start', cursor: 'pointer' }}
                onClick={() => setUseMaxQty(!useMaxQty)}
              >
                <div
                  style={{
                    color: useMaxQty
                      ? theme.colors.black
                      : theme.colors.darkGray,
                  }}
                >
                  포장 최대개수 제한 사용
                </div>
                <div style={{ position: 'relative' }}>
                  <IoIosSquareOutline
                    size={24}
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                    }}
                  />
                  {useMaxQty && (
                    <IoIosCheckmark
                      size={24}
                      style={{ position: 'absolute', left: 0, top: 0 }}
                    />
                  )}
                </div>
              </h4>
              <h4 className='row-value'>
                {maxQty !== null && (
                  <div className='flex-center'>
                    <div
                      style={{ flex: 1, textAlign: 'start', cursor: 'pointer' }}
                      onClick={() =>
                        maxQty > 1 && maxQtyChangeHandler('decrease')
                      }
                    >
                      <BiSolidLeftArrow />
                    </div>
                    <input
                      value={maxQty}
                      onChange={(e) => {
                        let value = e.target.value.trim();
                        // 숫자만 남도록 필터링
                        value = value.replace(/[^0-9]/g, ''); // 숫자 외 문자 제거

                        // 앞의 '0' 제거
                        value = value.replace(/^0+/, '');

                        // 빈 값이면 0으로 설정
                        if (value === '' || value === null) {
                          maxQtyChangeHandler('none', 0);
                        } else {
                          maxQtyChangeHandler('none', Number(value));
                        }
                      }}
                      type='text'
                      style={{ width: '40px', textAlign: 'center' }}
                    />
                    <div
                      style={{ flex: 1, textAlign: 'end', cursor: 'pointer' }}
                      onClick={() => maxQtyChangeHandler('increase')}
                    >
                      <BiSolidRightArrow />
                    </div>
                  </div>
                )}
              </h4>
            </div>
          </Body>

          <ButtonSection>
            <Button>
              <h4 onClick={() => saveHandler()}>저장</h4>
            </Button>
          </ButtonSection>
        </ContainerBody>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  border-radius: 15px;
  /* overflow: hidden; */
  position: relative;
  width: 600px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ContainerHeader = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.colors.softBlack};
`;

const ContainerBody = styled.div`
  padding: 0px 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontsize[20]};
  height: 50px;
  align-content: center;
`;

const AddContainer = styled.div`
  display: flex;
  top: 0;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const AddButton = styled.h4`
  flex: 0 0 15%;
`;

const SearchContainer = styled.div`
  bottom: 0;
  display: flex;
  position: relative;
  overflow: visible;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  flex: 0 0 80%;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 10px;
`;

const Button = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  align-content: center;
  text-align: center;
  padding: 5px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue_hover};
  }
`;

const OptionalH4 = styled.h4<{ selectable: boolean }>`
  ${({ selectable }) =>
    selectable
      ? css`
          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.white_hover};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.whiteGray};
        `}
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize[16]};
  padding: 0px 15px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
  .row-label {
    display: flex;
    align-items: center;
  }
  .row-button {
    flex: 0 0 10%;
  }
  .row-prod {
    flex: 1 0 70%;
    display: flex;
    gap: 15px;
  }

  .row-qty {
    flex: 0 0 20%;
  }
`;
