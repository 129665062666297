import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import {
  bomListGetApi,
  bomPostApi,
  BomRequestType,
  ItemBomResponseEssType,
  ItemBomResponseType,
  ItemBomType,
  itemListGetApi,
  ProductItem,
} from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
import { BomEditModal } from '../../../component/common/modal/ModalComponents/BomEditModal';

export const BomPage = () => {
  const [items, setItems] = useState<ProductItem[]>([]);

  const [boms, setBoms] = useState<ItemBomResponseEssType[]>([]);

  const [bomModalItem, setBomModalItem] =
    useState<ItemBomResponseEssType | null>(null);

  const [openBomModal, setOpenBomModal] = useState<boolean>(false);

  const { fetch: fetchItemListGet } = useFetch({
    fetchFunction: itemListGetApi,
    onSuccess: (data: ProductItem[]) => setItems(data),
  });

  const { fetch: fetchBomListGet } = useFetch({
    fetchFunction: bomListGetApi,
    onSuccess: (data: ItemBomResponseEssType[]) => setBoms(data),
  });

  const { fetch: fetchBomPostAPi } = useFetch({
    fetchFunction: bomPostApi,
    onSuccess: (data: ItemBomResponseEssType) => applyNewBom(data),
  });

  function postBom(item: BomRequestType) {
    fetchBomPostAPi(item);
  }

  function applyNewBom(data: ItemBomResponseEssType) {
    setBoms((prev) =>
      prev.some((bom) => bom.producedItem.itemId === data.producedItem.itemId)
        ? prev.map((bom) =>
            bom.producedItem.itemId === data.producedItem.itemId ? data : bom
          )
        : [...prev, data]
    );
  }

  useEffect(() => {
    fetchItemListGet();
    fetchBomListGet();
  }, []);

  function onClickBomCell(itemId: number) {
    const bom = boms.find((bom) => bom.producedItem.itemId === itemId) || null;

    if (!bom) {
      const item = items.find((item) => item.id === itemId);
      if (!item) {
        console.log('bom, 품목정보 모두를 찾을 수 없음');
        return;
      }
      const bomModalProps: ItemBomResponseEssType = {
        producedItem: {
          itemDes: item.PROD_DES,
          sizeDes: item.SIZE_DES,
          itemId: item.id,
        },
        consumedItems: [],
      };

      setBomModalItem(bomModalProps);
    } else {
      setBomModalItem(bom);
    }

    setOpenBomModal(true);
  }

  return (
    <Container>
      {openBomModal && (
        <BomEditModal
          itemList={items}
          item={bomModalItem}
          onClose={() => setOpenBomModal(false)}
          onSave={(item: BomRequestType) => postBom(item)}
        />
      )}
      <h2 style={{ marginTop: '0px' }}>소모량 목록</h2>
      <RowContainer>
        <HeaderRow>
          <h4 className='prod-des flex-center'>품목명</h4>
          <h4 className='size-des flex-center'>규격명</h4>
          <h4 className='has-bom flex-center'>소모품 등록현황</h4>
        </HeaderRow>
        {items.length > 0 &&
          items
            .filter((item) => item.PROD_TYPE === '1')
            .map((item, index) => (
              <Row odd={index % 2 === 1}>
                <h5 className='prod-des flex-center'>{item.PROD_DES}</h5>
                <h5 className='size-des flex-center'>{item.SIZE_DES}</h5>
                <h5
                  className='has-bom flex-center'
                  onClick={() => onClickBomCell(item.id)}
                >
                  {(() => {
                    const bom = boms.find(
                      (bom) => bom.producedItem.itemId === item.id
                    );
                    if (bom) {
                      return bom.consumedItems
                        .map((consumed) => consumed.itemDes)
                        .join(', ');
                    }
                    return '추가하기';
                  })()}
                </h5>
              </Row>
            ))}
        <Row></Row>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div``;

const RowContainer = styled.div`
  /* background-color: white; */
`;

const Row = styled.div<{ odd?: boolean }>`
  display: flex;
  width: 100%;
  height: 32px;
  flex-direction: row;
  align-items: center;

  background-color: ${({ odd, theme }) =>
    odd ? theme.colors.white_odd : theme.colors.white};

  .prod-des {
    flex: 0 0 30%;
  }
  .size-des {
    flex: 0 0 25%;
  }
  .has-bom {
    flex: 1 0 15%;
    overflow: hidden; /* 넘치는 부분 숨김 */
    text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
    white-space: nowrap; /* 줄 넘김 금지 */
    cursor: pointer;
    &:hover {
      text-decoration-line: underline;
      font-weight: 700;
    }
  }
`;

const HeaderRow = styled(Row)`
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.charcoal};
  box-sizing: border-box;
  .has-bom {
    &:hover {
      text-decoration: none;
      cursor: auto;
    }
  }
`;
