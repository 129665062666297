import { AxiosError } from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { CustomAxiosError } from './axiosConfig';

interface FetchState<T> {
  /** api data */
  data: T | null;
  /** api loading... */
  loading: boolean;
  /** api error */
  error: CustomAxiosError | null;
  /** api 재호출 */
  fetch: (params?: any) => void;
}

interface FetchParam<T> {
  /** api axios function */
  fetchFunction: (params?: any) => Promise<T>;
  /** 초기 설정 param */
  initialParams?: any;
  /** 선언시 호출하지 않고 refetch시에 호출 */
  lazy?: boolean;
  /** 성공 시 콜백 */
  onSuccess?: (result: T) => void;

  onError?: (error?: CustomAxiosError) => void;
}

const useFetch = <T>({
  fetchFunction,
  initialParams,
  lazy = true,
  onSuccess,
  onError,
}: FetchParam<T>): FetchState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(!lazy);
  const [error, setError] = useState<CustomAxiosError | null>(null);

  const fetchData = useCallback(
    async (params?: any) => {
      setLoading(true);
      setError(null); // Fetch 시작 시 이전 에러 상태 초기화
      try {
        const result = await fetchFunction(params);
        // console.warn(result);
        setData(result);
        if (onSuccess) onSuccess(result);
      } catch (err) {
        setError(err as CustomAxiosError);
        if (onError) onError(err as CustomAxiosError);
      } finally {
        setLoading(false);
      }
    },
    [fetchFunction, onSuccess]
  );

  useEffect(() => {
    if (!lazy) {
      fetchData(initialParams);
    }
  }, [fetchData, lazy]);

  const fetch = (params?: any) => {
    fetchData(params);
  };

  return { data, loading, error, fetch };
};

export default useFetch;
