import { styled, css } from 'styled-components';

export const WholeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background: linear-gradient(to bottom right, #45435c, #486e7a); */
  background-color: ${({ theme }) => theme.colors.brightNavy};
`;

export const Conatiner = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  overflow: hidden;
`;

export const HalfContainer = styled.div`
  width: 500px;
  height: 430px;

  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  width: 150px;
  position: absolute;
  top: 119px;
  transform: translateX(-16px);
  object-fit: contain;
`;

export const Title = styled.div`
  width: 90%;
  align-content: center;
  font-weight: 800;
  font-size: ${({ theme }) => theme.fontsize.header_main};
  height: 30%;
  color: ${({ theme }) => theme.colors.brightNavy};
`;

export const InputArea = styled.div`
  display: flex;
  width: 90%;
  height: 187px;
  flex-direction: column;
  align-items: fl;
  gap: 30px;
`;
export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const Label = styled.div`
  width: 30%;
  align-content: center;
  font-size: ${({ theme }) => theme.fontsize[15]};
`;

export const Input = styled.input`
  border: none;
  flex: 1;
  outline: none;
  height: 30px;
  font-weight: 500;
  font-size: 15px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.brightNavy};
  border-bottom-style: solid;
`;

export const LoginButton = styled.div`
  width: 100%;
  height: 43px;
  margin-top: 17px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 7px;
  align-content: center;
  text-align: center;
  font-weight: 800;
  font-size: ${({ theme }) => theme.fontsize.header_pri};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;
