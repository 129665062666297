/** 2024/10/15 -1   을 8자리 문자열, 시리얼번호 객체로 반환*/
export const replaceOrderUploadserno = (uploadSerno: string) => {
  const splitDash = uploadSerno.split('-');
  const ser = splitDash[1];
  const splitSlash = splitDash[0].split('/');
  const date =
    splitSlash[0].trim() + splitSlash[1].trim() + splitSlash[2].trim();

  return { date: date, serial: ser };
};

/** 문자열에서 - 제거 */
export const removeDashFromString = (dateString: string) => {
  const splitDash = dateString.split('-');
  let replaced: string = '';
  if (splitDash) {
    splitDash.forEach((item) => (replaced = replaced + item.trim()));
  }

  return replaced;
};

/** 날짜형식 문자열에서 / 및 공백 제거 */
export const removeSlashFromDateString = (dateString: string) => {
  const sliced = dateString.split('/');
  const combined = sliced.map((string) => string.trim()).join('');
  return combined;
};

/** 날짜에서 연, 월, 일 YYYY, MM, DD로 추출 */
export function getYearMonthDateFromDate(date: Date) {
  const year = date.getFullYear(); // 년도 추출 (yyyy)
  const month = date.getMonth() + 1; // 월 추출 (0~11 범위이므로 +1)
  const day = date.getDate();
  const yearString = year.toString();
  const monthString = month.toString().padStart(2, '0'); // 한 자리일 경우 앞에 '0' 추가
  const dayString = day.toString().padStart(2, '0'); // 한 자리일 경우 앞에 '0' 추가
  const dateString = yearString + monthString + dayString;
  return {
    year: year,
    month: month,
    day: day,
    yearString: yearString,
    monthString: monthString,
    dayString: dayString,
    dateString: dateString,
  };
}

/** 숫자 - 천의 자리마다 , 쉼표 추가 */
export function formatCurrency(value?: number): string | null {
  if (value === undefined || value === null) {
    return null; // 데이터가 없을 때 표시할 기본값
  }
  return `${value.toLocaleString('en-US')}`;
}

/** 날짜 YYYY, MM string으로 받아 해당날짜 총 일/월 계산 */
export function getDayCountFromYearMonthString(year: string, month: string) {
  // 첫 번째 날을 설정 (ex. 2024년 10월 1일)
  const firstDayOfMonth = new Date(Number(year), Number(month) - 1, 1);

  // 다음 달의 첫 번째 날을 설정 (ex. 2024년 11월 1일)
  const firstDayOfNextMonth = new Date(Number(year), Number(month), 1);

  // 마지막 날 계산
  const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

  // 그 차이를 밀리초로 계산한 후, 일 수로 변환
  // 두 날짜의 차이를 밀리초로 계산 후, 일 수로 변환
  const daysInMonth: number =
    (firstDayOfNextMonth.getTime() - firstDayOfMonth.getTime()) /
    (1000 * 60 * 60 * 24);

  return { daysInMonth, firstDayOfMonth, lastDayOfMonth };
}

/** 1자리, 2자리 숫자를 2자리 string으로 리턴 */
export function formatNumberToTwoDigitString(num: number): string {
  return num.toString().padStart(2, '0');
}

/** YYYYMMDD를 YYYY/MM/DD로 변경 */
export function formatEightDigitStringToSlashForm(
  string?: string
): string | null {
  if (string === undefined || string === null) {
    return null; // 데이터가 없을 때 표시할 기본값
  }
  const yyyy = string.substring(0, 4);
  const mm = string.substring(4, 6);
  const dd = string.substring(6, 8);
  const reform = yyyy + '/' + mm + '/' + dd;
  return reform;
}

/** YYYYMMDD를 YY.MM.DD로 변경 */
export function formatEightDigitStringToSixDigitDot(string: string): string {
  const yy = string.substring(2, 4);
  const mm = string.substring(4, 6);
  const dd = string.substring(6, 8);
  const reform = yy + '.' + mm + '.' + dd;
  return reform;
}

/** YYYYMMDD를 각각 잘라서 반환 */
export function splitYYYYMMDD(data: string) {
  const yyyy = data.substring(0, 4);
  const mm = data.substring(4, 6);
  const dd = data.substring(6, 8);
  return { year: yyyy, month: mm, day: dd };
}
