// DeliberySet.tsx
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import NaverMap from '../../../component/map/NaverMap';
import { MdOutlineSearch } from 'react-icons/md';
import { ColumnDef } from '@tanstack/react-table';
import BeforeDeliverTable from '../../../component/table/BeforeDeliverTable';
import AfterDeliverTable from '../../../component/table/AfterDeliverTable';
import {
  data as initialData,
  SampleAfterData,
  DataRow,
  SubRow,
  SampleAfterDataType,
  SampleAfterSubRow,
  SampleAfterThirdRow,
  Manager,
  sampleManagers,
} from '../../../assets/datas/sampleDeliveryData';
import ManagerSelectModal from '../../../component/common/modal/ManagerSelectModal';
import {
  DragDropContext,
  Draggable,
  DragStart,
  DragUpdate,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import useFetch from '../../../api/useFetch';
import {
  deliveryAssignmentGetApi,
  DeliveryAssignmentRequestDataType,
  DeliveryAssignmentRequestType,
  deliveryAssignmentUpdateApi,
  deliveryManagerListGetApi,
  DeliveryManagerType,
  deliveryTargetOrdersGetApi,
  orderListGetApi,
  OrderListType,
} from '../../../api/apiConfig';
import { orderData } from '../../../component/table/TableTestdata';
import {
  formatEightDigitStringToSixDigitDot,
  formatEightDigitStringToSlashForm,
  formatNumberToTwoDigitString,
  getDayCountFromYearMonthString,
  getYearMonthDateFromDate,
  removeDashFromString,
} from '../../../function/replaceString';
import { EnumType } from 'typescript';
import SquareButton from '../../../component/button/SquareButton';
import { FiHelpCircle } from 'react-icons/fi';
import { FiTrash2 } from 'react-icons/fi';
import { DeliveryOrderDetailModal } from '../../../component/common/modal/ModalComponents/DeliveryOrderDetailModal';
import { AddressItem } from '../../../component/map/NaverMapWrapper';
import { BiMap } from 'react-icons/bi';
import { BiSolidMap } from 'react-icons/bi';

export type DeliveryGroupType = {
  assignmentDate: string;
  deliveryManager: DeliveryManagerGroupType[];
};

export type DeliveryManagerGroupType = {
  info: DeliveryManagerType;
  items: OrderListType[];
};

export type DeliveryOrderType = {
  id: number;
  prodDes: string;
  qty: number;
  timeDate: string;
  requirement: string;
  receiverAddress: string;
  deliveryRemarks: string;
  idx: number;
};

//drag and drop y축 컨테이너 type
export interface DnDContainer {
  [key: string]: DeliveryOrderType[];
}

export const DeliveryPage = () => {
  const { setButtons } = useHeaderButtons();

  const theme = useTheme();

  const newWindow = useRef<Window | null>(null);

  const beforeDroppableId = useRef<string>('');

  // 연도선택
  const [targetYear, setTargetYear] = useState<number>(
    getYearMonthDateFromDate(new Date()).year
  );

  //월 선택
  const [targetMonth, setTargetMonth] = useState<number>(
    getYearMonthDateFromDate(new Date()).month
  );

  const [pinnedOrders, setPinnedOrders] = useState<OrderListType[]>([]);

  const [minDay, setMinDay] = useState<string>('');
  const [maxDay, setMaxDay] = useState<string>('');

  //상세정보 모달 트리거
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  const [modalTargetItem, setModalTargetItem] = useState<OrderListType | null>(
    null
  );

  // 맵 표시를 위한 현재선택된 주문정보
  const [selectedOrderGroup, setSelectedOrderGroup] = useState<OrderListType[]>(
    []
  );

  //주문목록 담을 변수
  const [orderData, setOrderData] = useState<OrderListType[]>([]);

  //배송목록 담을 변수
  const [firstContainers, setFirstContainers] = useState<DeliveryGroupType[]>(
    []
  );

  //배송담당자 목록 담을 변수
  const [deliveryManagers, setDeliveryManagers] = useState<
    DeliveryManagerType[]
  >([]);

  const { fetch: fetchDeliveryAssignmentUpdate } = useFetch({
    fetchFunction: deliveryAssignmentUpdateApi,
    onSuccess: () => console.log('배송 배정 완료'),
  });

  const { fetch: fetchDeliveryAssignmentGet } = useFetch({
    fetchFunction: deliveryAssignmentGetApi,
    onSuccess: (data: DeliveryGroupType[]) => {
      console.log('dlvrData:::', data);
      setFirstContainers(data);
    },
  });

  const { fetch: fetchDeliveryManagerListGet } = useFetch({
    fetchFunction: deliveryManagerListGetApi,
    onSuccess: (data: DeliveryManagerType[]) => {
      setDeliveryManagers(data);
    },
  });

  const { fetch: fetchDeliveryTargetOrdersGetApi } = useFetch({
    fetchFunction: deliveryTargetOrdersGetApi,
    onSuccess: (data: OrderListType[]) => setOrderData(data),
  });

  const [managers, setManagers] = useState<Manager[]>(sampleManagers);

  useEffect(() => {
    setButtons(
      <>
        <SquareButton
          colorType='ACTIVE'
          text='저장하기'
          onClick={() => saveHandler()}
        />
        <SquareButton
          colorType='ACTIVE'
          text='지도열기'
          onClick={() => openNewWindow()}
        />
      </>
    );
  }, [setButtons, firstContainers]);

  //주문정보, 배송자리스트 조회
  useEffect(() => {
    fetchDeliveryManagerListGet();
    fetchDeliveryTargetOrdersGetApi();
  }, []);

  //선택 월에 존재하는 일자 전부 배송목록에 빈 데이터로 추가. 현재 사용안함
  // useEffect(() => {
  //   const { daysInMonth } = getDayCountFromYearMonthString(
  //     targetYear.toString(),
  //     targetMonth.toString()
  //   );
  //   const newContainerGroup: DeliveryGroupType[] = [];
  //   for (let i = 1; i <= daysInMonth; i++) {
  //     const day = formatNumberToTwoDigitString(i);

  //     const newFirstContainer: DeliveryGroupType = {
  //       assignmentDate: `${targetYear}${targetMonth}${day}`,
  //       deliveryManager: [],
  //     };
  //     newContainerGroup.push(newFirstContainer);
  //   }

  //   // setFirstContainers(newContainerGroup);
  // }, [targetYear, targetMonth]);

  //dnd 드래그 시작 시 호출
  const onDragStart = (start: DragStart) => {
    const { source } = start;

    //드래그 시작영역의 구분 아이디
    const sourceKey = source.droppableId as string;

    //할당안된 주문으로부터 출발시
    if (sourceKey === 'unhandled-order-container') {
      //할당안된 주문들에서 추출
      const _items = JSON.parse(JSON.stringify(orderData)) as typeof orderData;

      const [targetItem] = _items.splice(source.index, 1);
      setSelectedOrderGroup([targetItem]);
    } else if (sourceKey.startsWith('droppable-order-container')) {
      //할당된 주문들에서 출발시
      const delimiter = 'droppable-order-container-';

      //target = 주문일자-담당자id 추출
      const fromIndex = sourceKey.indexOf(delimiter);
      const fromTarget = sourceKey.substring(fromIndex + delimiter.length);
      const fromSplit = fromTarget.split('-');
      const fromTimeDate = fromSplit[0];
      const fromManagerId = Number(fromSplit[1]);

      //배송일자에 맞는 담당자리스트 먼저 조회
      const targetDateContainer: DeliveryGroupType | undefined =
        firstContainers.find((item) => item.assignmentDate === fromTimeDate);

      if (!targetDateContainer) return;
      //내부 순회하며 최종적으로 주문형식의 데이터 찾아내고 set
      const targetManager = targetDateContainer.deliveryManager.find(
        (item) => item.info.id === fromManagerId
      );
      if (!targetManager) return;

      const targetItem = targetManager.items.find(
        (item, index) => index === source.index
      );

      if (!targetItem) return;

      setSelectedOrderGroup([targetItem]);
    }
  };

  //배송정보 post
  const saveHandler = () => {
    const postData = firstContainers
      .map((container) => {
        const assignmentDate = container.assignmentDate;

        // 각 deliveryManager에 대해 유효한 데이터를 필터링
        const datas: DeliveryAssignmentRequestDataType[] =
          container.deliveryManager
            .map((delivery) => {
              console.log('delivery:', delivery);
              if (!delivery.items || delivery.items.length < 1) return null; // 유효하지 않으면 null 반환
              console.log('delivery', delivery);

              const managerId = delivery.info.id;
              const orderIds = delivery.items
                .map((item) => item.orderId)
                .filter((id) => id !== undefined); // undefined 제외하고 필터링

              if (orderIds.length < 1) return null; // orders가 없으면 null 반환

              return {
                assignmentDate,
                managerId,
                orderIds,
              };
            })
            .filter((item) => item !== null); // null을 제외하고 유효한 데이터만 반환
        console.log('data', datas);
        // datas가 비어있으면 null 반환
        if (datas.length < 1) return null;

        return datas;
      })
      .filter((item) => item !== null); // 최종적으로 비어있는 값 제거

    const dto: DeliveryAssignmentRequestType = {
      targetDt: targetYear.toString() + targetMonth.toString(),
      data: postData.flat(),
    };

    fetchDeliveryAssignmentUpdate(dto);
  };

  //dnd 드래그하여 위치이동이 발생하였을 경우 호출
  const onDragUpdate = (update: DragUpdate) => {
    //변경된 목적지 위치정보
    const { destination } = update;
    if (!destination) return;
    //목적지 id
    const destinationKey = destination.droppableId as string;
    //목적지로부터 날짜, 배송자id 추출
    const delimiter = 'droppable-order-container-';
    const index = destinationKey.indexOf(delimiter);
    const target = destinationKey.substring(index + delimiter.length);
    const split = target.split('-');
    const timeDate = split[0];
    const managerId = Number(split[1]);

    //추출데이터 기반 탐색하여 주문 찾고 set
    const targetDateContainer = firstContainers.find(
      (item) => item.assignmentDate === timeDate
    );

    if (!targetDateContainer) return;

    const targetItem = targetDateContainer.deliveryManager.find(
      (item) => item.info.id === managerId
    )?.items;
    if (!targetItem) return;

    setSelectedOrderGroup([...targetItem]);
  };

  // --- requestAnimationFrame 초기화
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  //dnd drop시 호출
  const onDragEnd = ({ source, destination }: DropResult) => {
    //drop 가능한 장소가 아니면(!destination) return
    if (!destination) return;

    // 출발점 droppable id
    const sourceKey = source.droppableId as string;
    // 도착점 droppable id
    const destinationKey = destination.droppableId as string;

    // 배송담당자 이름 컨테이너 내부에서의 재정렬은 관련동작 없음
    if (
      sourceKey === 'droppable-manager-resource' &&
      destinationKey === 'droppable-manager-resource'
    )
      return;

    if (sourceKey === 'droppable-manager-resource') {
      //from배송담당자 이름 컨테이너

      const delimiter = 'droppable-manager-container-';
      //to 배송 컨테이너
      if (destinationKey.startsWith(delimiter)) {
        //target = 주문일자만 추출
        const index = destinationKey.indexOf(delimiter);
        const target = destinationKey.substring(index + delimiter.length);

        //배송담당자 정보 추출
        const _items = JSON.parse(
          JSON.stringify(deliveryManagers)
        ) as typeof deliveryManagers;

        const [targetItem] = _items.splice(source.index, 1);

        //해당 날짜 배송자목록에 이미 존재할경우 기존값 유지, 없다면 추가
        setFirstContainers((prev) =>
          prev.map((item) =>
            item.assignmentDate === target
              ? {
                  ...item,
                  deliveryManager: item.deliveryManager.some(
                    (manager) => manager.info.id === targetItem.id
                  )
                    ? item.deliveryManager
                    : [
                        ...item.deliveryManager,
                        {
                          info: {
                            id: targetItem.id,
                            name: targetItem.name,
                            position: targetItem.position,
                          },
                          items: [],
                        },
                      ],
                }
              : item
          )
        );
      }
    } else if (sourceKey === 'unhandled-order-container') {
      const delimiter = 'droppable-order-container-';
      //to 배송 가공 컨테이너
      if (destinationKey.startsWith(delimiter)) {
        //target = 주문일자-담당자id 추출
        const index = destinationKey.indexOf(delimiter);
        const target = destinationKey.substring(index + delimiter.length);
        const split = target.split('-');
        const timeDate = split[0];
        const managerId = Number(split[1]);

        //주문리스트(배정안된) 복사하여 새 배열 생성
        const _items = JSON.parse(
          JSON.stringify(orderData)
        ) as typeof orderData;

        const [targetItem] = _items.splice(source.index, 1);

        setOrderData(_items);

        //목적지에 set
        setFirstContainers((prev) =>
          prev.map((item) => {
            // console.log(
            //   'itemdate',
            //   item.assignmentDate,
            //   'targetdate',
            //   timeDate
            // );
            if (item.assignmentDate === timeDate) {
              // console.log('Target Assignment Date Found:', item.assignmentDate);
              return {
                ...item,
                deliveryManager: item.deliveryManager.map((delivery) => {
                  if (delivery.info.id === managerId) {
                    // console.log('Target Manager Found:', delivery.info);

                    // items 배열 상태와 삽입 위치 확인
                    // console.log('Original Items:', delivery.items);
                    // console.log('Target Item:', targetItem);
                    // console.log('Destination Index:', destination.index);

                    return {
                      ...delivery,
                      items: [
                        ...delivery.items.slice(0, destination.index),
                        targetItem,
                        ...delivery.items.slice(destination.index),
                      ],
                    };
                  }
                  return delivery;
                }),
              };
            }
            return item;
          })
        );
      }
    } else if (sourceKey.startsWith('droppable-order-container')) {
      const delimiter = 'droppable-order-container-';
      if (sourceKey === destinationKey && source.index === destination.index)
        return;
      //to 배송 가공 컨테이너
      if (destinationKey.startsWith(delimiter)) {
        //target = 주문일자-담당자id 추출
        const fromIndex = sourceKey.indexOf(delimiter);
        const fromTarget = sourceKey.substring(fromIndex + delimiter.length);
        const fromSplit = fromTarget.split('-');
        const fromTimeDate = fromSplit[0];
        const fromManagerId = Number(fromSplit[1]);

        const toIndex = destinationKey.indexOf(delimiter);
        const toTarget = destinationKey.substring(toIndex + delimiter.length);
        const toSplit = toTarget.split('-');
        const toTimeDate = toSplit[0];
        const toManagerId = Number(toSplit[1]);

        //주문리스트(배정안된) 복사하여 새 배열 생성
        const _items_from: DeliveryGroupType | undefined =
          firstContainers.find(
            (item) => item.assignmentDate === fromTimeDate
          ) || undefined;

        const _items_to: DeliveryGroupType | undefined =
          firstContainers.find((item) => item.assignmentDate === toTimeDate) ||
          undefined;

        //dnd 시작점, 끝점 못찾을 시의 처리
        if (!_items_from || !_items_to) return;

        //날짜주문들 중 배송자 찾고, drag start 인덱스 이용하여 데이터 추출
        const [targetItem] =
          _items_from.deliveryManager
            .find((delivery) => delivery.info.id === fromManagerId)
            ?.items.splice(source.index, 1) || [];

        //빈값예외처리
        if (!targetItem) return;

        //목표위치에 대입
        _items_to.deliveryManager
          .find((delivery) => delivery.info.id === toManagerId)
          ?.items.splice(destination.index, 0, targetItem);

        //원본배열 업데이트
        setFirstContainers((prev) =>
          prev.map((date) => {
            if (date.assignmentDate === fromTimeDate) {
              return {
                ..._items_from,
              };
            }
            if (date.assignmentDate === toTimeDate) {
              return {
                ..._items_to,
              };
            } else {
              return date; // 기존 요소를 그대로 반환
            }
          })
        );
      }
    }

    // console.log('source', sourceKey, 'destination', destinationKey);
    // console.log(
    //   'source index',
    //   source.index,
    //   'destination index',
    //   destination.index
    // );
  };

  const handleDateAdd = (addedDate: string) => {
    //날짜추가 핸들러
    const date = removeDashFromString(addedDate);

    if (firstContainers.some((item) => item.assignmentDate === date)) return;

    // 1. 배열을 날짜 기준으로 오름차순 정렬
    const sortedContainers = [...firstContainers].sort((a, b) =>
      a.assignmentDate.localeCompare(b.assignmentDate)
    );

    // 2. 새 날짜가 들어갈 위치를 찾기 (날짜보다 큰 첫 번째 인덱스)
    const targetIndex = sortedContainers.findIndex(
      (item) => item.assignmentDate > date
    );

    // 3. 적절한 위치에 새 항목을 삽입
    const newContainer: DeliveryGroupType = {
      assignmentDate: date,
      deliveryManager: [],
    };

    if (targetIndex === -1) {
      // 새로운 날짜가 가장 큰 날짜보다 크면 맨 뒤에 삽입
      sortedContainers.push(newContainer);
    } else {
      // 새 날짜가 삽입될 위치에 데이터 추가
      sortedContainers.splice(targetIndex, 0, newContainer);
    }

    // 4. 상태 업데이트
    setFirstContainers(sortedContainers);
  };

  //기존 dnd와 연동되는 맵 마커 로직
  // 선택된 담당자의 주소와 거래처명을 지도에 표시하기위한 주소/이름데이터
  // const addresses = useMemo(() => {
  //   if (!selectedOrderGroup || selectedOrderGroup.length < 1) return [];

  //   // const selectedData = selectedOrderGroup.flatMap((item) =>
  //   //   item.subRows.filter((subRow) => subRow.managerName === selectedManager)
  //   // );
  //   const selectedData: AddressItem[] =
  //     selectedOrderGroup &&
  //     selectedOrderGroup.map((item) => ({
  //       address: item.receiverAddress!,
  //       clientName: item.CUST_DES!,
  //     }));
  //   newWindow.current?.postMessage({ message: JSON.stringify(selectedData) });
  //   return selectedData;
  // }, [selectedOrderGroup]);

  const addresses = useMemo(() => {
    if (!pinnedOrders || pinnedOrders.length < 1) return [];

    // const selectedData = selectedOrderGroup.flatMap((item) =>
    //   item.subRows.filter((subRow) => subRow.managerName === selectedManager)
    // );
    const selectedData: AddressItem[] =
      pinnedOrders &&
      pinnedOrders.map((item) => ({
        address: item.receiverAddress!,
        clientName: item.CUST_DES!,
      }));
    newWindow.current?.postMessage({ message: JSON.stringify(selectedData) });
    return selectedData;
  }, [pinnedOrders]);

  function openNewWindow() {
    const newWin = window.open('/naver-map', '_blank', 'width=500,height=500');
    newWindow.current = newWin;

    if (newWindow.current) {
      newWindow.current.postMessage({ message: 'Hello from parent!' }, '*');
    }
  }

  const closeNewWindow = () => {
    if (newWindow.current && !newWindow.current.closed) {
      newWindow.current.close();
    }
  };

  function deleteHandler(
    assignmentDate: string,
    managerId: number,
    index: number
  ) {
    let deletedItem: OrderListType | null = null;
    const updatedContainers = firstContainers.map((container) => {
      if (container.assignmentDate !== assignmentDate) {
        return container; // 배송일자로 컨테이너 찾기
      }

      return {
        ...container,
        deliveryManager: container.deliveryManager.map((manager) => {
          if (manager.info.id !== managerId) {
            return manager; // 컨테이너의 매니저 찾기
          }

          // 특정 아이템 찾기
          const targetItem = manager.items[index];

          if (targetItem) {
            deletedItem = targetItem; // 제거된 아이템 저장
          }

          return {
            ...manager,
            items: manager.items.filter((_, itemIndex) => itemIndex !== index), // 인덱스 아이템 제거
          };
        }),
      };
    });

    deletedItem !== null &&
      setOrderData((prev) => [deletedItem as OrderListType, ...prev]);

    setFirstContainers(updatedContainers);
  }

  function detailHandler(item: OrderListType) {
    setModalTargetItem(item);
  }

  function monthChangeHandler(type: 'increase' | 'decrease') {
    if (firstContainers.length > 0) {
      const confirm = window.confirm(
        '저장되지않은 내용은 사라집니다. 계속하시겠습니까?'
      );
      if (!confirm) return;
    }
    if (type === 'increase') {
      if (targetMonth === 12) {
        setTargetYear(targetYear + 1);
        setTargetMonth(1);
      } else {
        setTargetMonth(targetMonth + 1);
      }
    }
    if (type === 'decrease') {
      if (targetMonth === 1) {
        setTargetYear(targetYear - 1);
        setTargetMonth(12);
      } else {
        setTargetMonth(targetMonth - 1);
      }
    }
  }

  useEffect(() => {
    modalTargetItem !== null
      ? setShowDetailModal(true)
      : setShowDetailModal(false);
  }, [modalTargetItem]);

  useEffect(() => {
    if (!targetYear || !targetMonth) return;

    fetchDeliveryAssignmentGet(
      targetYear.toString() + formatNumberToTwoDigitString(targetMonth)
    );

    const days = getDayCountFromYearMonthString(
      targetYear.toString(),
      targetMonth.toString()
    );
    const firstDay = days.firstDayOfMonth;
    const lastDay = days.lastDayOfMonth;
    const firstDayConvert = getYearMonthDateFromDate(firstDay);
    const lastDayConvert = getYearMonthDateFromDate(lastDay);
    setMinDay(
      firstDayConvert.yearString +
        '-' +
        firstDayConvert.monthString +
        '-' +
        firstDayConvert.dayString
    );
    setMaxDay(
      lastDayConvert.yearString +
        '-' +
        lastDayConvert.monthString +
        '-' +
        lastDayConvert.dayString
    );
  }, [targetYear, targetMonth]);
  return (
    <Container>
      {/* <div onClick={() => openNewWindow()}>새 창 열기</div>
      <div onClick={() => closeNewWindow()}>새 창 닫기</div>
      <div
        onClick={() => {
          newWindow.current?.postMessage(
            { message: 'Hello from parent!' },
            '*'
          );
        }}
      >
        메시지
      </div> */}
      {/* <MapContainer>
        <NaverMap addresses={addresses} />
      </MapContainer> */}
      {showDetailModal && (
        <DeliveryOrderDetailModal
          item={modalTargetItem}
          onClose={() => setModalTargetItem(null)}
        />
      )}
      <DateSection>
        <ClickableArrow onClick={() => monthChangeHandler('decrease')}>
          {'<'}
        </ClickableArrow>
        <div>
          {formatNumberToTwoDigitString(targetYear)}.
          {formatNumberToTwoDigitString(targetMonth)}
        </div>
        <ClickableArrow onClick={() => monthChangeHandler('increase')}>
          {'>'}
        </ClickableArrow>
      </DateSection>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <div style={{ width: '100px' }}>날짜추가 : </div>
        <CalendarInput
          type='date'
          min={minDay}
          max={maxDay}
          onChange={(e) => handleDateAdd((e.target as HTMLInputElement).value)}
        />
      </div>

      <TableSection>
        <DragDropContext
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
        >
          <Droppable
            droppableId='droppable-manager-resource'
            direction='horizontal'
            type='manager'
          >
            {(provided) => (
              <ManagerSection
                ref={provided.innerRef}
                {...provided.droppableProps} // Droppable의 props를 제공
              >
                <>
                  <div style={{ width: '100px' }}>담당자 추가 : </div>
                  {deliveryManagers.length > 0 &&
                    deliveryManagers.map((manager, index) => (
                      <Draggable
                        key={manager.id}
                        draggableId={`draggable-manager-resource-${manager.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <ManagerItem
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            {manager.name}
                          </ManagerItem>
                        )}
                      </Draggable>
                    ))}
                </>
                {provided.placeholder}
              </ManagerSection>
            )}
          </Droppable>

          <div>
            <div className='title-text'>- 배정된 주문 목록</div>
            <TableContainer>
              <TableRow className='table-header'>
                {/* <div className='date'></div> */}
                <div className='manager'></div>
                <div className='cust-name'>거래처명</div>
                {/* <div className='prod-des'>품목명</div>
                <div className='qty'>주문수량</div> */}
                <div className='time-date'>납기일</div>
                <div className='requirement'>특이사항</div>
                <div className='address'>주소</div>
              </TableRow>
              {firstContainers.map((container) => (
                <React.Fragment key={container.assignmentDate}>
                  {/* 배송일자 행 */}
                  <AssignmentDateRow>
                    <div
                      className='date'
                      style={{
                        flex: 1,
                        textAlign: 'start',
                        paddingLeft: '15px',
                        fontWeight: 700,
                      }}
                    >
                      {formatEightDigitStringToSlashForm(
                        container.assignmentDate
                      )}
                    </div>
                    {/* <td colSpan={6}></td> */}
                  </AssignmentDateRow>
                  <Droppable
                    droppableId={`droppable-manager-container-${container.assignmentDate}`}
                    type='manager'
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {/* 담당자 및 주문 항목 드롭 가능 */}
                        {container.deliveryManager.length > 0 ? (
                          container.deliveryManager.map(
                            (manager, managerIndex) => (
                              <div>
                                {/* 담당자 행 */}
                                <DeliveryManagerRow>
                                  {/* <div className='date' /> */}
                                  <div className='manager'>
                                    {manager.info.name}
                                  </div>
                                  <div className='cust-name' />
                                  {/* <div className='prod-des' />
                                  <div className='qty' /> */}
                                  <div className='time-date' />
                                  <div className='requirement' />
                                  <div className='address' />
                                </DeliveryManagerRow>
                                {provided.placeholder}
                                {/* 주문 항목 */}
                                <Droppable
                                  droppableId={`droppable-order-container-${container.assignmentDate}-${manager.info.id}`}
                                  type='order'
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef} // Droppable ref
                                      {...provided.droppableProps} // Droppable props
                                      style={{
                                        width: '100%',
                                      }} // 테이블 레이아웃 유지
                                    >
                                      {manager.items.length > 0 ? (
                                        manager.items.map((item, index) => (
                                          <Draggable
                                            key={`draggable-order-container-${container.assignmentDate}-${manager.info.id}-${index}`}
                                            draggableId={`draggable-order-container-${container.assignmentDate}-${manager.info.id}-${index}`}
                                            index={index}
                                          >
                                            {(provided) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <ProductDescRow
                                                  isLastItem={
                                                    // item?.subRows?.length === 1
                                                    //서브로우 없애면서 주석처리.
                                                    true
                                                  }
                                                >
                                                  {/* <div className='date' /> */}
                                                  <div
                                                    className='manager'
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                        'space-evenly',
                                                    }}
                                                  >
                                                    <FiTrash2
                                                      size={20}
                                                      color={theme.colors.red}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        deleteHandler(
                                                          container.assignmentDate,
                                                          manager.info.id,
                                                          index
                                                        )
                                                      }
                                                    />
                                                    <FiHelpCircle
                                                      size={20}
                                                      style={{
                                                        cursor: 'pointer',
                                                      }}
                                                      onClick={() =>
                                                        detailHandler(item)
                                                      }
                                                    />
                                                    {pinnedOrders.some(
                                                      (order) =>
                                                        order.orderId ===
                                                        item.orderId
                                                    ) ? (
                                                      <BiSolidMap
                                                        size={20}
                                                        style={{
                                                          cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                          setPinnedOrders(
                                                            (prev) =>
                                                              prev.filter(
                                                                (selected) =>
                                                                  selected.orderId !==
                                                                  item.orderId
                                                              )
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <BiMap
                                                        size={20}
                                                        style={{
                                                          cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                          setPinnedOrders(
                                                            (prev) => [
                                                              ...prev,
                                                              item,
                                                            ]
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                  <div className='cust-name'>
                                                    {item.CUST_DES}
                                                  </div>
                                                  {/* <div className='prod-des'>
                                                    {item.subRows &&
                                                      item.subRows[0].PROD_DES}
                                                  </div>
                                                  <div className='qty'>
                                                    {item.subRows &&`
                                                      item.subRows[0].QTY}
                                                  </div> */}
                                                  <div className='time-date'>
                                                    {item.TIME_DATE &&
                                                      formatEightDigitStringToSixDigitDot(
                                                        item.TIME_DATE
                                                      )}
                                                  </div>
                                                  <div className='requirement'>
                                                    {item.deliveryRemarks}
                                                  </div>
                                                  <div className='address'>
                                                    {item.receiverAddress}
                                                  </div>
                                                </ProductDescRow>
                                                {/* {item.subRows &&
                                                  item.subRows.length > 1 &&
                                                  item.subRows.map(
                                                    (sub, index) => {
                                                      if (index === 0) return;
                                                      return (
                                                        <ProductDescRow
                                                          isLastItem={
                                                            index + 1 ===
                                                            item.subRows?.length
                                                          }
                                                        >
                                                          <div className='date' />
                                                          <div className='manager' />
                                                          <div className='cust-name' />
                                                          <div className='prod-des'>
                                                            {sub.PROD_DES}
                                                          </div>
                                                          <div className='qty'>
                                                            {sub.QTY}
                                                          </div>
                                                          <div className='time-date' />
                                                          <div className='requirement' />
                                                          <div className='address' />
                                                        </ProductDescRow>
                                                      );
                                                    }
                                                  )} */}
                                              </div>
                                            )}
                                          </Draggable>
                                        ))
                                      ) : (
                                        <div>주문없음</div>
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            )
                          )
                        ) : (
                          <div>담당자없음</div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </React.Fragment>
              ))}
            </TableContainer>
            <div style={{ height: '50px' }} />
            <div className='title-text'>- 미배정 주문 목록</div>
            <TableContainer>
              <TableRow
                style={{ display: 'flex', flexDirection: 'row' }}
                className='table-header'
              >
                <div className='time-date'></div>
                <div className='cust-name'>거래처명</div>
                {/* <div className='prod-des'>품목명</div>
                <div className='qty'>주문수량</div> */}
                <div className='time-date'>납기일</div>
                <div className='requirement'>특이사항</div>
                <div className='address'>주소</div>
              </TableRow>
              <Droppable droppableId={'unhandled-order-container'} type='order'>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{ width: '100%' }}
                  >
                    {orderData.length > 0 &&
                      orderData.map((order, index) => {
                        return (
                          <Draggable
                            draggableId={`unhandled-order-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <ProductDescRow
                                  isLastItem={
                                    // item?.subRows?.length === 1
                                    //서브로우 없애면서 주석처리.
                                    true
                                  }
                                >
                                  <div
                                    className='manager'
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '10px',
                                    }}
                                  >
                                    {/* {order.TIME_DATE &&
                                      formatEightDigitStringToSixDigitDot(
                                        order.TIME_DATE
                                      )} */}
                                    <FiHelpCircle
                                      size={20}
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => detailHandler(order)}
                                    />
                                    {pinnedOrders.some(
                                      (pinnedOrder) =>
                                        pinnedOrder.orderId === order.orderId
                                    ) ? (
                                      <BiSolidMap
                                        size={20}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          setPinnedOrders((prev) =>
                                            prev.filter(
                                              (selected) =>
                                                selected.orderId !==
                                                order.orderId
                                            )
                                          )
                                        }
                                      />
                                    ) : (
                                      <BiMap
                                        size={20}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          setPinnedOrders((prev) => [
                                            ...prev,
                                            order,
                                          ])
                                        }
                                      />
                                    )}
                                  </div>
                                  <div className='cust-name'>
                                    {order.CUST_DES}
                                  </div>
                                  <div className='time-date'>
                                    {order.TIME_DATE &&
                                      formatEightDigitStringToSixDigitDot(
                                        order.TIME_DATE
                                      )}
                                  </div>
                                  {/* <div className='prod-des'></div>
                                  <div className='qty'></div> */}
                                  <div className='requirement'>
                                    {order.deliveryRemarks}
                                  </div>
                                  <div className='address'>
                                    {order.receiverAddress}
                                  </div>
                                </ProductDescRow>
                                {/* {order.subRows &&
                                  order.subRows.length > 0 &&
                                  order.subRows.map((sub) => (
                                    <OrderSubRow>
                                      <div className='time-date'></div>
                                      <div className='cust-name' />
                                      <div className='prod-des'>
                                        {sub.PROD_DES}
                                      </div>
                                      <div className='qty'>{sub.QTY}</div>
                                      <div className='requirement'></div>
                                      <div className='address'></div>
                                    </OrderSubRow>
                                  ))} */}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  </div>
                )}
              </Droppable>
            </TableContainer>
          </div>
        </DragDropContext>
      </TableSection>
    </Container>
  );
};

const TableSection = styled.div`
  min-width: 1200px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize[15]};
  color: ${({ theme }) => theme.colors.charcoal};

  .table-header {
    font-size: ${({ theme }) => theme.fontsize[18]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal};
    font-weight: 600;
  }

  .date {
    flex: 0 0 80px;
  }

  .time-date {
    flex: 0 0 90px;
  }
  .manager {
    flex: 0 0 95px;
  }
  .prod-des {
    flex: 0 1 17%;
  }
  .cust-name {
    flex: 0 1 25%;
  }
  .qty {
    flex: 0 0 70px;
  }
  .requirement {
    flex: 0 1 25%;
  }
  .address {
    flex: 0 1 35%;
  }
  * {
    align-content: center;
    text-align: center;
    box-sizing: border-box;
  }
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-height: 30px;
  *:not(svg) {
    min-height: 30px;
    max-height: 30px;
  }
`;

const AssignmentDateRow = styled(TableRow)`
  height: 30px;
  /* background-color: #d0d0d0a8; */
  color: #0031ab;

  .time-date {
  }
  .manager {
    flex: 0 0 95px;
  }
  .prod-des {
    flex: 0 1 17%;
  }
  .cust-name {
    flex: 0 1 17%;
  }
  .qty {
    flex: 0 0 70px;
  }
  .requirement {
    flex: 0 1 25%;
  }
  .address {
    flex: 0 1 35%;
  }

  * {
    flex: 1;
  }
`;

const DeliveryManagerRow = styled(TableRow)`
  background-color: #eef4ff99;
  .manager,
  .prod-des,
  .cust-name,
  .qty,
  .time-date,
  .requirement,
  .address {
    border-bottom: 1px solid ${({ theme }) => theme.colors.charcoal};
  }

  *:not(svg) {
    width: 100%;
    height: 100%;
  }
`;

const CustomerDescRow = styled(TableRow)`
  .date {
  }
  .manager {
  }
  .prod-des {
  }
  .cust-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .qty {
    flex: 0 0 70px;
  }
  .requirement {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .address {
    flex: 0 1 35%;
  }

  *:not(svg) {
    width: 100%;
    height: 100%;
  }
`;

const ProductDescRow = styled(TableRow)<{ isLastItem: boolean }>`
  .prod-des,
  .cust-name,
  .requirement {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .requirement {
    padding: 0px 15px;
  }
  ${({ isLastItem }) =>
    isLastItem &&
    css`
      .time-date,
      .prod-des,
      .cust-name,
      .qty,
      .requirement,
      .address {
        border-bottom: 1px solid ${({ theme }) => theme.colors.wine};
      }

      *:not(svg) {
        width: 100%;
        height: 100%;
      }
    `}
`;

const OrderMainRow = styled(TableRow)`
  .time-date {
  }
  .manager {
    flex: 0 0 95px;
  }
  .prod-des {
    flex: 0 1 17%;
  }
  .cust-name {
    flex: 0 1 17%;
  }
  .qty {
    flex: 0 0 70px;
  }
  .requirement {
    flex: 0 1 25%;
  }
  .address {
    flex: 0 1 35%;
  }

  * {
    width: 100%;
    height: 100%;
  }
  svg {
    width: auto;
    height: auto;
  }
`;

const OrderSubRow = styled(TableRow)`
  .time-date {
  }
  .manager {
    flex: 0 0 95px;
  }
  .prod-des {
    flex: 0 1 17%;
  }
  .cust-name {
    flex: 0 1 17%;
  }
  .qty {
    flex: 0 0 70px;
  }
  .requirement {
    flex: 0 1 25%;
  }
  .address {
    flex: 0 1 35%;
  }

  * {
    width: 100%;
    height: 100%;
  }
`;

const StyledTable = styled.table`
  background-color: white;
  border-collapse: collapse;
  * {
    box-sizing: border-box;
  }

  .date {
    width: 110px;
  }
  .manager {
    width: 95px;
  }
  .prod-des {
    width: 200px;
  }
  .cust-name {
    width: 180px;
  }
  .qty {
    width: 70px;
  }
  .time-date {
    width: 160px;
  }
  .require {
    width: 400px;
  }
  .addr {
    width: 400px;
  }
  * {
    align-content: center;
    text-align: center;
  }
`;

const StyledDiv = styled.div`
  background-color: white;
  border-collapse: collapse;
  * {
    box-sizing: border-box;
  }

  .date {
    width: 180px;
  }
  .manager {
    width: 140px;
  }
  .cust-name {
    width: 180px;
  }
  .prod-des {
    width: 200px;
  }
  .qty {
    width: 150px;
  }
  .time-date {
    width: 220px;
  }
  .require {
    width: 400px;
  }
  .addr {
    width: 400px;
  }
  * {
    align-content: center;
    text-align: center;
  }
`;

const CalendarInput = styled.input`
  width: 130px;
  /* padding: 5px; */
  color: #595b60;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 13px; */
  text-align: center;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
`;

// 스타일 컴포넌트 정의
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .title-text {
    font-size: ${({ theme }) => theme.fontsize[20]};
    height: 42px;
    align-content: center;
  }
`;

const DateSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontsize.header_main};
`;

const ClickableArrow = styled.div`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.blue_hover};
  }
`;

const ManagerSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: auto;
  height: 33px;
`;

const ManagerItem = styled.div`
  background-color: white;
  padding: 3px 7px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontsize.header_semi};
  cursor: grab;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  gap: 20px;
  margin-bottom: 10px;
`;

const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DateInput = styled.input`
  color: #595b60;
  font-weight: 800;
  font-size: 13px;
  text-align: center;
  border: none;
  cursor: pointer;
`;

const Text = styled.p`
  margin: 0;
  color: #595b60;
  font-size: 15px;
  font-weight: 800;
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #595b60;
  padding: 2px 5px 2px 10px;
  cursor: pointer;
`;

const ManagerName = styled(Text)`
  font-size: 12px;
  margin-right: 5px;
`;

const Button = styled.div<{ $isActive: boolean }>`
  width: fit-content;
  padding: 5px 10px;
  background-color: ${({ $isActive }) => ($isActive ? '#14ADEA' : '#fff')};
  color: ${({ $isActive }) => ($isActive ? '#fff' : '#6E7074')};
  border: 1px solid ${({ $isActive }) => ($isActive ? '#14ADEA' : '#6E7074')};
  font-size: 12px;
  font-weight: 600;
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'not-allowed')};
  pointer-events: ${({ $isActive }) => ($isActive ? 'auto' : 'none')};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
`;

const ResizableContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;

const TabHeader = styled.div`
  display: flex;
  background-color: #f5f5f5;
`;

const TabButton = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 10px;
  background-color: ${({ active }) => (active ? '#fff' : '#f5f5f5')};
  border: none;
  border-bottom: ${({ active }) => (active ? 'none' : '1px solid #ccc')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  cursor: pointer;
`;

const TabContent = styled.div`
  flex: 1;
  max-height: 100%;
  overflow: auto;
  flex-direction: column;
  padding-top: 10px;
`;

const MapContainer = styled.div`
  /* flex: 1; */
  display: flex;
  /* height: calc(100vh - 230px); */
  width: 500px;
  height: 500px;
`;

const TableHeader = styled.thead`
  background-color: #fff;
`;
const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 7px;
  color: #595b60;
  font-weight: 800;
  font-size: 11px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
  background-color: #fff;
`;

const TableHeaderCell = styled.th<{ $width: number }>`
  width: ${(props) => props.$width}px;
  font-weight: 800;
  padding: 6px;
  color: #000;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #e5e5e5;
`;

type deliv = {
  assignmentDate: string; // 배송일자 YYYYMMDD string
  deliveryPersonName: string; // 배송담당자 이름 string
  deliveryPersonPosition: string; //배송담당자 직급 string

  //주문묶음
  orderList: [
    {
      custName: string; // 거래처명 string
      receiverAddress: string; //  실배송주소 string
      deliveryOrder: number; //배송순서 int
      id: number; //   delivery_assignment_id -> 배송상태 업데이트용
      deliveryStatus: EnumType; //배송상태 enum
      itemList: [
        {
          prodName: string; //품목명 string
          qty: number; //주문수량 int
        },
        {
          prodName: string;
          qty: number;
        }
      ];
    }
  ];
};
