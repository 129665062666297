import styled from 'styled-components';
import { RootState } from '../../store/modules';
import { useSelector } from 'react-redux';
import { BsPersonCircle } from 'react-icons/bs';
import { BiSolidDownArrow } from 'react-icons/bi';
import { BiSolidUpArrow } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearLoginUserData } from '../../store/modules/loginUserData';
import { UserInfoTooltip } from './UserInfoTooltip';

export const UserInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUserData = useSelector((state: RootState) => state.loginUserData);
  const [openBox, setOpenBox] = useState<boolean>(false);
  const [roleChar, setRoleChar] = useState<string | null>(null);
  useEffect(() => {
    setRoleChar(
      loginUserData?.role === 'MANAGER'
        ? 'M'
        : loginUserData?.role === 'ADMIN'
        ? 'A'
        : loginUserData?.role === 'WORKER'
        ? 'W'
        : null
    );
  }, [loginUserData]);
  return (
    <Container onClick={() => setOpenBox(!openBox)}>
      <UserInfoTooltip show={openBox} />
      {loginUserData && (
        <>
          <RoleChar role={roleChar || ''} className='flex-center'>
            {roleChar}
          </RoleChar>
          <h4>{loginUserData.userName || undefined}</h4>
          {openBox ? (
            <BiSolidUpArrow size={12} />
          ) : (
            <BiSolidDownArrow size={12} />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white_hover};
  }
`;

const RoleChar = styled.div<{ role: string }>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 13px;
  min-width: 22px;
  min-height: 22px;
  border-radius: 50%;
  background-color: ${({ role, theme }) =>
    role === 'M'
      ? theme.colors.green
      : role === 'A'
      ? theme.colors.red
      : theme.colors.blue};
`;
