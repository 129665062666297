import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { useEffect, useRef } from 'react';
import { RootState } from '../store/modules';
import { useSelector } from 'react-redux';
import { getAccessTokenAsync } from '../service/tokenService';
import { useDispatch } from 'react-redux';
import { API_BASE_URL } from '../api/axiosConfig';
import {
  addStockHistoryData,
  increaseProductionCount,
  setProductionData,
  setStockData,
  setStockHistoryData,
  updateProductionData,
  updateStockHistoryData,
} from '../store/modules/socketData';

export const WebsocketManager = () => {
  const dispatch = useDispatch();
  let stompClient: Client | null = null;
  const stompClientRef = useRef<Client | null>(null);
  let subscriptions: { [key: string]: any } = {}; // 각 구독을 저장할 객체
  // const stompClientRef = useRef<Client | null>(null);

  async function connectSocket() {
    let accessToken = await getAccessTokenAsync();
    if (!accessToken) return;
    if (stompClientRef.current) {
      console.log('WebSocket already connected');
      return;
    }

    const socket = new SockJS(`${API_BASE_URL}/ws`);

    stompClient = new Client({
      webSocketFactory: () => socket, // SockJS로 WebSocket 팩토리 생성
      onConnect: (frame) => {
        stompClientRef.current = stompClient; // stompClient를 useRef에 저장
        //생산관련 토픽 구독 시작
        subscribe('production');
        subscribe('stock');
        subscribe('stock-history');
      },
      onStompError: (frame) => {
        console.error('Broker reported error: ' + frame.headers['message']);
        console.error('Additional details: ' + frame.body);
      },
      connectHeaders: {
        Authorization: `Bearer ${accessToken}`, // JWT 토큰을 헤더에 추가
      },
    });
    console.log('stompclient', stompClient);
    stompClient.activate(); // stompClient 활성화
  }

  //구독함수
  function subscribe(topic: string) {
    if (stompClientRef.current) {
      if (subscriptions[topic]) {
        console.log(`Already subscribed to ${topic}. Skipping subscription.`);
        return; // 이미 구독한 토픽이면 구독하지 않음
      }
      console.log(`Do subscribing to ${topic}`);

      // 구독 추가
      const subscription = stompClientRef.current.subscribe(
        `/sub/${topic}`,
        (message) => {
          const data = JSON.parse(message.body);

          switch (topic) {
            case 'production':
              if (data.type === 'CREATED') {
                dispatch(setProductionData(data.data));
              } else if (data.type === 'INCREASED') {
                dispatch(increaseProductionCount(data.data));
              } else if (data.type === 'UPDATED') {
                dispatch(updateProductionData(data.data));
              }
              break;

            case 'stock':
              if (data.type === 'UPDATED') {
                dispatch(setStockData(data.data));
              }

            case 'stock-history':
              if (data.type === 'CREATED') {
                dispatch(setStockHistoryData(data.data));
              }
              if (data.type === 'UPDATED') {
                dispatch(updateStockHistoryData(data.data));
              }
              if (data.type === 'ADDED') {
                dispatch(addStockHistoryData(data.data));
              }

            default:
              break;
          }
          console.log(`Received message from ${topic}:`, data);
        }
      );
      // 구독 객체를 subscriptions 객체에 저장
      subscriptions[topic] = subscription;
    }
  }

  // 구독 해제 함수
  function unsubscribe(topic: string) {
    const subscription = subscriptions[topic];
    if (subscription) {
      console.log(`Unsubscribing from ${topic}`);
      subscription.unsubscribe(); // 구독 해제
      delete subscriptions[topic]; // 구독 객체 삭제
    }
  }

  // WebSocket 연결 종료 함수
  function disconnectSocket() {
    if (stompClientRef.current) {
      // 모든 구독을 해제하고 WebSocket 종료
      Object.keys(subscriptions).forEach((topic) => {
        unsubscribe(topic); // 각 구독 해제
      });
      stompClientRef.current.deactivate(); // WebSocket 종료
      stompClientRef.current = null;
      console.log('Disconnected from WebSocket');
    } else {
      console.log('Cannot find stompClient(In disconnect socket');
    }
  }

  //sub/order 구독
  // function subscribeOrder() {
  //   if (stompClientRef.current) {
  //     console.log('do sub');
  //     stompClientRef.current.subscribe('/sub/order', (message) => {
  //       const data = JSON.parse(message.body);
  //       console.log('Received message:', data);
  //     });
  //     console.log('stompcurrent', stompClientRef.current);
  //   }
  // }

  function testGreetingOrder() {
    if (stompClientRef.current) {
      stompClientRef.current.publish({
        destination: '/pub/send-greeting-order',
      });
    }
  }

  function testGreetingItem() {
    if (stompClientRef.current) {
      stompClientRef.current.publish({
        destination: '/pub/send-greeting-item',
      });
    }
  }

  function testGreetingProduction() {
    if (stompClientRef.current) {
      stompClientRef.current.publish({
        destination: '/pub/production',
      });
    }
  }

  useEffect(() => {
    connectSocket();

    return () => {
      disconnectSocket();
    };
  }, []);

  return null;
};
