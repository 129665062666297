import styled, { css, useTheme } from 'styled-components';
import {
  BomRequestType,
  OrderListType,
  ParcelTraderResponseType,
  TraderType,
} from '../../../../api/apiConfig';
import Form from 'react-bootstrap/Form';
import ModalBase from '../ModalBase';
import {
  BiPlusCircle,
  BiSolidLeftArrow,
  BiSolidRightArrow,
  BiX,
} from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { ProdType } from '../../../table/Table';
import { FiTrash2 } from 'react-icons/fi';
import { IoIosCheckmark } from 'react-icons/io';
import { IoIosSquareOutline } from 'react-icons/io';

export const ParcelTraderEditModal = ({
  traderProp,
  parcelTraderProp,
  onClose,
  onSave,
}: {
  traderProp: TraderType;
  parcelTraderProp: ParcelTraderResponseType;
  onClose: () => void;
  onSave: (trader: ParcelTraderResponseType) => void;
}) => {
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [trader, setTrader] = useState<TraderType>(traderProp);

  const [label, setLabel] = useState<string>(parcelTraderProp.label || '');

  const [parcelTrader, setParcelTrader] =
    useState<ParcelTraderResponseType>(parcelTraderProp);

  function saveHandler() {
    const prop: ParcelTraderResponseType = {
      id: parcelTrader.id || null,
      label: label || null,
      custCd: parcelTrader.custCd,
    };
    onSave(prop);
  }

  return (
    <ModalBase>
      <Container>
        <ContainerHeader>
          <BiX
            color={'white'}
            size={40}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </ContainerHeader>
        <ContainerBody>
          <Header>거래처 정보</Header>
          <Body>
            <div className='row'>
              <h4 className='row-label'>거래처명</h4>
              <h4 className='row-value'>{trader.CUST_NAME}</h4>
            </div>
            <div className='row'>
              <h4 className='row-label'>주소</h4>
              <h4 className='row-value'>{trader.ADDR}</h4>
            </div>
            {/* <div className='row'>
              <div className='row-label'>배송지 주소</div>
              <div className='row-value'>{trader?.receiverAddress}</div>
            </div>
            <div className='row'>
              <div className='row-label'>주문 특이사항</div>
              <div className='row-value'>{trader?.deliveryRemarks}</div>
            </div> */}
          </Body>
          <Header>소모품 정보</Header>
          <Body>
            <div className='row'>
              <h4 className='row-label'>엑셀 변환될 이름</h4>
              <input
                className='row-value'
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
          </Body>
          <ButtonSection>
            <Button>
              <h4 onClick={() => saveHandler()}>저장</h4>
            </Button>
          </ButtonSection>
        </ContainerBody>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  border-radius: 15px;
  /* overflow: hidden; */
  position: relative;
  width: 600px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ContainerHeader = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.colors.softBlack};
`;

const ContainerBody = styled.div`
  padding: 0px 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontsize[20]};
  height: 50px;
  align-content: center;
`;

const AddContainer = styled.div`
  display: flex;
  top: 0;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const AddButton = styled.h4`
  flex: 0 0 15%;
`;

const SearchContainer = styled.div`
  bottom: 0;
  display: flex;
  position: relative;
  overflow: visible;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  flex: 0 0 80%;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 10px;
`;

const Button = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  align-content: center;
  text-align: center;
  padding: 5px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue_hover};
  }
`;

const OptionalH4 = styled.h4<{ selectable: boolean }>`
  ${({ selectable }) =>
    selectable
      ? css`
          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.white_hover};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.whiteGray};
        `}
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize[16]};
  padding: 0px 15px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
  .row-label {
    display: flex;
    align-items: center;
  }
  .row-button {
    flex: 0 0 10%;
  }
  .row-prod {
    flex: 1 0 70%;
    display: flex;
    gap: 15px;
  }

  .row-qty {
    flex: 0 0 20%;
  }
`;
