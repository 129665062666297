import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  /* height: 100vh; */
  z-index: 999;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

export const Sidebar = styled.div<{ open: boolean }>`
  width: ${({ open }) => (open ? '250px' : '0px')};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.softBlack};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize.header_semi};
  overflow: hidden;
  transition: width 0.3s ease;
  div {
    width: 250px;
  }
`;

export const ContainerFlipper = styled.div`
  width: 25px;
  height: 100%;
  text-align: center;
  align-content: center;
  background-color: ${({ theme }) => theme.colors.darkGray};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`;

export const HeaderImgDiv = styled.div`
  width: 100%;
  padding: 30px 0px;
  display: flex;
`;

export const Menu = styled.div<{ focus?: boolean }>`
  height: 40px;
  padding: 0px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  &:hover {
    background-color: ${({ theme }) => theme.colors.darkNavy};
  }
  ${(props) =>
    props.focus &&
    css`
      color: ${({ theme }) => theme.colors.blue};
    `}
`;

export const MenuItem = styled.div<{ focus?: boolean }>`
  padding: 5px 20px 5px 43px;
  width: 100%;
  height: 28px;
  cursor: pointer;

  /* line-height: 18px; */
  &:hover {
    background-color: ${({ theme }) => theme.colors.brightNavy};
  }
  ${(props) =>
    props.focus &&
    css`
      color: ${({ theme }) => theme.colors.blue};
    `}
`;

export const MenuItemWrapper = styled.div<{
  show: boolean;
  itemLength: number;
}>`
  width: 100%;
  overflow: hidden;
  height: ${({ show, itemLength }) => (show ? `${itemLength * 38}px` : '0px')};
  transition: height 0.5s ease;
`;
