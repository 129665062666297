import styled from 'styled-components';
import { ProductionCardProps } from './ProductionCircularCard';
import { useTheme } from 'styled-components';
import { CustomProgressBar } from './CustomProgressBar';

export type ProductionSquareCardType = {
  current: number;
  goal: number;
  workbayName: string;
  itemName: string;
  itemSize: string;
  progress: string;
};
export const ProductionSquareCard = ({
  current,
  goal,
  workbayName,
  itemName,
  itemSize,
  progress,
}: ProductionSquareCardType) => {
  const theme = useTheme();
  const percentage = (current / goal) * 100;
  const isExceeded = current > goal;
  return (
    <Container active={progress === 'P'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: '6px',
          boxSizing: 'border-box',
        }}
      >
        <div className='medium-text'>{itemName}</div>
        <ProductionInfo className='medium-text'>
          <>
            {current} / {goal}
          </>
        </ProductionInfo>
      </div>
      <SizeDes>{itemSize}</SizeDes>
      <ProgressContainer>
        <div className='large-text'>{percentage.toFixed(0)}%</div>
        <ProgressbarContainer>
          <CustomProgressBar
            current={current}
            goal={goal}
            style={{ height: '11px', flex: 1 }}
          />
        </ProgressbarContainer>
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div<{ active: boolean }>`
  background-color: ${({ active, theme }) =>
    active ? theme.colors.white : theme.colors.whiteGray};
  width: 100%;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.darkNavy};
  .small-text {
    font-size: ${({ theme }) => theme.fontsize[13]};
    font-weight: 500;
  }
  .medium-text {
    font-size: ${({ theme }) => theme.fontsize[18]};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brightNavy};
  }
  .large-text {
    font-size: ${({ theme }) => theme.fontsize[27]};
    color: ${({ theme }) => theme.colors.lightBlue};
    transform: scaleY(0.9);
    font-weight: 900;
  }
`;

const SizeDes = styled.div`
  height: 18px;
  width: 100%;
  margin-top: 6px;
  margin-left: 6px;
`;

const ProductionInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
`;

const ProgressbarContainer = styled.div`
  width: 80%;
  align-self: center;
  height: 35px;
  align-items: flex-end;
  padding-bottom: 7px;
  display: flex;
`;
