import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/modules';

const AnonymousRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSidebarOpen = useSelector(
    (state: RootState) => state.sidebar.uiStatus.open
  );

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, [location]);

  return (
    <>
      <Routes>
        <Route path='/login' element={<LoginPage />} />
      </Routes>
    </>
  );
};

export default AnonymousRouter;
