import * as Styled from './TableStyled';

type TableConfigProps = {
  show: boolean;
  Header_Overflow_Type: 'wrap' | 'hide';
  Body_Overflow_Type: 'wrap' | 'hide';
  Body_Align_Hori_Type: 'left' | 'center' | 'right';
  Cell_Hori_Padd_Value: number;
  Body_Vert_Padd_Value: number;
  setHeaderOverflowType: (value: 'wrap' | 'hide') => void;
  setBodyOverflowType: (value: 'wrap' | 'hide') => void;
  setBodyAlignHoriType: (value: 'left' | 'center' | 'right') => void;
  setCellHoriPaddValue: (value: number) => void;
  setBodyVertPaddValue: (value: number) => void;
};

const TableConfig = (props: TableConfigProps) => {
  return (
    <Styled.TableOptionContainer show={props.show}>
      <Styled.TableOptionContent>
        <Styled.TableConfigRow>
          헤더 글자길이가 칸을 넘어갈 시
          <Styled.RadioContainer>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Header_Overflow_Type === 'wrap'}
                onChange={() => props.setHeaderOverflowType('wrap')}
              />
              줄넘김
            </div>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Header_Overflow_Type === 'hide'}
                onChange={() => props.setHeaderOverflowType('hide')}
              />
              숨김
            </div>
          </Styled.RadioContainer>
        </Styled.TableConfigRow>
        <Styled.TableConfigRow>
          바디 글자길이가 칸을 넘어갈 시
          <Styled.RadioContainer>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Body_Overflow_Type === 'wrap'}
                onChange={() => props.setBodyOverflowType('wrap')}
              />
              줄넘김
            </div>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Body_Overflow_Type === 'hide'}
                onChange={() => props.setBodyOverflowType('hide')}
              />
              숨김
            </div>
          </Styled.RadioContainer>
        </Styled.TableConfigRow>
        <Styled.TableConfigRow>
          바디 셀 가로정렬 위치
          <Styled.RadioContainer>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Body_Align_Hori_Type === 'left'}
                onChange={() => props.setBodyAlignHoriType('left')}
              />
              좌측
            </div>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Body_Align_Hori_Type === 'center'}
                onChange={() => props.setBodyAlignHoriType('center')}
              />
              중앙
            </div>
            <div className='flex-align-center'>
              <input
                type='radio'
                checked={props.Body_Align_Hori_Type === 'right'}
                onChange={() => props.setBodyAlignHoriType('right')}
              />
              우측
            </div>
          </Styled.RadioContainer>
        </Styled.TableConfigRow>
        <Styled.TableConfigRow>
          셀 좌/우 여백값
          <Styled.RadioContainer>
            <div className='flex-align-center'>
              <input
                id='numberInput'
                style={{ width: '50px' }}
                type='number'
                value={props.Cell_Hori_Padd_Value}
                onChange={(event) => {
                  props.setCellHoriPaddValue(Number(event.target.value));
                }}
                min={0} // 최소값 설정
                step={1} // 증가 및 감소할 값 설정
              />
            </div>
          </Styled.RadioContainer>
        </Styled.TableConfigRow>
        <Styled.TableConfigRow>
          바디 셀 상/하 여백값
          <Styled.RadioContainer>
            <div className='flex-align-center'>
              <input
                id='numberInput'
                style={{ width: '50px' }}
                type='number'
                value={props.Body_Vert_Padd_Value}
                onChange={(event) => {
                  props.setBodyVertPaddValue(Number(event.target.value));
                }}
                min={0} // 최소값 설정
                step={1} // 증가 및 감소할 값 설정
              />
            </div>
          </Styled.RadioContainer>
        </Styled.TableConfigRow>
      </Styled.TableOptionContent>
    </Styled.TableOptionContainer>
  );
};

export default TableConfig;

//헤더 글자길이가 칸을 넘어갈 시   줄넘김v   숨김v
//바디 글자길이가 칸을 넘어갈 시   줄넘김v   숨김v
//바디 셀 가로정렬         좌측  중앙  우측
//바디 셀 좌우여백값       up/down버튼
