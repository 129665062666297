// NaverMap.tsx
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface AddressItem {
  address: string;
  clientName: string;
}

interface NaverMapProps {
  addresses?: AddressItem[];
  // managerAddresses: AddressItem[];
}

const NaverMap: React.FC<NaverMapProps> = ({ addresses = [] }) => {
  const mapElement = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<naver.maps.Map | null>(null);
  const markersRef = useRef<naver.maps.Marker[]>([]);

  const minZoomLevel = 14;

  useEffect(() => {
    const { naver } = window as any;

    if (!mapElement.current || !naver) return;

    if (!mapRef.current) {
      mapRef.current = new naver.maps.Map(mapElement.current, {
        center: new naver.maps.LatLng(37.5665, 126.978),
        zoom: 10,
      });
    }

    const map = mapRef.current!; // map이 null이 아님을 명시

    // 기존 마커 제거
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];

    const validCoords: naver.maps.LatLng[] = [];

    const addMarkers = (items: AddressItem[], color: string) => {
      return items.map((item: AddressItem) => {
        return new Promise<void>((resolve) => {
          naver.maps.Service.geocode(
            { query: item.address },
            (status: any, response: any) => {
              if (status !== naver.maps.Service.Status.OK) {
                console.error(`주소를 찾을 수 없습니다: ${item.address}`);
                resolve();
                return;
              }

              if (
                response.v2 &&
                response.v2.addresses &&
                response.v2.addresses.length > 0
              ) {
                const result = response.v2.addresses[0];
                const coords = new naver.maps.LatLng(result.y, result.x);

                // 기본 마커 모양과 유사한 커스텀 SVG 아이콘 정의
                const markerIcon = {
                  content: `
                    <svg width="24" height="37" viewBox="0 0 24 37" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0C5.37258 0 0 5.37258 0 12C0 21 12 37 12 37C12 37 24 21 24 12C24 5.37258 18.6274 0 12 0Z" fill="${color}" stroke="#000" stroke-width="1"/>
                      <circle cx="12" cy="12" r="5" fill="#fff"/>
                    </svg>
                  `,
                  size: new naver.maps.Size(20, 33),
                  anchor: new naver.maps.Point(12, 33),
                };

                const marker = new naver.maps.Marker({
                  position: coords,
                  map: map,
                  icon: markerIcon,
                  title: item.clientName,
                });

                const infoWindow = new naver.maps.InfoWindow({
                  content: `<div style="padding:5px; font-size:12px;">${item.clientName}</div>`,
                  disableAnchor: false,
                  backgroundColor: 'white',
                  borderColor: 'gray',
                  borderWidth: 1,
                  anchorSize: new naver.maps.Size(10, 10),
                  anchorSkew: true,
                  pixelOffset: new naver.maps.Point(0, 0),
                });

                // 기본적으로 InfoWindow 열기
                infoWindow.open(map, marker);

                naver.maps.Event.addListener(marker, 'click', () => {
                  if (infoWindow.getMap()) {
                    infoWindow.close();
                  } else {
                    infoWindow.open(map, marker);
                  }
                });

                markersRef.current.push(marker);
                validCoords.push(coords);

                resolve();
              } else {
                console.error(`주소를 찾을 수 없습니다: ${item.address}`);
                resolve();
              }
            }
          );
        });
      });
    };

    const normalMarkerPromises = addMarkers(addresses, '#ff0000'); // 빨간색
    // const managerMarkerPromises = addMarkers(managerAddresses, '#0000ff'); // 파란색

    Promise.all([...normalMarkerPromises]).then(() => {
      if (validCoords.length > 0) {
        const bounds = new naver.maps.LatLngBounds();
        console.log('bounds', bounds);
        validCoords.forEach((coord) => bounds.extend(coord));
        map.fitBounds(bounds);
        if (
          mapRef.current?.getZoom() &&
          mapRef.current.getZoom() > minZoomLevel
        ) {
          mapRef.current?.setZoom(minZoomLevel);
        }
      } else {
        console.warn('유효한 주소가 없어 지도의 범위를 설정하지 않습니다.');
      }
    });
  }, [addresses]);

  return <Container ref={mapElement} />;
};

export default NaverMap;

const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;
