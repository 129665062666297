import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import { CustomProgressBar } from '../../../component/common/CustomProgressBar';
import { CustomCircularProgressbar } from '../../../component/common/CustomCircularProgressbar';
import styled from 'styled-components';
import { ProductionSquareCard } from '../../../component/common/ProductionSquareCard';
import useFetch from '../../../api/useFetch';
import { workbayListGetApi, WorkbayType } from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
export const ProductionOverview = () => {
  const productionData = useSelector(
    (state: RootState) => state.socketData.production
  );

  const [workbayList, setWorkbayList] = useState<WorkbayType[]>([]);

  const { fetch: fetchWorkbayListGetApi } = useFetch({
    fetchFunction: workbayListGetApi,
    onSuccess: (data: WorkbayType[]) => setWorkbayList(data),
  });
  //     <div style={{ width: '400px' }}>
  //     <ProductionSquareCard
  //       itemName={production.item.PROD_DES}
  //       itemSize={production.item.SIZE_DES}
  //       workbayName={production.workbayName}
  //       current={production.productionCnt}
  //       goal={production.productionGoal}
  //       progress={production.progress}
  //     />
  //   </div>

  useEffect(() => {
    fetchWorkbayListGetApi();
  }, []);

  return (
    <Container>
      {workbayList
        .filter((workbay) => workbay.workbayCode !== '100')
        .map((workbay) => {
          const workbayName = workbay.workbayName;
          const workbayId = workbay.id;
          return (
            <>
              <h2 style={{ marginTop: '0px' }}>{workbayName}</h2>
              {(() => {
                const filteredData = productionData.filter(
                  (production) =>
                    Number(production.workbayId) === Number(workbayId)
                );
                return filteredData.length > 0 ? (
                  <GroupContainer>
                    {filteredData.map((production) => (
                      <div style={{ width: '400px' }}>
                        <ProductionSquareCard
                          itemName={production.item.PROD_DES}
                          itemSize={production.item.SIZE_DES}
                          workbayName={production.workbayName}
                          current={production.productionCnt}
                          goal={production.productionGoal}
                          progress={production.progress}
                        />
                      </div>
                    ))}
                  </GroupContainer>
                ) : (
                  <h4>-생산일정이 없습니다</h4>
                );
              })()}
            </>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.div`
  font-size: ${({ theme }) => theme.fontsize[25]};
  color: ${({ theme }) => theme.colors.darkNavy};
  margin: 10px 0px;
`;

const GroupContainer = styled.div`
  display: flex;
  width: fit-content;
  /* background-color: ${({ theme }) => theme.colors.white}; */
  overflow-x: scroll;
  /* justify-content: space-evenly; */
  flex-direction: column;
  flex-wrap: wrap;
  height: 265px;
  gap: 28px;
  margin-bottom: 20px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    border-radius: 6px;
    background-color: #ededed;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background: #4b4b4b;
    border-radius: 6px;
    cursor: pointer;
  }
  * {
    box-sizing: border-box;
  }
`;

const NullData = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.fontsize[17]};
  font-weight: 600;
  margin: 22px 0px;
`;
