import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import {
  bomListGetApi,
  bomPostApi,
  BomRequestType,
  ItemBomResponseEssType,
  ItemBomResponseType,
  ItemBomType,
  itemListGetApi,
  ProductItem,
  userListGetApi,
  UserType,
} from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
import { BomEditModal } from '../../../component/common/modal/ModalComponents/BomEditModal';
import { UserEditModal } from '../../../component/common/modal/ModalComponents/UserEditModal';
import { roleMapper } from '../../../@const/role';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import SquareButton from '../../../component/button/SquareButton';
import { Prev } from 'react-bootstrap/esm/PageItem';

export const AccountManage = () => {
  const { setButtons } = useHeaderButtons();
  const [userList, setUserList] = useState<UserType[]>([]);

  const [modalItem, setModalItem] = useState<UserType | null>(null);

  const { fetch: fetchUserListGetApi } = useFetch({
    fetchFunction: userListGetApi,
    onSuccess: (data: UserType[]) => setUserList(data),
  });

  // function applyNewBom(data: ItemBomResponseEssType) {
  //   setBoms((prev) =>
  //     prev.some((bom) => bom.producedItem.itemId === data.producedItem.itemId)
  //       ? prev.map((bom) =>
  //           bom.producedItem.itemId === data.producedItem.itemId ? data : bom
  //         )
  //       : [...prev, data]
  //   );
  // }

  useEffect(() => {
    setButtons(
      <>
        <SquareButton
          colorType='ACTIVE'
          text='신규생성'
          onClick={() => {
            const dto: UserType = {
              id: null,
              userName: '',
              loginId: '',
              password: '',
              role: 'MANAGER',
            };
            setModalItem(dto);
          }}
        />
      </>
    );
  }, [setButtons]);

  useEffect(() => {
    fetchUserListGetApi();
  }, []);

  return (
    <Container>
      {modalItem && (
        <UserEditModal
          userProp={modalItem}
          onClose={() => setModalItem(null)}
          onSave={(data: UserType) => {
            setUserList((prev) =>
              prev.map((user) => (user.loginId === data.loginId ? data : user))
            );
            setModalItem(null);
          }}
          onDelete={(loginId: string) => {
            setUserList((prev) =>
              prev.filter((user) => user.loginId !== loginId)
            );
            setModalItem(null);
          }}
          onCreate={(data: UserType) => {
            setUserList([...userList, data]);
            setModalItem(null);
          }}
        />
      )}
      <h2 style={{ marginTop: '0px' }}>소모량 목록</h2>
      <RowContainer>
        <HeaderRow>
          <h4 className='name-cell flex-center'>이름</h4>
          <h4 className='role-cell flex-center'>권한</h4>
          <h4 className='login-id-cell flex-center'>로그인ID</h4>
          <h4 className='manage-cell flex-center'>관리</h4>
        </HeaderRow>
        {userList.length > 0 &&
          userList.map((user, index) => (
            <Row odd={index % 2 === 1}>
              <h5 className='name-cell flex-center'>{user.userName}</h5>
              <h5 className='role-cell flex-center'>{roleMapper[user.role]}</h5>
              <h5 className='login-id-cell flex-center'>{user.loginId}</h5>
              <h5
                className='manage-cell flex-center button-text'
                onClick={() => setModalItem(user)}
              >
                관리
              </h5>
            </Row>
          ))}
      </RowContainer>
    </Container>
  );
};

const Container = styled.div``;

const RowContainer = styled.div`
  /* background-color: white; */
`;

const Row = styled.div<{ odd?: boolean }>`
  display: flex;
  width: 100%;
  height: 32px;
  flex-direction: row;
  align-items: center;

  background-color: ${({ odd, theme }) =>
    odd ? theme.colors.white_odd : theme.colors.white};

  .name-cell {
    flex: 0 0 20%;
  }
  .role-cell {
    flex: 0 0 20%;
  }
  .login-id-cell {
    flex: 0 0 20%;
  }
  .manage-cell {
    flex: 0 0 15%;
  }
  .button-text {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const HeaderRow = styled(Row)`
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.charcoal};
  box-sizing: border-box;
  .has-bom {
    &:hover {
      text-decoration: none;
      cursor: auto;
    }
  }
`;
