import { useEffect, useState } from 'react';
import {
  ItemBomType,
  productionProgressUpdatePostApi,
  ProductionProgressUpdateReqType,
  ProductionResponseType,
  productionStockHistoryFindApi,
  ProductionStockHistoryRequestType,
  StockHistoryResponseType,
  workbayListGetApi,
  WorkbayType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import { CustomCircularProgressbar } from '../../../component/common/CustomCircularProgressbar';
import { ProductionSquareCard } from '../../../component/common/ProductionSquareCard';
import { ProductionCard } from '../../../component/common/ProductionCircularCard';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import SquareButton from '../../../component/button/SquareButton';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../../store/modules/sidebar';
import { setHeaderOpenstate } from '../../../store/modules/header';
import { useNavigate } from 'react-router-dom';
import { CustomProgressBar } from '../../../component/common/CustomProgressBar';
import {
  formatEightDigitStringToSixDigitDot,
  getYearMonthDateFromDate,
} from '../../../function/replaceString';

type StockHistoryResponseEssType = {
  itemId: number;
  prodDes: string;
  sizeDes: string;
  changedQuantities: number;
};

type StockHistoryEssType = {
  itemId: number;
  prodDes: string;
  sizeDes: string;
  expectedChangedQty: number;
  changedQty: number;
};

export const ProductionInbound = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { fetch: fetchProductionProgressUpdate } = useFetch({
    fetchFunction: productionProgressUpdatePostApi,
  });

  const { fetch: fetchProductionStockHistoryFind } = useFetch({
    fetchFunction: productionStockHistoryFindApi,
    onSuccess: (data: StockHistoryResponseEssType[]) =>
      initStockHistoryDatas(data),
  });

  function initStockHistoryDatas(data: StockHistoryResponseEssType[]) {
    // Step 1: 변환된 데이터 초기화
    const transformedData = data.map((response) => ({
      itemId: response.itemId,
      prodDes: response.prodDes,
      sizeDes: response.sizeDes,
      expectedChangedQty: response.changedQuantities * -1,
      changedQty: response.changedQuantities * -1,
    }));

    // Step 2: itemId 기준으로 그룹화 및 합산
    const stockHistoryMap = new Map<number, StockHistoryEssType>();

    transformedData.forEach((stockHistory) => {
      if (stockHistoryMap.has(stockHistory.itemId)) {
        // 이미 존재하는 itemId는 값을 합산
        const existingHistory = stockHistoryMap.get(stockHistory.itemId)!;
        existingHistory.expectedChangedQty += stockHistory.expectedChangedQty;
        existingHistory.changedQty += stockHistory.changedQty;
      } else {
        // 새로운 itemId는 맵에 추가
        stockHistoryMap.set(stockHistory.itemId, { ...stockHistory });
      }
    });

    // Step 3: 상태 업데이트
    setStockHistories(Array.from(stockHistoryMap.values()));
  }

  const [stockHistories, setStockHistories] = useState<StockHistoryEssType[]>(
    []
  );

  const sidebarOpenStatus = useSelector(
    (state: RootState) => state.sidebar.uiStatus.open
  );

  const productionData = useSelector(
    (state: RootState) => state.socketData.production
  );

  // const stockHistories = useSelector(
  //   (state: RootState) => state.socketData.stockHistory
  // );

  const [filteredProductions, setFilteredProductions] = useState<
    ProductionResponseType[]
  >([]);

  const test = useSelector((state: RootState) => state.socketData.stockHistory);

  const headerOpenStatus = useSelector((state: RootState) => state.header.open);
  const { setButtons } = useHeaderButtons();
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const [workbayList, setWorkbayList] = useState<WorkbayType[]>([]);
  const [selectedWorkbayId, setSelectedWorkbayId] = useState<number>(-1);
  const { fetch: fetchWorkbayListGet } = useFetch({
    fetchFunction: workbayListGetApi,
    onSuccess: (data: WorkbayType[]) => setWorkbayList(data),
  });

  //생산시작버튼 활성화 트리거
  const [clickable, setClickable] = useState<boolean>(false);

  const [stockHistoryGroup, setStockHistoryGroup] = useState<
    StockHistoryResponseType[]
  >([]);

  useEffect(() => {
    fetchWorkbayListGet();
  }, []);

  useEffect(() => {
    if (selectedWorkbayId === -1) return;
    setFilteredProductions(
      productionData.filter(
        (production) =>
          Number(selectedWorkbayId) === Number(production.workbayId)
      )
    );
  }, [selectedWorkbayId]);

  function progressUpdate() {
    const targetItem = productionData.find(
      (production) => Number(production.workbayId) === Number(selectedWorkbayId)
    );
    if (!targetItem) return;
    const dto: ProductionProgressUpdateReqType = {
      progress: 'C',
      targetDate: targetItem.productionDt,
      workbayId: selectedWorkbayId,
    };
    fetchProductionProgressUpdate(dto);
  }

  useEffect(() => {
    if (filteredProductions.length < 1) return;
    if (selectedWorkbayId === -1) return;
    const requestDto: ProductionStockHistoryRequestType = {
      workbayId: selectedWorkbayId,
      timeDate: filteredProductions[0].productionDt,
    };
    fetchProductionStockHistoryFind(requestDto);
  }, [filteredProductions]);

  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        <SquareButton
          text='생산마감'
          colorType={clickable ? 'ACTIVE' : 'DISACTIVE'}
          onClick={() => {
            clickable && progressUpdate();
          }}
        />
        <SquareButton
          text='엑셀출력'
          colorType={'DISACTIVE'}
          onClick={() => undefined}
        />
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons, clickable]);

  useEffect(() => {
    if (selectedWorkbayId === -1) return;
    setClickable(true);
  }, [selectedWorkbayId]);

  function qtyChangeHandler(targetItemId: number, value: number) {
    setStockHistories((prev) =>
      prev.map((stockHistory) =>
        stockHistory.itemId === targetItemId
          ? {
              ...stockHistory,
              changedQty: value,
            }
          : stockHistory
      )
    );
  }

  // useEffect(() => {
  // const stockHistoryMap = new Map<number, StockHistoryResponseType>();

  // filteredProductions.forEach((production) => {
  //   const matchingHistories = stockHistories.filter(
  //     (stockHistory) => stockHistory.itemId === production.item.id
  //   );

  //   matchingHistories.forEach((stockHistory) => {
  //     if (stockHistoryMap.has(stockHistory.itemId)) {
  //       // If itemId already exists, sum the consumedQty
  //       const existingStockHistory = stockHistoryMap.get(
  //         stockHistory.itemId
  //       )!;
  //       existingStockHistory.changedQuantity += stockHistory.changedQuantity;
  //     } else {
  //       // Add new entry to the map
  //       stockHistoryMap.set(stockHistory.itemId, { ...stockHistory });
  //     }
  //   });
  // });

  // // Convert the map back to an array and set it in state
  // // setStockHistoryGroup(Array.from(stockHistoryMap.values()));
  // console.log(Array.from(stockHistoryMap.values()));
  // }, [filteredProductions]);

  return (
    <Container>
      <WorkbaySection>
        {workbayList.length > 0 &&
          workbayList
            //100은 엠마우스 본사코드로 사용안함
            .filter((workbay) => workbay.workbayCode !== '100')
            .map((workbay) => (
              <WorkbayItem
                selected={selectedWorkbayId === workbay.id}
                onClick={() =>
                  setSelectedWorkbayId((prev) =>
                    prev === workbay.id ? prev : workbay.id
                  )
                }
              >
                {workbay.workbayName}
              </WorkbayItem>
            ))}
      </WorkbaySection>
      {selectedWorkbayId === -1 ? (
        <div>작업장을 선택해주세요</div>
      ) : (
        <>
          <TableHeader>
            {formatEightDigitStringToSixDigitDot(
              productionData[0].productionDt
            )}{' '}
            - 생산품
          </TableHeader>
          <Table>
            <th>
              <td className='prod-cell'>품목</td>
              <td className='achieve-cell'>달성수량</td>
              <td className='goal-cell'>목표수량</td>
              <td className='state-cell'>달성여부</td>
              <td className='achieve-ratio-cell'>달성률</td>
            </th>
            {filteredProductions.length > 0 &&
              filteredProductions.map((production) => {
                const percentage =
                  (production.productionCnt / production.productionGoal) * 100;
                return (
                  <tr>
                    <td className='prod-cell'>
                      {production.item.PROD_DES} / {production.item.SIZE_DES}
                    </td>
                    <td className='achieve-cell'>{production.productionCnt}</td>
                    <td className='goal-cell'>{production.productionGoal}</td>
                    <td className='state-cell'>
                      {production.progress === 'E'
                        ? '조기마감'
                        : percentage >= 100
                        ? '달성'
                        : '미달'}
                    </td>
                    <td className='achieve-ratio-cell'>
                      <CustomProgressBar
                        current={production.productionCnt}
                        goal={production.productionGoal}
                        style={{
                          width: '100%',
                          height: '8px',
                          borderRadius: '5px',
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </Table>
        </>
      )}
      <hr />
      <TableHeader>
        {formatEightDigitStringToSixDigitDot(
          getYearMonthDateFromDate(new Date()).dateString
        )}{' '}
        - 소모품
      </TableHeader>
      <Table>
        <th>
          <td className='prod-cell'>품목명</td>
          <td className='achieve-cell'>등록된 소모수량</td>
          <td className='goal-cell'>소모수량 직접 적용</td>
        </th>
        {stockHistories.length > 0 &&
          stockHistories
            //생산품은 필터링
            .filter(
              (stockHistory) =>
                !productionData.find(
                  (production) => production.item.id === stockHistory.itemId
                )
            )
            .map((stockHistory) => {
              return (
                <tr>
                  <td className='prod-cell'>
                    {stockHistory.prodDes} / {stockHistory.sizeDes}
                  </td>
                  <td className='achieve-cell'>
                    {stockHistory.expectedChangedQty}
                  </td>
                  {/* <td className='goal-cell'>{stockHistory.changedQty}</td> */}
                  <input
                    value={stockHistory.changedQty}
                    className='goal-cell'
                    onChange={(e) => {
                      let value = e.target.value.trim();
                      // 숫자만 남도록 필터링
                      value = value.replace(/[^0-9]/g, ''); // 숫자 외 문자 제거

                      // 앞의 '0' 제거
                      value = value.replace(/^0+/, '');

                      // 빈 값이면 0으로 설정

                      qtyChangeHandler(stockHistory.itemId, Number(value));
                    }}
                    type='text'
                    style={{ textAlign: 'center' }}
                  />
                </tr>
              );
            })}
      </Table>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  * {
    box-sizing: border-box;
    font-weight: 600;
  }
`;

const WorkbaySection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: auto;
  margin-bottom: 15px;
  height: 33px;
`;

const WorkbayItem = styled.div<{ selected: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3px 7px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 3px solid;
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.grapeFruit : 'transparent'};
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontsize.header_semi};
  cursor: pointer;
`;

const TableHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: ${({ theme }) => theme.fontsize[21]};
  padding: 7px 25px;
  font-weight: 700;
`;

const Table = styled.table`
  width: 100%;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.white};
  .prod-cell {
    flex-basis: 30%;
  }
  .achieve-cell {
    flex-basis: 15%;
  }
  .goal-cell {
    flex-basis: 15%;
  }
  .state-cell {
    flex-basis: 15%;
  }
  .achieve-ratio-cell {
    flex-basis: 20%;
  }

  .consumed-prod-cell {
    flex: 1 0 30%;
  }
  .consumed-size-cell {
    flex: 1 0 15%;
  }
  .consumed-qty-cell {
    flex: 1 0 15%;
  }

  td {
    align-content: center;
    text-align: center;
  }
  th {
    display: flex;
    flex-direction: row;
  }
  tr {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    td {
      overflow: hidden; /* 넘치는 부분 숨김 */
      text-overflow: ellipsis; /* 가려지는 부분 ... 처리 */
      white-space: nowrap; /* 줄 넘김 금지 */
    }
  }
`;
