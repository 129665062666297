const SET_LOGIN_USER_DATA = 'loginUser/SET_LOGIN_USER_DATA' as const;
const CLEAR_LOGIN_USER_DATA = 'loginUser/CLEAR_LOGIN_USER_DATA' as const;
const SET_SPECIFIC_USER_DATA = 'loginUser/SET_SPECIFIC_USER_DATA' as const;

export interface UserData {
  loginId: string;
  role: string;
  userName: string;
}

export const setLoginUserData = (payload: UserData) => ({
  type: SET_LOGIN_USER_DATA,
  payload: payload,
});

export const clearLoginUserData = () => ({
  type: CLEAR_LOGIN_USER_DATA,
});

export const setSpecificUserData = (payload: Partial<UserData>) => ({
  type: SET_SPECIFIC_USER_DATA,
  payload: payload,
});

type UserDataAction =
  | { type: typeof SET_LOGIN_USER_DATA; payload: UserData }
  | { type: typeof CLEAR_LOGIN_USER_DATA }
  | { type: typeof SET_SPECIFIC_USER_DATA; payload: Partial<UserData> };

const initialState: UserData | null = null;

const loginUserDataReducer = (
  state: UserData | null = initialState,
  action: UserDataAction
): UserData | null => {
  switch (action.type) {
    case SET_LOGIN_USER_DATA:
      return action.payload;

    case CLEAR_LOGIN_USER_DATA:
      return initialState;

    case SET_SPECIFIC_USER_DATA:
      return state ? { ...state, ...action.payload } : state;

    default:
      return state;
  }
};

export default loginUserDataReducer;
