import toast from 'react-hot-toast';
import { CustomAxiosError } from './axiosConfig';

//단순 성공/실패 메시지만 있는것이 아니라 여러 case가 있을 경우, errorCode를 기반으로 메시지 정의
const specificErrors = [
  {
    code: 'REFRESH_TOKEN_EXPIRED',
    message: '인증이 만료되었습니다.',
  },
];

//성공시의 toast message handler
const apiSuccessHandler = (text?: string, hideDefaultOnSuccess?: boolean) => {
  if (text) {
    // toast(text);
    // Toast.showSuccess(text);
    toast.success(text);
  } else if (!hideDefaultOnSuccess) {
    // Toast.showSuccess('성공적으로 처리되었습니다');
    toast.success('처리되었습니다');
  } else return;
};

//실패시의 toast message handler
const apiErrorHandler = (
  error: CustomAxiosError,
  text?: string,
  hideDefaultOnError?: boolean
) => {
  const method = error.config?.meta?.method?.toLowerCase();
  const defaultErrorMessage =
    method === 'get'
      ? '서버로부터 데이터를 불러오지 못했습니다'
      : method === 'post'
      ? '데이터 전송중 문제가 발생하였습니다'
      : method === 'delete'
      ? '삭제중 문제가 발생하였습니다'
      : '처리중 문제가 발생하였습니다';

  const specificError = specificErrors.find(
    (specificError) => specificError.code === error.response.data.errorCode
  );

  if (specificError) {
    //나중에 error로 바꾸기
    // Toast.showSuccess(specificError.message);
    toast.error(specificError.message);
  } else {
    if (text) {
      // Toast.showSuccess(text);
      toast.error(text);
    } else if (!hideDefaultOnError) {
      // Toast.showSuccess(defaultErrorMessage);
      toast.error(defaultErrorMessage);
    }
  }
};

//성공, 에러 메시지 및 기타 설정들을 엮어주는 중간함수
export const createApiFunction = <T>({
  apiCall,
  successMessage,
  errorMessage,
  hideDefaultOnSuccess,
  hideDefaultOnError,
}: {
  apiCall: (data: any) => Promise<T>;
  successMessage?: string;
  errorMessage?: string;
  hideDefaultOnSuccess?: boolean;
  hideDefaultOnError?: boolean;
}) => {
  return async (data: any) => {
    try {
      const response = await apiCall(data);
      apiSuccessHandler(successMessage, hideDefaultOnSuccess);
      return response;
    } catch (error) {
      // AxiosError 타입으로 변환
      const axiosError = error as CustomAxiosError;
      console.log('errorhandler:::', axiosError);
      apiErrorHandler(axiosError, errorMessage, hideDefaultOnError);
      throw axiosError;
    }
  };
};
