import { useState } from 'react';
import * as Styled from './LoginPageStyled';
import axios from 'axios';
import {
  storeAccessTokenAsync,
  getAccessTokenAsync,
  storeRefreshTokenAsync,
} from '../../../service/tokenService';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginUserData } from '../../../store/modules/loginUserData';
import { RootState } from '../../../store/modules';
import {
  LoginApi,
  LoginByCodeApi,
  LoginRequestType,
  LoginResponseType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import logo from '../../../assets/image/logo.png';
import { CustomAxiosError } from '../../../api/axiosConfig';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [code, setCode] = useState<string>();

  const onSuccessLogin = (data: LoginResponseType) => {
    console.log(data);
    storeAccessTokenAsync(data.accessToken)
      .then(() => console.log('AT Stored Successfully'))
      .catch((e) => console.log('AT Store Failed by ', e));
    storeRefreshTokenAsync(data.refreshToken)
      .then(() => console.log('AT Stored Successfully'))
      .catch((e) => console.log('AT Store Failed by ', e));
    dispatch(setLoginUserData(data.userInfo));
  };
  const { fetch: doLogin } = useFetch<LoginResponseType>({
    fetchFunction: LoginApi,
    onSuccess: (data: LoginResponseType) => onSuccessLogin(data),
    onError: (error?: CustomAxiosError) => {
      if (error?.response.data.errorCode === 'INVALID_CREDENTIALS') {
        window.alert('아이디 혹은 비밀번호가 일치하지 않습니다');
      }
    },
  });

  const { fetch: fetchloginByCodeApiConfig } = useFetch({
    fetchFunction: LoginByCodeApi,
    onSuccess: () => {
      console.log('login success');
    },
  });

  function loginFn() {
    const loginDto: LoginRequestType = {
      loginId: loginId,
      password: password,
    };
    doLogin(loginDto);
  }

  const savedLoginUserdata = useSelector(
    (state: RootState) => state.loginUserData
  );
  //   async function login() {
  //     const loginDto = {
  //       loginId: loginId,
  //       password: password,
  //     };
  //     try {
  //       const response = await axios.post<LoginResponseType>(
  //         'http://localhost:8080/auth/login',
  //         loginDto
  //       );
  //       const data = response.data;
  //       storeAccessTokenAsync(data.accessToken)
  //         .then(() => console.log('AT Stored Successfully'))
  //         .catch((e) => console.log('AT Store Failed by ', e));
  //       storeRefreshTokenAsync(data.refreshToken)
  //         .then(() => console.log('AT Stored Successfully'))
  //         .catch((e) => console.log('AT Store Failed by ', e));
  //       dispatch(setLoginUserData(data.userInfo));
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }

  return (
    <Styled.WholeContainer>
      <Styled.Image src={logo} />
      <Styled.Conatiner>
        <Styled.HalfContainer>
          <Styled.Title>웹 로그인</Styled.Title>
          <Styled.InputArea>
            <Styled.InputRow>
              <Styled.Input
                placeholder='아이디를 입력해주세요'
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
              />
            </Styled.InputRow>
            <Styled.InputRow>
              <Styled.Input
                placeholder='비밀번호를 입력해주세요'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type='password'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    loginFn();
                  }
                }}
              />
            </Styled.InputRow>
            <Styled.LoginButton onClick={() => loginFn()}>
              로그인
            </Styled.LoginButton>
          </Styled.InputArea>
        </Styled.HalfContainer>
      </Styled.Conatiner>
    </Styled.WholeContainer>
  );
};

export default LoginPage;
