import {
  HTMLProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { orderData, OrderHeaderType } from '../TableTestdata';
import {
  ColumnDef,
  Row,
  RowData,
  TableMeta,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import * as Styled from '../TableStyled';
import TableConfig from '../TableConfig';
import { useHeaderButtons } from '../../header/HeaderButtonProvider';
import SquareButton from '../../button/SquareButton';
import { AiFillCaretRight } from 'react-icons/ai';
import { AiFillCaretDown } from 'react-icons/ai';
import ListTooltip from '../../common/ListTooltip';
import { ValueWithKey } from '../TableTestdata';
import { traderData } from '../TableTestdata';
import TraderUploadModal from '../../common/modal/ModalComponents/TraderUploadModal';
import axios from 'axios';
import { useTheme } from 'styled-components';
import { toast } from 'react-hot-toast';
// import { Toast } from '../../toast/Toast';
import {
  traderListGetApi,
  traderListPostApi,
  TraderType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { Pagination, PaginationState } from '../Pagination';

const TraderListTable = () => {
  const theme = useTheme();
  const { setButtons } = useHeaderButtons();

  const { fetch: apiGetTraderList } = useFetch({
    fetchFunction: traderListGetApi,
    onSuccess: (data: TraderType[]) => setData(data),
  });

  const { fetch: apiPostTraderList } = useFetch({
    fetchFunction: traderListPostApi,
    onSuccess: (data: TraderType[]) => {
      setUpdatedData(data);
      toast('업데이트에 성공하였습니다.');
    },
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const [modalType, setModalType] = useState<'edit' | 'add' | null>(null);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [data, setData] = useState<TraderType[]>([]);
  const [excelData, setExcelData] = useState<TraderType[]>([]);
  const [updatedData, setUpdatedData] = useState<TraderType[]>([]);

  //테이블 옵션값 ~~~
  const [headerOverflowType, setHeaderOverflowType] = useState<'wrap' | 'hide'>(
    'wrap'
  );
  const [bodyOverflowType, setBodyOverflowType] = useState<'wrap' | 'hide'>(
    'wrap'
  );
  const [bodyAlignHoriType, setBodyAlignHoriType] = useState<
    'left' | 'center' | 'right'
  >('center');

  const [cellHoriPaddValue, setCellHoriPaddValue] = useState<number>(5);
  const [bodyVertPaddValue, setBodyVertPaddValue] = useState<number>(5);

  const [showConfig, setShowConfig] = useState<boolean>(false);

  useEffect(() => {
    if (excelData.length > 0) {
      apiPostTraderList(excelData);
    }
  }, [excelData]);

  useEffect(() => {
    if (updatedData.length > 0) {
      setData((prev) => {
        //변경된(response응답받은) 데이터들의 거래처코드 저장
        const updatedCodes = new Set(updatedData.map((data) => data.CUST_CODE));

        //기존 항목에서 필터링. 변경사항들의 인덱스를 나란히 유지하기위해 일단 지워주는 작업
        const filteredPrev = prev.filter(
          (item) => !updatedCodes.has(item.CUST_CODE)
        );

        //updatedData들에 updateFlag를 설정하여 새 배열 생성
        const updatedWithFlag = updatedData.map((item) => ({
          ...item,
          updateFlag: true, // updateFlag를 true로 설정
        }));

        //state는 비워줌
        setUpdatedData([]);
        //변경된 데이터들을 위로하여 재배치
        return [...updatedWithFlag, ...filteredPrev];
      });
    }
  }, [updatedData]);

  useEffect(() => {
    apiGetTraderList();
  }, []);

  const columns = useMemo<ColumnDef<TraderType>[]>(
    () => [
      {
        header: 'No',
        id: 'NO',
        size: 46,
        cell: ({ cell, row, getValue }) => (
          <Styled.NoBodyCell>{row.index + 1}</Styled.NoBodyCell>
        ),
      },
      {
        header: '거래처코드',
        accessorKey: 'CUST_CODE',
        size: 190,
      },
      {
        header: '거래처명',
        accessorKey: 'CUST_NAME',
        size: 300,
      },
      {
        header: '대표자명',
        accessorKey: 'BOSS_NAME',
        size: 92,
      },
      {
        header: '전화번호',
        accessorKey: 'TEL',
        size: 115,
      },
      {
        header: '팩스번호',
        accessorKey: 'FAX',
        size: 65,
      },
      {
        header: '모바일번호',
        accessorKey: 'HP_NO',
        size: 65,
      },
      {
        header: '우편번호',
        accessorKey: 'PONT_NO',
        size: 65,
      },
      {
        header: '주소',
        accessorKey: 'ADDR',
        size: 420,
      },
    ],
    []
  );

  //아마 페이지네이션
  function useSkipper() {
    const shouldSkipRef = useRef(true);
    const shouldSkip = shouldSkipRef.current;

    // Wrap a function with this to skip a pagination reset temporarily
    const skip = useCallback(() => {
      shouldSkipRef.current = false;
    }, []);

    useEffect(() => {
      shouldSkipRef.current = true;
    });

    return [shouldSkip, skip] as const;
  }

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  //~~페이지네이션

  useEffect(() => {
    //헤더에 버튼 추가
    setButtons(
      <>
        <SquareButton
          text='거래처 등록'
          colorType='ACTIVE'
          onClick={() => {
            setModalType('add');
            setShowModal(true);
          }}
        />
        {/* <SquareButton
          text='테스트'
          colorType='ACTIVE'
          onClick={() => {
            Toast.showSuccess('테스트');
          }}
        /> */}
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons]);

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: 'onChange',
    state: {
      expanded: true,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    autoResetPageIndex,
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    onPaginationChange: setPagination,
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  return (
    <>
      <Pagination
        table={table}
        pagination={pagination}
        setPageSize={(pageSize: number) =>
          setPagination((prev) => ({
            pageIndex: prev.pageIndex,
            pageSize: pageSize,
          }))
        }
      />
      {showModal && modalType && (
        <TraderUploadModal
          onClose={() => {
            setShowModal(false);
          }}
          onSelect={(item: TraderType[]) => setExcelData(item)}
          mode='add'
        />
      )}
      <Styled.TableContainer>
        {/* <>
          {JSON.stringify(
            {
              columnSizing: table.getState().columnSizing,
            },
            null,
            2
          )}
        </> */}
        <Styled.TableOptionButton onClick={() => setShowConfig(!showConfig)}>
          설정
        </Styled.TableOptionButton>
        <TableConfig
          show={showConfig}
          Header_Overflow_Type={headerOverflowType}
          setHeaderOverflowType={(value: 'hide' | 'wrap') =>
            setHeaderOverflowType(value)
          }
          Body_Overflow_Type={bodyOverflowType}
          setBodyOverflowType={(value: 'hide' | 'wrap') =>
            setBodyOverflowType(value)
          }
          Body_Align_Hori_Type={bodyAlignHoriType}
          setBodyAlignHoriType={(value: 'left' | 'center' | 'right') =>
            setBodyAlignHoriType(value)
          }
          Cell_Hori_Padd_Value={cellHoriPaddValue}
          setCellHoriPaddValue={(value: number) => setCellHoriPaddValue(value)}
          Body_Vert_Padd_Value={bodyVertPaddValue}
          setBodyVertPaddValue={(value: number) => setBodyVertPaddValue(value)}
        />
        <Styled.TableDiv
          {...{
            style: {
              ...columnSizeVars,
              width: table.getTotalSize(),
            },
          }}
          headOverflowType={headerOverflowType}
          bodyOverflowType={bodyOverflowType}
          bodyAlignHoriType={bodyAlignHoriType}
          cellHoriPaddValue={cellHoriPaddValue}
          bodyVertPaddValue={bodyVertPaddValue}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Styled.ThDiv {...{ key: headerGroup.id }}>
                {headerGroup.headers.map((headerCell) =>
                  headerCell.isPlaceholder ? null : (
                    <Styled.ThCell
                      {...{
                        key: headerCell.id,
                        style: {
                          width: `calc(var(--header-${headerCell?.id}-size) * 1px)`,
                        },
                        className: 'th',
                      }}
                    >
                      {headerCell.isPlaceholder
                        ? null
                        : flexRender(
                            headerCell.column.columnDef.header,
                            headerCell.getContext()
                          )}
                      <Styled.ResizeHandler
                        {...{
                          onMouseDown: headerCell.getResizeHandler(),
                          className: `resizer ${
                            headerCell.column.getIsResizing()
                              ? 'isResizing'
                              : ''
                          }`,
                        }}
                      />
                    </Styled.ThCell>
                  )
                )}
              </Styled.ThDiv>
            );
          })}
          {table.getRowModel().rows.map((row) => (
            <>
              <Styled.TdDiv
                {...{
                  key: row.id,
                  style: {
                    backgroundColor: row.original.updateFlag
                      ? theme.colors.lime
                      : 'transparent',
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Styled.TdCell
                      {...{
                        key: cell.id,
                        className: 'td',
                        style: {
                          width: `calc(var(--header-${cell.column.id}-size) * 1px)`,
                        },
                      }}
                    >
                      {flexRender(
                        // cell.column.columnDef.aggregatedCell ??
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Styled.TdCell>
                  );
                })}
              </Styled.TdDiv>
            </>
          ))}
        </Styled.TableDiv>
      </Styled.TableContainer>
    </>
  );
};

export default TraderListTable;
