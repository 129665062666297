//일괄설정
const SET_SIDEBAR_UI_STATUS = 'sidebar/SET_SIDEBAR_UI_STATUS' as const;
//열려있는 카테고리 설정
const SET_SIDEBAR_OPENED_CATEGORIES =
  'sidebar/SET_SIDEBAR_OPENED_CATEGORIES' as const;
//사이드바 여닫기
const TOGGLE_SIDEBAR = 'sidebar/TOGGLE_SIDEBAR' as const;

export interface SidebarUiStatusType {
  openKey: number[];
  highlightedMajorIndex: number[];
  selectedMinorIndex: number | null;
  open: boolean;
}

export type SidebarAction =
  | {
      type: typeof SET_SIDEBAR_UI_STATUS;
      payload: SidebarUiStatusType;
    }
  | { type: typeof SET_SIDEBAR_OPENED_CATEGORIES; payload: number[] }
  | { type: typeof TOGGLE_SIDEBAR };

export interface SidebarState {
  uiStatus: SidebarUiStatusType;
}

export const setSidebarUiStatus = (payload: SidebarUiStatusType) => ({
  type: SET_SIDEBAR_UI_STATUS,
  payload: payload,
});

export const setSidebarOpenedCategories = (payload: number[]) => ({
  type: SET_SIDEBAR_OPENED_CATEGORIES,
  payload: payload,
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

const initialState: SidebarState = {
  uiStatus: {
    openKey: [],
    highlightedMajorIndex: [],
    selectedMinorIndex: null,
    open: true,
  },
};

const sidebarReducer = (
  state: SidebarState = initialState,
  action: SidebarAction
) => {
  switch (action.type) {
    case SET_SIDEBAR_UI_STATUS:
      return {
        //다른 상태(현재는 ui만있음) 유지
        ...state,
        uiStatus: {
          //입력 없는 항목은 기존값
          ...state.uiStatus,
          //업데이트
          ...action.payload,
        },
      };
    case SET_SIDEBAR_OPENED_CATEGORIES:
      return {
        ...state,
        uiStatus: {
          ...state.uiStatus,
          openKey: [...action.payload],
        },
      };

    case TOGGLE_SIDEBAR:
      return {
        ...state,
        uiStatus: {
          ...state.uiStatus,
          open: !state.uiStatus.open,
        },
      };
    default:
      return state;
  }
};

export default sidebarReducer;
