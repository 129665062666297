import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import {
  storeAccessTokenAsync,
  storeRefreshTokenAsync,
  getAccessTokenAsync,
  getRefreshTokenAsync,
  removeAccessToken,
  removeRefreshToken,
} from '../service/tokenService';
import { useEffect } from 'react';
import { clearLoginUserData } from '../store/modules/loginUserData';
import store from '../store/configureStore';
import { LoginResponseType } from './apiConfig';
let accessToken = null;
let refreshToken = null;

//dev
// export const API_BASE_URL = 'http://localhost:8080';
export const API_BASE_URL = 'https://emmausi.duckdns.org';

const isWindows = navigator?.platform?.toLowerCase().includes('win');

// export const API_BASE_URL = 'https://emmausi.duckdns.org';
// process.env.NODE_ENV === 'development' || isWindows
//   ? 'http://localhost:8080'
//   : 'https://emmausi.duckdns.org';

// const tokenExpiredMessage = 'JWT token is expired.';

interface AxiosResponseType<T> {
  data: T;
}

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  meta?: {
    method?: string;
    url?: string;
  };
}

export interface CustomAxiosError extends AxiosError {
  response: AxiosError['response'] & {
    data: {
      errorCode: string;
      message: string;
    };
  };
  config: CustomAxiosRequestConfig;
}

// Axios 인스턴스 생성
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 100000,
});

const postRefreshToken = async () => {
  try {
    refreshToken = await getRefreshTokenAsync();
    const response: AxiosResponseType<LoginResponseType> = await apiClient.post(
      `/auth/refresh-token`,
      {
        refreshToken,
      }
    );
    // console.warn(response.data.accessToken);
    if (response.data.accessToken) {
      console.log('new access token', response.data.accessToken);
      try {
        removeAccessToken();
        storeAccessTokenAsync(response.data.accessToken);
      } catch (e) {
        // console.log(e.response.data.errorCode);
      }
    }

    // 리프레시토큰은 갱신되지 않음. 로그아웃처리 -> 로그인으로 재발급으로 로직 변경
    // if (response.data.refreshToken) {
    //   removeRefreshToken();
    //   storeRefreshTokenAsync(response.data.refreshToken);
    // }
    return response.data.accessToken; // 새로 발급된 액세스 토큰 반환
  } catch (error) {
    throw error; // 에러를 상위로 던짐
  }
};

// 이전 요청을 저장할 변수
let isRefreshing = false;
let failedQueue: any[] = [];

// 토큰 갱신 시도 후 이전 요청 재시도 함수
const retryFailedRequests = (error: CustomAxiosError) => {
  const { config } = error;
  // 토큰 갱신 중인 경우, 이전 요청을 저장 후 재시도
  if (!isRefreshing) {
    isRefreshing = true;
    return postRefreshToken()
      .then((newToken) => {
        //RT를 이용해 AT를 재발급받는것에 성공했다면 이전요청의 AT를 바꿔서 다시 요청
        config.headers.Authorization = `Bearer ${newToken}`;
        return axios(config);
      })
      .catch((err) => {
        //재발급 실패시
        failedQueue.forEach(({ reject }) => reject(err));
        failedQueue = [];
        if (err.response.data.errorCode === 'INVALID_REFRESH_TOKEN') {
          //리프레시 토큰 만료 케이스라면
          //세션스토리지의 토큰, 리덕스의 로그인유저데이터 날림
          removeAccessToken();
          removeRefreshToken();
          store.dispatch(clearLoginUserData());
        }

        throw err;
      })
      .finally(() => {
        isRefreshing = false;
      });
  } else {
    // 토큰 갱신 중이면 요청을 큐에 저장
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    }).then((newToken) => {
      config.headers.Authorization = `Bearer ${newToken}`;
      return axios(config);
    });
  }
};

// 요청 인터셉터 설정
apiClient.interceptors.request.use(
  async (config) => {
    console.log('요청 인터셉터 진입');
    accessToken = await getAccessTokenAsync();
    if (accessToken) {
      console.log('토큰 존재', accessToken);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 설정 (옵션)
apiClient.interceptors.response.use(
  (response) => response,
  (error: CustomAxiosError) => {
    error.config.meta = { method: error.config.method, url: error.config.url };
    // 토큰이 만료되었거나 유효하지 않으면 처리
    if (error.response.status === 401) {
      if (error.response.data.errorCode === 'ACCESSTOKEN_EXPIRED') {
        return retryFailedRequests(error);
      }
    }
    return Promise.reject(error);
  }
);

export { apiClient };
