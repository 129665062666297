import { useEffect } from 'react';
import {
  govshopOrderPostToEcountApi,
  itemUpdateApi,
  stockLatestUpdatedTimeGetApi,
  stockUpdateApi,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import styled from 'styled-components';

export const DataManage = () => {
  const { fetch: fetchStockLatestUpdatedTimeGetApi } = useFetch({
    fetchFunction: stockLatestUpdatedTimeGetApi,
    onSuccess: (data: Date) => console.log('date', data),
  });

  const { fetch: fetchStockUpdateApi } = useFetch({
    fetchFunction: stockUpdateApi,
    onSuccess: () => console.log('success to update stock'),
  });

  const { fetch: fetchItemUpdateApi } = useFetch({
    fetchFunction: itemUpdateApi,
    onSuccess: () => console.log('success to update item'),
  });

  const { fetch: fetchGovshopOrderPostToEcountApi } = useFetch({
    fetchFunction: govshopOrderPostToEcountApi,
    onSuccess: () => console.log('success to update govshop order'),
  });

  useEffect(() => {
    fetchStockLatestUpdatedTimeGetApi();
  }, []);

  return (
    <Container>
      <Button onClick={() => fetchItemUpdateApi()}>품목 업데이트</Button>
      <Button onClick={() => fetchStockUpdateApi()}>재고 업데이트</Button>
      <Button onClick={() => fetchGovshopOrderPostToEcountApi()}>
        나라장터 주문수집
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Button = styled.div`
  width: 220px;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  align-content: center;
  text-align: center;
  padding: 5px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue_hover};
  }
`;
