import styled, { css, useTheme } from 'styled-components';
import {
  BomRequestType,
  ItemBomEssType,
  ItemBomResponseEssType,
  ItemBomResponseType,
  ItemBomType,
  OrderListType,
  ProductItem,
} from '../../../../api/apiConfig';
import ModalBase from '../ModalBase';
import {
  BiPlusCircle,
  BiSolidLeftArrow,
  BiSolidRightArrow,
  BiX,
} from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { ProdType } from '../../../table/Table';
import { FiTrash2 } from 'react-icons/fi';
export const BomEditModal = ({
  item,
  itemList,
  onClose,
  onSave,
}: {
  item: ItemBomResponseEssType | null;
  itemList: ProductItem[];
  onClose: () => void;
  onSave: (item: BomRequestType) => void;
}) => {
  const theme = useTheme();

  const [producedItem, setProducedItem] = useState<ItemBomEssType | null>(null);

  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const [newBomTargetItem, setNewBomTargetItem] =
    useState<ItemBomEssType | null>(null);

  const [boms, setBoms] = useState<ItemBomEssType[]>([]);

  useEffect(() => {
    if (item && item.producedItem) setProducedItem(item.producedItem);
    if (item && item.consumedItems) {
      setBoms(item.consumedItems);
    }
  }, [item]);

  function addBom() {
    // const newBom: ItemBomEssType;
    // setBoms((prev) => [...prev]);
  }

  function saveHandler() {
    if (!producedItem) return;
    if (boms.length < 1) return;
    if (boms.find((bom) => bom.consumedQty && bom.consumedQty < 1)) return;
    const requestDto: BomRequestType = {
      producedItemId: producedItem.itemId,
      consumedItems: boms.map((bom) => ({
        consumedItemId: bom.itemId,
        consumedQuantity: bom.consumedQty!,
      })),
    };
    onSave(requestDto);
    onClose();
  }

  function deleteHandler(itemId: number) {
    setBoms((prev) => prev.filter((bom) => bom.itemId !== itemId));
  }

  function onClickNewItem(item: ProductItem) {
    const newBomTarget: ItemBomEssType = {
      itemDes: item.PROD_DES,
      sizeDes: item.SIZE_DES,
      itemId: item.id,
      consumedQty: 0,
    };
    setBoms((prev) => [...prev, newBomTarget]);
    // setNewBomTargetItem(newBomTarget);

    setSearchTerm(null);
  }

  function consumedQtyChangeHandler(
    targetIndex: number,
    type: 'decrease' | 'increase' | 'none',
    value?: number
  ) {
    setBoms((prev) =>
      prev.map((bom, index) =>
        index === targetIndex
          ? {
              ...bom,
              consumedQty:
                value !== undefined
                  ? value
                  : type === 'decrease'
                  ? bom.consumedQty! - 1
                  : bom.consumedQty! + 1,
            }
          : bom
      )
    );
  }

  if (!producedItem) return;

  return (
    <ModalBase>
      <Container>
        <ContainerHeader>
          <BiX
            color={'white'}
            size={40}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </ContainerHeader>
        <ContainerBody>
          <Header>생산품 정보</Header>
          <Body>
            <div className='row'>
              <h4 className='row-label'>품목명</h4>
              <h4 className='row-value'>{producedItem.itemDes}</h4>
            </div>
            <div className='row'>
              <h4 className='row-label'>규격명</h4>
              <h4 className='row-value'>{producedItem.sizeDes}</h4>
            </div>
            {/* <div className='row'>
              <div className='row-label'>배송지 주소</div>
              <div className='row-value'>{item?.receiverAddress}</div>
            </div>
            <div className='row'>
              <div className='row-label'>주문 특이사항</div>
              <div className='row-value'>{item?.deliveryRemarks}</div>
            </div> */}
          </Body>
          <Header>소모품 정보</Header>
          <Body>
            {boms.map((bom, index) => (
              <div className='row'>
                <div className='row-button'>
                  <FiTrash2
                    color={theme.colors.red}
                    onClick={() => deleteHandler(bom.itemId)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <div className='row-prod'>
                  <h4>{bom.itemDes}</h4>
                  <h4>/</h4>
                  <h4>{bom.sizeDes}</h4>
                </div>
                <div className='flex-center'>
                  <div
                    style={{ flex: 1, textAlign: 'start', cursor: 'pointer' }}
                    onClick={() =>
                      bom.consumedQty! > 0 &&
                      consumedQtyChangeHandler(index, 'decrease')
                    }
                  >
                    <BiSolidLeftArrow />
                  </div>
                  <input
                    value={bom.consumedQty}
                    onChange={(e) => {
                      let value = e.target.value.trim();
                      // 숫자만 남도록 필터링
                      value = value.replace(/[^0-9]/g, ''); // 숫자 외 문자 제거

                      // 앞의 '0' 제거
                      value = value.replace(/^0+/, '');

                      // 빈 값이면 0으로 설정
                      if (value === '' || value === null) {
                        consumedQtyChangeHandler(index, 'none', 0);
                      } else {
                        consumedQtyChangeHandler(index, 'none', Number(value));
                      }
                    }}
                    type='text'
                    style={{ width: '40px', textAlign: 'center' }}
                  />
                  <div
                    style={{ flex: 1, textAlign: 'end', cursor: 'pointer' }}
                    onClick={() => consumedQtyChangeHandler(index, 'increase')}
                  >
                    <BiSolidRightArrow />
                  </div>
                </div>
              </div>
            ))}
            <AddContainer>
              <SearchContainer>
                {newBomTargetItem ? (
                  <h4>{newBomTargetItem.itemDes}</h4>
                ) : (
                  <input
                    value={searchTerm || ''}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                )}
                {searchTerm?.trim() !== '' && searchTerm !== null && (
                  <div
                    style={{
                      position: 'absolute',
                      minHeight: '0px',
                      maxHeight: '250px',
                      overflowY: 'scroll',
                      top: 22,
                      backgroundColor: 'white',
                      padding: '10px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {itemList
                      .filter((item) => item.PROD_DES.includes(searchTerm))
                      .map((item) => {
                        const selectable = !boms.find(
                          (bom) => bom.itemId === item.id
                        );
                        return (
                          <OptionalH4
                            selectable={selectable}
                            onClick={() => selectable && onClickNewItem(item)}
                          >
                            {item.PROD_DES}
                          </OptionalH4>
                        );
                      })}
                  </div>
                )}
              </SearchContainer>
              <AddButton className='flex-center'>추가하기</AddButton>
            </AddContainer>
          </Body>
          {/* <Body>
            {item?.subRows?.map((prod) => (
              <div className='row'>
                <div className='row-value'>
                  {prod.PROD_DES}
                  {prod.SIZE_DES ? ` - ${prod.SIZE_DES}` : ''}
                </div>
                <div className='row-value'>{prod.QTY}개</div>
              </div>
            ))}
          </Body> */}
          <ButtonSection>
            <Button>
              <h4 onClick={() => saveHandler()}>저장</h4>
            </Button>
          </ButtonSection>
        </ContainerBody>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  border-radius: 15px;
  /* overflow: hidden; */
  position: relative;
  width: 600px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ContainerHeader = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.colors.softBlack};
`;

const ContainerBody = styled.div`
  padding: 0px 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontsize[20]};
  height: 50px;
  align-content: center;
`;

const AddContainer = styled.div`
  display: flex;
  top: 0;
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const AddButton = styled.h4`
  flex: 0 0 15%;
`;

const SearchContainer = styled.div`
  bottom: 0;
  display: flex;
  position: relative;
  overflow: visible;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  flex: 0 0 80%;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 10px;
`;

const Button = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  align-content: center;
  text-align: center;
  padding: 5px;
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.blue_hover};
  }
`;

const OptionalH4 = styled.h4<{ selectable: boolean }>`
  ${({ selectable }) =>
    selectable
      ? css`
          &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.colors.white_hover};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.whiteGray};
        `}
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize[16]};
  padding: 0px 15px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
  .row-button {
    flex: 0 0 10%;
  }
  .row-prod {
    flex: 1 0 70%;
    display: flex;
    gap: 15px;
  }

  .row-qty {
    flex: 0 0 20%;
  }
`;
