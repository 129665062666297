import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from 'react-icons/bs';
import * as Styled from './TableStyled';
import { Table } from '@tanstack/react-table';
export interface PaginationState {
  pageIndex: number;
  pageSize: number;
}

export type PaginationProps = {
  table: Table<any>;
  pagination: PaginationState;
  setPageSize: (pageSize: number) => void;
};

export const Pagination = ({
  table,
  pagination,
  setPageSize,
}: PaginationProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Styled.PaginationButtonContainer>
        <Styled.PaginationButton
          size={28}
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <BsChevronDoubleLeft />
        </Styled.PaginationButton>
        <Styled.PaginationButton
          size={28}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <BsChevronLeft />
        </Styled.PaginationButton>
        {/* 중간인풋 */}
        <Styled.PaginationButton
          size={28}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <BsChevronRight />
        </Styled.PaginationButton>
        <Styled.PaginationButton
          size={28}
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          <BsChevronDoubleRight />
        </Styled.PaginationButton>
      </Styled.PaginationButtonContainer>
      <div
        style={{
          alignContent: 'center',
          marginLeft: '20px',
          marginRight: '40px',
        }}
      >
        {table.getPageCount()}페이지 중{' '}
        {table.getState().pagination.pageIndex + 1}페이지
      </div>
      <div className='flex-center'>페이지당 표시 데이터 수</div>
      <select
        id={'row-count'}
        value={pagination.pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
          table.firstPage();
        }}
        style={{ marginLeft: '14px' }}
      >
        <option key={'10'} value={10}>
          10개
        </option>
        <option key={'20'} value={20}>
          20개
        </option>
        <option key={'30'} value={30}>
          30개
        </option>
        <option key={'40'} value={40}>
          40개
        </option>
        <option key={'50'} value={50}>
          50개
        </option>
      </select>
    </div>
  );
};
