export function progressMapper(progressChar: string) {
  //진행상태  W:대기중, P:진행중, E:조기마감, C:마감
  switch (progressChar) {
    case 'W':
      return '대기중';
    case 'P':
      return '진행중';
    case 'E':
      return '조기마감';
    case 'C':
      return '마감';
    default:
      return '';
  }
}
