import { styled } from 'styled-components';

export const Container = styled.div<{ open: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  display: ${({ open }) => (open ? 'auto' : 'none')};
  padding: 0px 0px 0px 30px;
  box-sizing: border-box;
  position: sticky;
  z-index: 99;
  top: 0;
  left: 0;
  transition: height 0.3s ease;
`;

export const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  height: 27px;
  justify-content: space-between;
  padding-top: 14px;
`;

export const NavHistory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme }) => theme.fontsize.header_semi};

  .category-first {
    font-weight: 900;
    color: ${({ theme }) => theme.colors.black};
  }
  .category-second {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
  }
  .category-third {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const InfoButtonSec = styled.div`
  display: flex;
  position: sticky;
  right: 30px;
  top: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UserInfoSec = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderTitle = styled.div`
  height: 40px;
  font-size: ${({ theme }) => theme.fontsize[20]};
  font-weight: bold;
  align-content: center;
`;

export const HeaderButtonSec = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${({ theme }) => theme.colors.charcoal};
`;
