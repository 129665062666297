// OrderListTable.tsx
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { DataRow, SubRow } from '../../pages/OrderListPage';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

interface OrderListTableProps {
  data: DataRow[];
  columns: ColumnDef<any>[];
  checkedSubRows: Record<number, Set<number>>;
  onDateChange: (no: number, newDate: string) => void;
  onSubRowCheckChange: (
    no: number,
    subRowIndex: number,
    isChecked: boolean
  ) => void;
}

const OrderListTable = ({
  data,
  columns,
  checkedSubRows,
  onDateChange,
  onSubRowCheckChange,
}: OrderListTableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { getHeaderGroups } = table;

  return (
    <TableContainer>
      {getHeaderGroups().map((headerGroup) => (
        <TableHeader className='row' key={headerGroup.id}>
          <tr>
            {headerGroup.headers.map((header) =>
              header.isPlaceholder ? null : (
                <TableHeaderCell
                  key={header.id}
                  $width={header.column.getSize()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </TableHeaderCell>
              )
            )}
          </tr>
        </TableHeader>
      ))}
      <TableBody>
        {data.map((row) => (
          <Fragment key={row.no}>
            {/* 메인 행 */}
            <TableRow className='row'>
              <TableCell>{row.no}</TableCell>
              <TableCell>{row.clientName}</TableCell>
              <TableCell></TableCell> {/* SubRows 구분용 */}
              <TableCell></TableCell> {/* SubRows 구분용 */}
              <TableCell></TableCell> {/* SubRows 구분용 */}
              <TableCell></TableCell> {/* SubRows 구분용 */}
              <TableCell></TableCell> {/* SubRows 구분용 */}
              <DeliveryDateCell>
                <DateInput
                  type='date'
                  value={row.deliveryDate}
                  onChange={(e) => onDateChange(row.no, e.target.value)}
                />
              </DeliveryDateCell>
              <TableCell rowSpan={row.subRows.length + 1}>
                {row.orderNotes}
              </TableCell>
              <TableCell rowSpan={row.subRows.length + 1}>
                {row.address}
              </TableCell>
            </TableRow>
            {/* SubRows */}
            {row.subRows.map((subRow, index) => (
              <TableRow className='row' key={`${row.no}-${index}`}>
                <TableCell></TableCell> {/* SubRows 구분용 */}
                <TableCell></TableCell> {/* SubRows 구분용 */}
                <TableCell>{subRow.productName}</TableCell>
                <TableCell>{subRow.orderQuantity}</TableCell>
                <TableCell>{subRow.stockQuantity}</TableCell>
                <TableCell>{subRow.orderMethod}</TableCell>
                <TableCell>{subRow.deliveryMethod}</TableCell>
                <DeliveryDateCell>
                  <input
                    type='checkbox'
                    checked={checkedSubRows[row.no]?.has(index) || false}
                    onChange={(e) =>
                      onSubRowCheckChange(row.no, index, e.target.checked)
                    }
                  />
                </DeliveryDateCell>
              </TableRow>
            ))}
          </Fragment>
        ))}
      </TableBody>
    </TableContainer>
  );
};

export default OrderListTable;

const TableContainer = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-size: 14px;

  border-collapse: collapse; /* 테두리 겹침 방지 */
`;

const TableRow = styled.tr`
  /* border: 1px solid rgba(224, 224, 224, 1); */
`;

const TableHeader = styled.thead``;
const TableBody = styled.tbody``;

const TableCell = styled.td`
  padding: 7px;
  color: #595b60;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  border: 1px solid #e5e5e5; /* 테두리 추가 */
  background-color: #efefef;
`;

const DeliveryDateCell = styled(TableCell)`
  background-color: #fff;
`;

const TableHeaderCell = styled.th<{ $width: number }>`
  width: ${(props) => props.$width}px;
  font-weight: 800;
  padding: 6px;
  color: #000;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  /* word-break: keep-all; */
  border: 1px solid #e5e5e5; /* 테두리 추가 */
`;

const DateInput = styled.input`
  width: 100%;
  padding: 5px;
  color: #595b60;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  box-sizing: border-box;
  border: none;
  cursor: pointer;

  /* Webkit 브라우저에서 달력 아이콘 숨기기 */
  /* &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  /* Firefox 브라우저에서 달력 아이콘 숨기기 */
  /*-moz-appearance: textfield; */
`;
