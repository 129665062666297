export const storeAccessTokenAsync = (accessToken: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      sessionStorage.setItem('accessToken', accessToken);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const storeRefreshTokenAsync = (refreshToken: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      sessionStorage.setItem('refreshToken', refreshToken);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getAccessTokenAsync = () => {
  return new Promise((resolve, reject) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');
      resolve(accessToken);
    } catch (error) {
      reject(error);
    }
  });
};

export const getRefreshTokenAsync = () => {
  return new Promise((resolve, reject) => {
    try {
      const accessToken = sessionStorage.getItem('refreshToken');
      resolve(accessToken);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeAccessToken = () => {
  sessionStorage.removeItem('accessToken');
};

export const removeRefreshToken = () => {
  sessionStorage.removeItem('refreshToken');
};
