// OrderListPage.tsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColumnDef } from '@tanstack/react-table';
import OrderListTable from '../component/table/OrderListTable';
import { useHeaderButtons } from '../component/header/HeaderButtonProvider';
export interface SubRow {
  productName: string;
  orderQuantity: number;
  stockQuantity: number;
  orderMethod: string;
  deliveryMethod: string;
}

export interface DataRow {
  no: number;
  clientName: string;
  subRows: SubRow[];
  deliveryDate: string;
  orderNotes: string;
  address: string;
}

const OrderListPage = () => {
  const { setButtons } = useHeaderButtons();
  const initialData: DataRow[] = [
    {
      no: 1,
      clientName: '(재)성매운국수도회',
      subRows: [
        {
          productName: '천연행복예감점보롤250m(2겹)',
          orderQuantity: 45,
          stockQuantity: 1100,
          orderMethod: '전화',
          deliveryMethod: '택배',
        },
        {
          productName: '미드미 봉헌(미)',
          orderQuantity: 30,
          stockQuantity: 830,
          orderMethod: '카톡',
          deliveryMethod: '직배',
        },
      ],
      deliveryDate: '2024-10-19', // ISO 형식으로 변경
      orderNotes:
        '4573400155764 [10.4 발송요청]하반기/직차운로포섭/033-765-3990',
      address: '서울특별시 강북구 오현로7길 34 (미아동)',
    },
    {
      no: 2,
      clientName: '광주장애인생산품판매시설',
      subRows: [
        {
          productName: '미드미 봉헌(미)',
          orderQuantity: 30,
          stockQuantity: 400,
          orderMethod: '전화',
          deliveryMethod: '택배',
        },
        {
          productName: '자연 그대로 핸드타올',
          orderQuantity: 100,
          stockQuantity: 400,
          orderMethod: '카톡',
          deliveryMethod: '직배',
        },
      ],
      deliveryDate: '2024-10-11', // ISO 형식으로 변경
      orderNotes: '[9.11 발품요청] 반장님...',
      address: '충청북도 청주시 청구구 내수읍 오천대로 980 1층',
    },
  ];

  const [data, setData] = useState<DataRow[]>(initialData);
  const [modifiedRows, setModifiedRows] = useState<Record<number, string>>({});
  const [checkedSubRows, setCheckedSubRows] = useState<
    Record<number, Set<number>>
  >({});

  const columns: ColumnDef<DataRow | SubRow>[] = [
    { accessorKey: 'no', header: 'No', size: 10 },
    { accessorKey: 'clientName', header: '거래처명', size: 150 },
    { accessorKey: 'productName', header: '품목명', size: 150 },
    { accessorKey: 'orderQuantity', header: '주문수량', size: 70 },
    { accessorKey: 'stockQuantity', header: '재고수량', size: 70 },
    { accessorKey: 'orderMethod', header: '주문방법', size: 70 },
    { accessorKey: 'deliveryMethod', header: '배송방법', size: 70 },
    { accessorKey: 'deliveryDate', header: '임시 납기일', size: 100 },
    { accessorKey: 'orderNotes', header: '주문 특이사항' },
    { accessorKey: 'address', header: '주소' },
  ];

  const handleDateChange = (no: number, newDate: string) => {
    setData((prevData) =>
      prevData.map((row) =>
        row.no === no ? { ...row, deliveryDate: newDate } : row
      )
    );
    setModifiedRows((prev) => ({
      ...prev,
      [no]: newDate,
    }));
  };

  const handleSubRowCheckChange = (
    no: number,
    subRowIndex: number,
    isChecked: boolean
  ) => {
    setCheckedSubRows((prev) => {
      const newChecked = { ...prev };
      if (!newChecked[no]) {
        newChecked[no] = new Set<number>();
      }
      if (isChecked) {
        newChecked[no].add(subRowIndex);
      } else {
        newChecked[no].delete(subRowIndex);
        if (newChecked[no].size === 0) {
          delete newChecked[no];
        }
      }
      return newChecked;
    });
  };

  const handleSave = () => {
    // 수정된 main rows 출력
    Object.entries(modifiedRows).forEach(([no, deliveryDate]) => {
      console.log(`No: ${no}, deliveryDate: ${deliveryDate}`);
    });

    // 체크된 subRows 출력
    Object.entries(checkedSubRows).forEach(([no, subRowIndices]) => {
      subRowIndices.forEach((index) => {
        const subRow = data.find((row) => row.no === Number(no))?.subRows[
          index
        ];
        if (subRow) {
          console.log(
            `No: ${no}, SubRow Index: ${index}, Product: ${subRow.productName}`
          );
        }
      });
    });

    // 저장 후 상태 초기화 (선택 사항)
    setModifiedRows({});
    setCheckedSubRows({});
  };

  useEffect(() => {
    setButtons(
      <>
        <Button
          $isActive={
            Object.keys(modifiedRows).length > 0 ||
            Object.keys(checkedSubRows).length > 0
          }
          onClick={() => {
            if (
              Object.keys(modifiedRows).length > 0 ||
              Object.keys(checkedSubRows).length > 0
            ) {
              handleSave();
            }
          }}
        >
          변경사항 저장
        </Button>
      </>
    );
  }, []);

  return (
    <Container>
      {/* <Header>
        <Button
          $isActive={
            Object.keys(modifiedRows).length > 0 ||
            Object.keys(checkedSubRows).length > 0
          }
          onClick={() => {
            if (
              Object.keys(modifiedRows).length > 0 ||
              Object.keys(checkedSubRows).length > 0
            ) {
              handleSave();
            }
          }}
        >
          변경사항 저장
        </Button>
      </Header> */}
      <Content>
        <TableDiv>
          <Title>확인 대기</Title>
          <OrderListTable
            data={data}
            columns={columns}
            onDateChange={handleDateChange}
            checkedSubRows={checkedSubRows}
            onSubRowCheckChange={handleSubRowCheckChange}
          />
        </TableDiv>
      </Content>
    </Container>
  );
};

export default OrderListPage;

const Container = styled.div`
  flex: 1;
  background-color: #fff;
`;
const Header = styled.div`
  padding: 10px 30px;
  background-color: #fff;
`;

const Button = styled.div<{ $isActive: boolean }>`
  width: fit-content;
  padding: 5px 10px;
  background-color: ${({ $isActive }) => ($isActive ? '#14ADEA' : '#fff')};
  color: ${({ $isActive }) => ($isActive ? '#fff' : '#6E7074')};
  border: 1px solid ${({ $isActive }) => ($isActive ? '#14ADEA' : '#6E7074')};
  font-size: 12px;
  font-weight: 600;
  cursor: ${({ $isActive }) => ($isActive ? 'pointer' : 'not-allowed')};
  pointer-events: ${({ $isActive }) => ($isActive ? 'auto' : 'none')};
`;
const Content = styled.div`
  padding: 20px;
`;
const TableDiv = styled.div``;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
`;
