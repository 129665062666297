import styled from 'styled-components';
import {
  OrderListUpdateRequestDto,
  TraderType,
} from '../../../../api/apiConfig';
import ModalBase from '../ModalBase';
import { BiWindowClose } from 'react-icons/bi';
import { BsFiletypeXls } from 'react-icons/bs';
import { BsFiletypeXlsx } from 'react-icons/bs';
import { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { OrderListType } from '../../../../api/apiConfig';
import {
  removeSlashFromDateString,
  replaceOrderUploadserno,
} from '../../../../function/replaceString';
import toast from 'react-hot-toast';

type OrderUploadModalType = {
  onClose: () => void;
  onSelect: (items: OrderListUpdateRequestDto) => void;
  mode: 'add' | 'edit';
  extDataLength?: number;
};
export const OrderUploadModal = ({
  onClose,
  onSelect,
  mode,
  extDataLength,
}: OrderUploadModalType) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const [originFileName, setOriginFileName] = useState<string>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [requestData, setRequestData] =
    useState<OrderListUpdateRequestDto | null>(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange(files[0]);
    }
  };

  const handleFileChange = (file: File) => {
    // if (!e.target.files || e.target.files.length === 0) {
    //   return;
    // }

    // const file = e.target.files[0];
    setOriginFileName(file.name);

    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target) return;

      // 헤더의 행 인덱스를 찾기 위한 배열
      const headerKeywords = [
        '일자-No.',
        '거래처명',
        '거래처코드',
        '담당자코드',
        '담당자명',
        '품목명',
        '규격명',
        '품목코드',
        '품목그룹1코드',
        '품목그룹1명',
        '품목그룹2코드',
        '품목그룹2명',
        '품목그룹3코드',
        '품목그룹3명',
        '수량',
        '단가',
        '공급가액',
        '납기일자',
        '주문방법',
        '배송방법',
        '주문특이사항',
        '배송특이사항',
        '배송지주소',
        '배송지주소 상세',
        '수령인 정보',
        '수령인 연락처',
        '적요',
      ];

      // 헤더 인덱스 선언
      let headerIndex = -1;

      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // 첫 번째 시트
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // 엑셀 데이터를 JSON으로 변환. header:1을 사용하여 헤더지정 없이 초기에는 모든 데이터를 배열로 가져옴
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      for (let i = 0; i < jsonData.length; i++) {
        const row = jsonData[i];
        if (
          headerKeywords.some(
            (keyword) => Array.isArray(row) && row.includes(keyword)
          )
        ) {
          headerIndex = i;
          break;
        }
      }

      if (headerIndex === -1) {
        console.log('header error');
        return;
      }

      // 헤더와 데이터를 분리
      const [headers, ...rows] = jsonData.slice(headerIndex);

      if (Array.isArray(headers)) {
        const mappedHeaders = headers.map((header) => {
          switch (header) {
            case '일자-No.':
              return 'UPLOAD_SER_NO';
            case '거래처명':
              return 'CUST_DES';
            case '거래처코드':
              return 'CUST';
            case '담당자명':
              return 'EMP_NAME';
            case '담당자코드':
              return 'EMP_CD';
            case '품목명':
              return 'PROD_DES';
            case '규격명':
              return 'SIZE_DES';
            case '품목코드':
              return 'PROD_CD';
            case '품목그룹1코드':
              return 'CLASS_CD';
            case '품목그룹1명':
              return 'CLASS_CD_DES';
            case '품목그룹2코드':
              return 'CLASS_CD2';
            case '품목그룹2명':
              return 'CLASS_CD2_DES';
            case '품목그룹3코드':
              return 'CLASS_CD3';
            case '품목그룹3명':
              return 'CLASS_CD3_DES';
            case '수량':
              return 'QTY';
            case '단가':
              return 'PRICE';
            case '공급가액':
              return 'SUPPLY_AMT';
            case '납기일자':
              return 'TIME_DATE';
            case '주문방법':
              return 'ORDER_TYPE';
            case '배송방법':
              return 'DELIVERY_TYPE';
            case '주문특이사항':
              return 'DESCRIPTION';
            case '적요':
              return 'REMARKS';
            case '배송특이사항':
              return 'deliveryRemarks';
            case '배송지주소':
              return 'receiverAddress';
            case '배송지주소 상세':
              return 'receiverAddressDetail';
            case '수령인 정보':
              return 'receiverName';
            case '수령인 연락처':
              return 'receiverTelNo';
            default:
              return null;
          }
        });

        let startDt: string | null = null;
        let endDt: string | null = null;

        const formattedData = rows
          .filter((row) => {
            if (Array.isArray(row)) {
              const custCode = row[mappedHeaders.indexOf('UPLOAD_SER_NO')];
              const custName = row[mappedHeaders.indexOf('CUST')];
              return custCode && custName; // 둘 다 값이 존재할 경우에만
            }
            return false; // 배열이 아닌 경우 false
          })
          .map((row) => {
            if (Array.isArray(row)) {
              // 각 행을 객체로 변환
              return row.reduce(
                (acc, value, index) => {
                  //acc : row(row array)
                  //value : cell value(화장지)
                  //key : key value(PROD_DES ...)
                  const key = mappedHeaders[index];
                  if (key) {
                    if (value) {
                      if (key === 'UPLOAD_SER_NO') {
                        const { date, serial } = replaceOrderUploadserno(value);
                        acc['IO_DATE'] = date; //20240505
                        //startDt와 endDt 설정
                        if (startDt === null && endDt === null) {
                          startDt = date;
                          endDt = date;
                        } else if (startDt && endDt) {
                          startDt =
                            Number(startDt) < Number(date) ? startDt : date;
                          endDt = Number(endDt) > Number(date) ? startDt : date;
                        }
                        acc[key] = serial; // 1
                        acc['ORDER_ID'] = date + '-' + serial; //20240505-1
                      } else if (key === 'TIME_DATE') {
                        //문자열 공백 및 / 제거
                        acc[key] = removeSlashFromDateString(value);
                      } else {
                        acc[key] = value;
                      }
                    } else {
                      acc[key] = null;
                    }
                    delete acc[index]; //기존 숫자인덱스는 삭제
                  }

                  return acc;
                },
                { ...row }
              );
            }
            return;
          });

        console.log('excel data', JSON.stringify(formattedData));
        if (!startDt || !endDt) {
          console.error(
            '주문목록 엑셀 업로드::: 날짜범위를 추출하지 못하였습니다'
          );
          return;
        }
        const requestDto: OrderListUpdateRequestDto = {
          startDt: startDt,
          endDt: endDt,
          orderList: formattedData,
        };
        setRequestData(requestDto);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  console.log(requestData);

  return (
    <ModalBase>
      <Container>
        <HeaderSection>
          거래처등록
          <HeaderButtonSection>
            <div
              className='button'
              onClick={() => {
                if (requestData) {
                  onSelect(requestData);
                  onClose();
                } else {
                  console.log('엑셀정보 없음');
                }
              }}
            >
              업로드
            </div>
            <BiWindowClose
              size={20}
              className='button'
              onClick={() => {
                onClose();
              }}
            />
          </HeaderButtonSection>
        </HeaderSection>
        <UploadSection
          isDragOver={isDragOver}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <IconSection>
            <BsFiletypeXls size={42} color='var(--green)' />
            <BsFiletypeXlsx size={42} color='var(--green)' />
          </IconSection>
          <div style={{ height: '30px' }} />
          <Title>파일을 드래그하여 업로드합니다.</Title>
          <div style={{ height: '30px' }} />
          <SubTitle>
            혹은, 탐색기에서{' '}
            <strong
              style={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() =>
                fileInputRef.current && fileInputRef.current.click()
              }
            >
              직접 선택
            </strong>
            합니다.
          </SubTitle>
          {originFileName && (
            <SubTitle style={{ marginTop: '8px' }}>
              선택된 파일:{originFileName}
            </SubTitle>
          )}
          <input
            type='file'
            ref={fileInputRef}
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                return;
              }
              handleFileChange(e.target.files[0]);
            }}
            style={{ display: 'none' }}
            accept='.xls, .xlsx'
          />
        </UploadSection>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  width: 600px;
  /* height: 700px; */
  border-radius: 8px;
  z-index: 101;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const HeaderSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontsize.table_header};
  background-color: ${({ theme }) => theme.colors.brightNavy};
  color: ${({ theme }) => theme.colors.white};
`;

const HeaderButtonSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .button {
    cursor: pointer;
  }
`;

const UploadSection = styled.div<{ isDragOver: boolean }>`
  /* width: 370px; */
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--green);
  border-radius: 10px;
  background-color: ${(props) =>
    props.isDragOver ? '#ada8a8' : 'transparent'};
`;

const IconSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-size: 22px;
  color: black;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: 18px;
  color: gray;
  font-weight: 700;
`;
