import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Route, Routes } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
//Global styles
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import NaverMap from './component/map/NaverMap';
//RouterContext
import { BrowserRouter } from 'react-router-dom';
//Redux store
import store from './store/configureStore';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ScreenProvider from './ScreenProvider';
const root = ReactDOM.createRoot(document.getElementById('root'));

const persistor = persistStore(store);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ScreenProvider />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
