/** 사이드바 메인 메뉴 */
export const SidebarMajorList = [
  // { name: '테스트', path: '/', key: 5 },
  // { name: '대시보드', path: '/dash-board', key: 0 },
  { name: '주문관리', key: 1 },
  { name: '생산관리', path: '/menu2', key: 2 },
  { name: '기초등록', path: '/menu3', key: 3 },
  { name: '배송', path: '/menu4', key: 4 },
];

/** 사이드바 메인 메뉴에 따르는 서브메뉴 */
export const SidebarMinorList: Record<
  number,
  { name: string; path: string }[]
> = {
  0: [],
  1: [{ name: '주문등록', path: '/orders/update' }],
  2: [
    { name: '생산등록', path: '/production-add' },
    { name: '생산현황', path: '/production-overview' },
    { name: '생산진행', path: '/production-progress' },
    { name: '생산입고', path: '/production-inbound' },
    { name: '재고현황', path: '/stock' },
  ],
  3: [
    { name: '거래처 정보등록', path: '/trader/update' },
    { name: '소모량 등록', path: '/bom' },
    { name: '품목 택배정보 등록', path: '/parcel-item' },
    { name: '거래처 택배정보 등록', path: '/parcel-trader' },
  ],
  4: [
    { name: '배송묶음', path: '/delivery/set' },
    { name: '택배송장변환', path: '/parcel' },
  ],
};

/** 사이드바 메인 메뉴 - 어드민 */
export const SidebarAdminMajorList = [
  { name: '계정관리', path: '/account-manage', key: 0 },
  { name: '데이터관리', path: '/data-manage', key: 1 },
  // { name: '주문관리', key: 1 },
];

/** 사이드바 메인 메뉴에 따르는 서브메뉴 - 어드민 */
export const SidebarAdminMinorList: Record<
  number,
  { name: string; path: string }[]
> = {
  0: [
    // { name: '계정등록', path: '/account-add' },
    // { name: '계정삭제', path: '/account-delete' },
  ],
  1: [],
  // 1: [{ name: '주문등록', path: '/orders/update' }],
};

//엑세스바 모드를 타입으로 export
const accessBarModes = ['ITEM_BALANCE', 'TEST'];
export type AccessBarMode = (typeof accessBarModes)[number];
