import styled from 'styled-components';
import { WebsocketManager } from '../../hook/WebsocketManager';
import { HeaderButtonProvider } from '../../component/header/HeaderButtonProvider';
import Header from '../../component/header/Header';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ProductionProgress } from '../user/pages/ProductionProgress';
import { useEffect } from 'react';
import { ProductionProgressWorker } from '../user/pages/ProductionProgressWorker';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/modules';

export const WorkerRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSidebarOpen = useSelector(
    (state: RootState) => state.sidebar.uiStatus.open
  );

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/login') {
      navigate('/production-progress');
    }
  }, [location]);
  const WithDefaultLayout = ({ children }: { children: React.ReactNode }) => (
    <WholeContainer>
      <WebsocketManager />
      <BodyContainer>
        <HeaderButtonProvider>
          <Header />
          <Content>{children}</Content>
        </HeaderButtonProvider>
      </BodyContainer>
    </WholeContainer>
  );

  return (
    <Routes>
      <Route
        path='production-progress'
        element={
          <WithDefaultLayout>
            <ProductionProgress />
          </WithDefaultLayout>
        }
      />
      <Route
        path='production-progress/:productionId/:itemId'
        element={
          // <WithDefaultLayout>
          <ProductionProgressWorker />
          // </WithDefaultLayout>
        }
      />
    </Routes>
  );
};

const WholeContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const BodyContainer = styled.div`
  flex-basis: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;
