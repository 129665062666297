import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface DashBoardTempleteProps {
  title: string;
  children: ReactNode;
}

const DashBoardTemplete = ({ title, children }: DashBoardTempleteProps) => {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};

export default DashBoardTemplete;

const Container = styled.div`
  background-color: #fff;
  padding: 10px;
  height: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div``;

const Title = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 16px;
`;

const Body = styled.div`
  flex: 1;
`;
