import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  TooltipModel,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { FiSettings, FiShare2 } from 'react-icons/fi';
import { monthlyData } from '../../assets/datas/sampleChartData';

// Chart.js에 필요한 컴포넌트 등록
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);

const WeeklyProductionChart = () => {
  const [currentProduction, setCurrentProduction] = useState<number[]>([]);
  const [targetProduction, setTargetProduction] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>('24.10.27');
  const [endDate, setEndDate] = useState<string>('24.10.31');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [period, setPeriod] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  const chartRef = useRef<any>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  // 오늘 생산량
  const [todayCurrentProduction, setTodayCurrentProduction] = useState<
    number | null
  >(null);
  const [todayTargetProduction, setTodayTargetProduction] = useState<
    number | null
  >(null);

  // 날짜 문자열을 Date 객체로 변환하는 함수 (중복 사용되므로 함수로 정의)
  const parseDate = (dateStr: string): Date => {
    const [year, month, day] = dateStr.split('.').map(Number);
    return new Date(2000 + year, month - 1, day);
  };

  useEffect(() => {
    // 데이터를 가져오기 위한 함수
    const loadData = () => {
      // 모든 월의 데이터를 합칩니다.
      const allLabels: string[] = [];
      const allCurrentProduction: number[] = [];
      const allTargetProduction: number[] = [];

      for (const month in monthlyData) {
        const monthData = monthlyData[month];
        allLabels.push(...monthData.labels);
        allCurrentProduction.push(...monthData.currentProduction);
        allTargetProduction.push(...monthData.targetProduction);
      }

      const parsedStartDate = parseDate(startDate);
      const parsedEndDate = parseDate(endDate);

      // 날짜를 기준으로 데이터를 필터링합니다.
      const filteredLabels: string[] = [];
      const filteredCurrentProduction: number[] = [];
      const filteredTargetProduction: number[] = [];

      for (let i = 0; i < allLabels.length; i++) {
        const labelDate = parseDate(allLabels[i]);

        if (labelDate >= parsedStartDate && labelDate <= parsedEndDate) {
          filteredLabels.push(allLabels[i]);
          filteredCurrentProduction.push(allCurrentProduction[i]);
          filteredTargetProduction.push(allTargetProduction[i]);
        }
      }

      // 기간에 따라 데이터 집계
      const aggregatedData = aggregateData(
        filteredLabels,
        filteredCurrentProduction,
        filteredTargetProduction,
        period
      );

      // 오늘 날짜가 포함되고, 일별 기간인 경우 오늘의 생산량과 목표 생산량을 기존 데이터와 교체
      const today = new Date();
      today.setHours(0, 0, 0, 0); // 시간을 00:00:00으로 설정

      const todayLabel = `${today.getFullYear() % 100}.${
        today.getMonth() + 1
      }.${today.getDate()}`;

      const isTodayIncluded =
        parseDate(startDate) <= today &&
        today <= parseDate(endDate) &&
        period === 'daily';

      console.log('today : ', today);
      console.log('startDate : ', parseDate(startDate));
      console.log('endDate : ', parseDate(endDate));
      console.log('todayLabel : ', todayLabel);

      console.log('isTodayIncluded : ', isTodayIncluded);
      if (
        isTodayIncluded &&
        todayCurrentProduction !== null &&
        todayTargetProduction !== null
      ) {
        // 오늘의 데이터가 이미 있는지 확인
        const todayIndex = aggregatedData.labels.findIndex(
          (label) => label === todayLabel
        );

        console.log('todayIndex : ', todayIndex);

        if (todayIndex !== -1) {
          // 기존 데이터를 교체
          console.log(
            '기존 데이터를 교체 : ',
            aggregatedData.currentProduction[todayIndex],
            ' -> ',
            todayCurrentProduction
          );
          aggregatedData.currentProduction[todayIndex] = todayCurrentProduction;
          aggregatedData.targetProduction[todayIndex] = todayTargetProduction;
        } else {
          // 오늘의 데이터를 추가
          console.log('오늘의 데이터를 추가');
          aggregatedData.labels.push(todayLabel);
          aggregatedData.currentProduction.push(todayCurrentProduction);
          aggregatedData.targetProduction.push(todayTargetProduction);
        }
      }
      setLabels(aggregatedData.labels);
      setCurrentProduction(aggregatedData.currentProduction);
      setTargetProduction(aggregatedData.targetProduction);
    };

    loadData();
  }, [startDate, endDate, period]);
  // 데이터 집계 함수
  const aggregateData = (
    labels: string[],
    currentProduction: number[],
    targetProduction: number[],
    period: 'daily' | 'weekly' | 'monthly'
  ) => {
    const aggregatedLabels: string[] = [];
    const aggregatedCurrentProduction: number[] = [];
    const aggregatedTargetProduction: number[] = [];

    const dateToKey = (date: Date): string => {
      const year = date.getFullYear() % 100; // 연도의 마지막 두 자리
      const month = date.getMonth() + 1; // 월 (1~12)
      if (period === 'weekly') {
        const weekNumber = getWeekOfMonth(date);
        return `${year}.${month} ${weekNumber}주차`;
      } else if (period === 'monthly') {
        return `${year}년 ${month}월`;
      } else {
        // 일별
        const day = date.getDate();
        return `${year}.${month}.${day}`;
      }
    };

    const getWeekOfMonth = (date: Date): number => {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const dayOfMonth = date.getDate();
      const adjustedDate = dayOfMonth + firstDayOfMonth.getDay(); // 첫 주의 시작 요일을 고려
      return Math.ceil(adjustedDate / 7);
    };

    const dataMap: {
      [key: string]: {
        currentProduction: number;
        targetProduction: number;
      };
    } = {};

    for (let i = 0; i < labels.length; i++) {
      const date = parseDate(labels[i]);
      const key = dateToKey(date);

      if (!dataMap[key]) {
        dataMap[key] = {
          currentProduction: 0,
          targetProduction: 0,
        };
      }

      dataMap[key].currentProduction += currentProduction[i];
      dataMap[key].targetProduction += targetProduction[i];
    }

    for (const key in dataMap) {
      aggregatedLabels.push(key);
      aggregatedCurrentProduction.push(dataMap[key].currentProduction);
      aggregatedTargetProduction.push(dataMap[key].targetProduction);
    }

    return {
      labels: aggregatedLabels,
      currentProduction: aggregatedCurrentProduction,
      targetProduction: aggregatedTargetProduction,
    };
  };

  // 막대 색상을 조건부로 설정하기
  const barColors = currentProduction.map((value, index) => {
    const labelDateStr = labels[index];
    const labelDate = parseDate(labelDateStr);

    const today = new Date();

    // 오늘 날짜인지 확인
    const isToday =
      labelDate.getFullYear() === today.getFullYear() &&
      labelDate.getMonth() === today.getMonth() &&
      labelDate.getDate() === today.getDate();

    if (isToday && period === 'daily') {
      // 오늘의 막대는 주황색으로 설정
      return '#FFA500'; // 주황색
    } else {
      // 기존 로직 유지
      return value >= targetProduction[index] ? '#A5dd91' : '#f48b78';
    }
  });
  // ChartData 타입 지정
  const data: ChartData<'bar' | 'line'> = {
    labels: labels,
    datasets: [
      {
        type: 'bar' as const,
        label: '현재 생산량',
        data: currentProduction,
        backgroundColor: barColors,
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        barThickness: 10,
        order: 2,
      },
      {
        type: 'line' as const,
        label: '목표 생산량',
        data: targetProduction,
        fill: false,
        borderColor: '#0099C6',
        borderWidth: 2,
        tension: 0.1,
        pointBackgroundColor: '#0099C6',
        order: 1,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  // 외부 툴팁 함수 정의
  const externalTooltipHandler = (context: {
    chart: any;
    tooltip: TooltipModel<'bar' | 'line'>;
  }) => {
    const { chart, tooltip } = context;
    let tooltipEl = tooltipRef.current;

    // 툴팁 숨기기
    if (tooltip.opacity === 0) {
      if (tooltipEl) {
        tooltipEl.style.opacity = '0';
      }
      return;
    }

    // 툴팁 요소가 없으면 생성
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)';
      tooltipEl.style.borderRadius = '5px';
      tooltipEl.style.padding = '5px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.border = '1px solid #000';
      tooltipEl.style.transform = 'translate(-50%, -100%)';
      tooltipEl.style.transition = 'opacity 0.2s ease';
      tooltipRef.current = tooltipEl;
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    // 데이터 가져오기
    const index = tooltip.dataPoints[0].dataIndex;
    const currentValue = currentProduction[index];
    const targetValue = targetProduction[index];

    // HTML 구성
    tooltipEl.innerHTML = `
      <div style="display: flex; align-items: center; font-size: 13px; font-weight:700; color: #AFAFAF;">
        <span style="color: #A5dd91;">${currentValue}</span>
        <span style="margin: 0 5px;"> / </span>
        <span style="color: #0099C6;">${targetValue}</span>
      </div>
    `;

    // 위치 설정
    tooltipEl.style.opacity = '1';
    tooltipEl.style.zIndex = '99';
    tooltipEl.style.left = tooltip.caretX + 'px';
    tooltipEl.style.top = tooltip.caretY - tooltipEl.offsetHeight - 0 + 'px';
    tooltipEl.style.background = 'rgba(255, 255, 255, 1)';
    tooltipEl.style.borderRadius = '20px';
    tooltipEl.style.padding = '3px 10px';
    tooltipEl.style.border = '1px solid #AFAFAF';
    tooltipEl.style.transform = 'translate(-50%, -100%)';
    tooltipEl.style.transition = 'opacity 0.2s ease';
  };

  // ChartOptions 타입 지정
  const options: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    animations: {
      undefined,
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          boxHeight: 1,
          usePointStyle: true,
          generateLabels: (chart) => {
            const dataset = chart.data.datasets[1]; // 목표 생산량 데이터셋
            return [
              {
                text: dataset.label ?? '목표 생산량', // 기본값 제공
                fillStyle: dataset.borderColor as string,
                strokeStyle: dataset.borderColor as string,
                lineWidth: 2,
                pointStyle: 'line',
              },
            ];
          },
          filter: (legendItem) => legendItem.datasetIndex === 1,
        },
        position: 'top',
        align: 'end',
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
        },
      },
    },
  };

  // 설정 아이콘 클릭 시 모달 열기
  const handleSettingsClick = () => {
    setIsModalOpen(true);
  };

  // 모달에서 설정 변경 처리
  const handleSettingsSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const newStartDate = (
      form.elements.namedItem('startDate') as HTMLInputElement
    ).value;
    const newEndDate = (form.elements.namedItem('endDate') as HTMLInputElement)
      .value;
    const newPeriod = (form.elements.namedItem('period') as HTMLSelectElement)
      .value as 'daily' | 'weekly' | 'monthly';

    // 오늘의 생산량과 목표 생산량 입력 받기
    const newTodayCurrentProduction = parseFloat(
      (form.elements.namedItem('todayCurrentProduction') as HTMLInputElement)
        .value
    );
    const newTodayTargetProduction = parseFloat(
      (form.elements.namedItem('todayTargetProduction') as HTMLInputElement)
        .value
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPeriod(newPeriod);

    // 오늘의 생산량과 목표 생산량 설정
    setTodayCurrentProduction(newTodayCurrentProduction);
    setTodayTargetProduction(newTodayTargetProduction);

    setIsModalOpen(false);
  };
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {/* 헤더 */}
      <Header>
        <SubTitle>
          {startDate} ~ {endDate}
        </SubTitle>
        <div>
          <IconButton onClick={handleSettingsClick}>
            <FiSettings />
          </IconButton>
          <IconButton>
            <FiShare2 />
          </IconButton>
        </div>
      </Header>
      {/* 차트 */}
      <Content>
        <Chart
          ref={chartRef}
          type='bar'
          data={data}
          options={options}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
        {/* 툴팁 요소 */}
        <div ref={tooltipRef} style={{ position: 'absolute', opacity: 0 }} />
      </Content>

      {/* 모달 */}
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <h3>설정</h3>
            <form onSubmit={handleSettingsSubmit}>
              <div>
                <label>
                  시작 날짜:
                  <input
                    type='text'
                    name='startDate'
                    defaultValue={startDate}
                  />
                </label>
              </div>
              <div>
                <label>
                  종료 날짜:
                  <input type='text' name='endDate' defaultValue={endDate} />
                </label>
              </div>
              <div>
                <label>
                  기간 설정:
                  <select name='period' defaultValue={period}>
                    <option value='daily'>일별</option>
                    <option value='weekly'>주별</option>
                    <option value='monthly'>월별</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  오늘 생산량:
                  <input
                    type='number'
                    name='todayCurrentProduction'
                    step='10'
                    defaultValue={
                      todayCurrentProduction !== null
                        ? todayCurrentProduction
                        : ''
                    }
                  />
                </label>
              </div>
              <div>
                <label>
                  오늘 목표 생산량:
                  <input
                    type='number'
                    name='todayTargetProduction'
                    step='10'
                    defaultValue={
                      todayTargetProduction !== null
                        ? todayTargetProduction
                        : ''
                    }
                  />
                </label>
              </div>
              <ButtonGroup>
                <ModalButton type='submit'>적용</ModalButton>
                <ModalButton
                  type='button'
                  onClick={() => setIsModalOpen(false)}
                >
                  취소
                </ModalButton>
              </ButtonGroup>
            </form>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default WeeklyProductionChart;

// Styled-components
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #b3b1b1;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
  margin-left: -15px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const ModalButton = styled.button`
  padding: 8px 12px;
  margin-left: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  &:first-child {
    background-color: #28a745;
  }
`;
