import * as XLSX from 'xlsx';

export const createExcelFileWithColumnLetters = (
  data: any[],
  columns: { header: string; key: string; columnLetter: string }[],
  fileName: string
) => {
  // Step 1: 워크시트 객체 초기화
  const worksheet: XLSX.WorkSheet = {};

  // Step 2: 헤더 설정
  columns.forEach(({ header, columnLetter }) => {
    worksheet[`${columnLetter}1`] = { v: header }; // 첫 행에 헤더 추가
  });

  // Step 3: 데이터 설정
  data.forEach((row, rowIndex) => {
    columns.forEach(({ key, columnLetter }) => {
      worksheet[`${columnLetter}${rowIndex + 2}`] = { v: row[key] || '' }; // 데이터 추가
    });
  });

  // Step 4: 워크시트의 범위 설정
  const range = {
    s: { c: 0, r: 0 }, // 시작 (A1)
    e: { c: columns.length - 1, r: data.length + 1 }, // 끝
  };
  worksheet['!ref'] = XLSX.utils.encode_range(range);

  // Step 5: 워크북 생성 및 저장
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};
