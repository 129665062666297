import {
  ProductionResponseType,
  ProductionCntIncreaseResponseType,
  StockResponseType,
  StockHistoryResponseType,
} from '../../api/apiConfig';

//Production 생산테이블 관련데이터 set
const SET_PRODUCTION_DATA = 'socketData/SET_PRODUCTION_DATA' as const;
//Production 생산테이블 관련데이터 set
const UPDATE_PRODUCTION_DATA = 'socketData/UPDATE_PRODUCTION_DATA' as const;
//Production 생산테이블 현재수량 증가
const INCREASE_PRODUCTION_DATA = 'socketData/INCREASE_PRODUCTION_DATA' as const;
//ItemStock (재고현황) 관련데이터 set
const SET_STOCK_DATA = 'socketData/SET_STOCK_DATA' as const;
//StockHistory(재고변동현황) 관련데이터 set
const SET_STOCK_HISTORY_DATA = 'socketData/SET_STOCK_HISTORY_DATA' as const;
//StockHistory(재고변동현황) 관련데이터 update
const UPDATE_STOCK_HISTORY_DATA =
  'socketData/UPDATE_STOCK_HISTORY_DATA' as const;
//StockHistory(재고변동현황) 관련데이터 add
const ADD_STOCK_HISTORY_DATA = 'socketData/ADD_STOCK_HISTORY_DATA' as const;

export type SocketDataAction =
  | {
      type: typeof SET_PRODUCTION_DATA;
      payload: ProductionResponseType[];
    }
  | {
      type: typeof UPDATE_PRODUCTION_DATA;
      payload: ProductionResponseType[];
    }
  | {
      type: typeof INCREASE_PRODUCTION_DATA;
      payload: ProductionCntIncreaseResponseType;
    }
  | {
      type: typeof SET_STOCK_DATA;
      payload: StockResponseType[];
    }
  | {
      type: typeof SET_STOCK_HISTORY_DATA;
      payload: StockHistoryResponseType[];
    }
  | {
      type: typeof UPDATE_STOCK_HISTORY_DATA;
      payload: StockHistoryResponseType[];
    }
  | {
      type: typeof ADD_STOCK_HISTORY_DATA;
      payload: StockHistoryResponseType[];
    };

export interface SocketState {
  production: ProductionResponseType[];
  stock: StockResponseType[];
  stockHistory: StockHistoryResponseType[];
}

export const setProductionData = (payload: ProductionResponseType[]) => ({
  type: SET_PRODUCTION_DATA,
  payload: payload,
});

export const updateProductionData = (payload: ProductionResponseType[]) => ({
  type: UPDATE_PRODUCTION_DATA,
  payload: payload,
});

export const increaseProductionCount = (
  payload: ProductionCntIncreaseResponseType
) => ({
  type: INCREASE_PRODUCTION_DATA,
  payload: payload,
});

export const setStockData = (payload: StockResponseType[]) => ({
  type: SET_STOCK_DATA,
  payload: payload,
});

export const setStockHistoryData = (payload: StockHistoryResponseType[]) => ({
  type: SET_STOCK_HISTORY_DATA,
  payload: payload,
});

export const updateStockHistoryData = (
  payload: StockHistoryResponseType[]
) => ({
  type: UPDATE_STOCK_HISTORY_DATA,
  payload: payload,
});

export const addStockHistoryData = (payload: StockHistoryResponseType[]) => ({
  type: ADD_STOCK_HISTORY_DATA,
  payload: payload,
});

const initialState: SocketState = {
  production: [],
  stock: [],
  stockHistory: [],
};

const socketDataReducer = (
  state: SocketState = initialState,
  action: SocketDataAction
) => {
  switch (action.type) {
    case SET_PRODUCTION_DATA:
      return {
        ...state,
        production: action.payload,
      };

    case UPDATE_PRODUCTION_DATA:
      return {
        ...state,
        production: [
          ...state.production.map((item) => {
            // payload에서 id가 일치하는 항목 찾기
            const updatedItem = action.payload.find(
              (payloadItem) => payloadItem.id === item.id
            );
            // id가 일치하는 경우 payload 값으로 교체, 그렇지 않으면 기존 값 유지
            return updatedItem ? updatedItem : item;
          }),
          // state.production에 없는 payload 항목 추가
          ...action.payload.filter(
            (payloadItem) =>
              !state.production.some((item) => item.id === payloadItem.id)
          ),
        ],
      };

    case INCREASE_PRODUCTION_DATA:
      return {
        ...state,
        production: state.production.map((prev) =>
          prev.id === action.payload.productionId
            ? { ...prev, productionCnt: action.payload.updatedCount }
            : prev
        ),
      };

    case SET_STOCK_DATA:
      return {
        ...state,
        stock: action.payload,
      };

    case SET_STOCK_HISTORY_DATA:
      return {
        ...state,
        //기존데이터 제거(중복방지)하고 새로 추가
        stockHistory: [
          ...state.stockHistory.filter(
            (prev) => !action.payload.some((updated) => updated.id === prev.id)
          ),
          ...action.payload,
        ],
      };

    case UPDATE_STOCK_HISTORY_DATA:
      return {
        ...state,
        stockHistory: state.stockHistory.map((prev) => {
          const updated = action.payload.find(
            (updatedItem) =>
              updatedItem.id === prev.id &&
              updatedItem.timeDate === prev.timeDate
          );

          // updated가 존재하면 교체, 없으면 기존 값 유지
          return updated ? updated : prev;
        }),
      };

    case ADD_STOCK_HISTORY_DATA:
      return {
        ...state,
        stockHistory: [...state.stockHistory, ...action.payload],
      };

    default:
      return state;
  }
};

export default socketDataReducer;
