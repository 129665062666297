import styled, { useTheme } from 'styled-components';
import {
  OrderListType,
  userCreateApi,
  userDeleteApi,
  UserType,
  userUpdateApi,
} from '../../../../api/apiConfig';
import ModalBase from '../ModalBase';
import { BiX } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import { roleMapper } from '../../../../@const/role';
import useFetch from '../../../../api/useFetch';
import { CustomAxiosError } from '../../../../api/axiosConfig';

export const UserEditModal = ({
  userProp,
  onClose,
  onSave,
  onDelete,
  onCreate,
}: {
  userProp: UserType;
  onClose: () => void;
  onSave: (data: UserType) => void;
  onDelete: (loginId: string) => void;
  onCreate: (data: UserType) => void;
}) => {
  const theme = useTheme();

  const [isNewAccount, setIsNewAccount] = useState<boolean>(false);

  const [user, setUser] = useState<UserType>(userProp);

  const { fetch: fetchUserUpdateApi } = useFetch({
    fetchFunction: userUpdateApi,
    onSuccess: (data: UserType) => {
      window.alert('저장되었습니다');
      onSave(data);
    },
  });

  const { fetch: fetchUserDeleteApiConfig } = useFetch({
    fetchFunction: userDeleteApi,
    onSuccess: (loginId: string) => {
      window.alert('삭제되었습니다');
      onDelete(loginId);
    },
  });

  const { fetch: fetchUserCreateApi } = useFetch({
    fetchFunction: userCreateApi,
    onSuccess: (data: UserType) => {
      window.alert('생성되었습니다');
      onCreate(data);
    },
    onError: (error?: CustomAxiosError) => {
      if (error?.response.data.errorCode === 'DUPLICATE_LOGIN_ID') {
        window.alert(error?.response.data.message);
      }
    },
  });

  useEffect(() => {
    userProp.id === null ? setIsNewAccount(true) : setIsNewAccount(false);
  }, [userProp]);

  const [pw, setPw] = useState<string | null>(null);
  const [pwCheck, setPwCheck] = useState<string | null>(null);

  function updateHandler() {
    if (pw !== null && pw.trim() !== '') {
      if (pw !== pwCheck) {
        window.alert('비밀번호 확인이 일치하지 않습니다');
        return;
      } else {
        fetchUserUpdateApi({ ...user, password: pw });
      }
    } else {
      fetchUserUpdateApi(user);
    }
  }

  function createHandler() {
    if (isEmpty(user.loginId)) return;
    if (isEmpty(user.role)) return;
    if (isEmpty(user.userName)) return;
    if (!pw || isEmpty(pw)) return;
    if (pw !== null && pw.trim() !== '') {
      if (pw !== pwCheck) {
        window.alert('비밀번호 확인이 일치하지 않습니다');
        return;
      } else {
        fetchUserCreateApi({ ...user, password: pw });
      }
    }
  }

  function deleteHandler() {
    const confirm = window.confirm('정말 삭제하시겠습니까?');
    if (!confirm) {
      return;
    } else {
      fetchUserDeleteApiConfig(user.loginId);
    }
  }

  function isEmpty(data: string) {
    return data === null || data.trim() === '';
  }

  return (
    <ModalBase>
      <Container>
        <ContainerHeader>
          <BiX
            color={'white'}
            size={40}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </ContainerHeader>
        <ContainerBody>
          <Header>주문 상세</Header>
          <Body>
            <div className='row'>
              <div className='row-label'>이름</div>
              <div className='row-value'>
                <input
                  value={user.userName}
                  onChange={(e) =>
                    setUser({ ...user, userName: e.target.value })
                  }
                />
              </div>
            </div>
            {isNewAccount && (
              <div className='row'>
                <div className='row-label'>로그인ID</div>
                <div className='row-value'>
                  <input
                    value={user.loginId}
                    onChange={(e) =>
                      setUser({ ...user, loginId: e.target.value })
                    }
                  />
                </div>
              </div>
            )}
            <div className='row'>
              <div className='row-label'>권한</div>
              <div className='row-value'>
                <select
                  value={user.role}
                  onChange={(e) => {
                    setUser({ ...user, role: e.target.value });
                  }}
                >
                  <option key={'ADMIN'} value='ADMIN'>
                    {roleMapper['ADMIN']}
                  </option>
                  <option key={'MANAGER'} value='MANAGER'>
                    {roleMapper['MANAGER']}
                  </option>
                  <option key={'WORKER'} value='WORKER'>
                    {roleMapper['WORKER']}
                  </option>
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='row-label'>
                {isNewAccount ? `비밀번호 설정` : `비밀번호 변경`}
              </div>
              <div className='row-value'>
                <input
                  value={pw || undefined}
                  onChange={(e) => setPw(e.target.value)}
                  type='password'
                />
              </div>
            </div>
            <div className='row'>
              <div className='row-label'>
                {isNewAccount ? `비밀번호 설정 확인` : `비밀번호 변경 확인`}
              </div>
              <div className='row-value'>
                <input
                  value={pwCheck || undefined}
                  onChange={(e) => setPwCheck(e.target.value)}
                  type='password'
                />
              </div>
            </div>
          </Body>
          <ButtonSection>
            <Button
              bgColor={theme.colors.blue}
              bgColorHover={theme.colors.blue_hover}
              onClick={() => (isNewAccount ? createHandler() : updateHandler())}
            >
              저장
            </Button>
            <Button
              bgColor={theme.colors.grapeFruit}
              bgColorHover={theme.colors.grapeFruit_hover}
              onClick={() => deleteHandler()}
            >
              삭제
            </Button>
          </ButtonSection>
        </ContainerBody>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  border-radius: 15px;
  overflow: hidden;
  width: 600px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
`;

const ContainerHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.colors.softBlack};
`;

const ContainerBody = styled.div`
  padding: 0px 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontsize[20]};
  height: 50px;
  align-content: center;
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize[16]};
  padding: 15px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .row-label {
    justify-content: flex-start;
    width: 160px;
    padding: 8px;
  }

  .row-value {
    justify-content: flex-start;
    /* border: 1px solid ${({ theme }) => theme.colors.charcoal}; */
    padding: 8px;
    flex: 1;
    /* border-radius: 6px; */
    input {
      width: 70%;
    }
  }
`;

const ButtonSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  margin-top: 15px;
  align-items: center;
`;

const Button = styled.div<{ bgColor: string; bgColorHover: string }>`
  width: 48%;
  cursor: pointer;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontsize[18]};
  height: 100%;
  text-align: center;
  align-content: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ bgColor }) => bgColor};

  &:hover {
    background-color: ${({ bgColorHover }) => bgColorHover};
  }
`;
