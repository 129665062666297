import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import {
  bomListGetApi,
  bomPostApi,
  BomRequestType,
  ItemBomResponseEssType,
  ItemBomResponseType,
  ItemBomType,
  itemListGetApi,
  parcelItemListAllGetApi,
  parcelItemListGetApi,
  parcelItemPostApi,
  ParcelItemResponseType,
  parcelTraderListAllGetApi,
  parcelTraderPostApi,
  ParcelTraderResponseType,
  ProductItem,
  traderListGetApi,
  TraderType,
} from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
import { BomEditModal } from '../../../component/common/modal/ModalComponents/BomEditModal';
import { ParcelItemEditModal } from '../../../component/common/modal/ModalComponents/ParcelItemEditModal';
import { ParcelTraderEditModal } from '../../../component/common/modal/ModalComponents/ParcelTraderEditModal';

export const ParcelTraderPage = () => {
  const [traders, setTraders] = useState<TraderType[]>([]);

  const [parcelTraders, setParcelTraders] = useState<
    ParcelTraderResponseType[]
  >([]);

  const [modalTrader, setModalTrader] = useState<TraderType | null>(null);

  const [modalParcelTrader, setModalParcelTrader] =
    useState<ParcelTraderResponseType | null>(null);

  //   const { fetch: fetchParcelItemListGet } = useFetch({
  //     fetchFunction: parcelItemListGetApi,
  //     onSuccess: (data: ParcelItemResponseType[]) => setParcelItems(data),
  //   });

  const { fetch: fetchTraderListGetApi } = useFetch({
    fetchFunction: traderListGetApi,
    onSuccess: (data: TraderType[]) => setTraders(data),
  });

  const { fetch: fetchParcelTraderListAllGetApi } = useFetch({
    fetchFunction: parcelTraderListAllGetApi,
    onSuccess: (data: ParcelTraderResponseType[]) => setParcelTraders(data),
  });

  const { fetch: fetchParcelTraderPostApi } = useFetch({
    fetchFunction: parcelTraderPostApi,
    onSuccess: (data: ParcelTraderResponseType) => {
      applyNewParcel(data);
      setModalTrader(null);
      setModalParcelTrader(null);
    },
  });

  function applyNewParcel(data: ParcelTraderResponseType) {
    setParcelTraders((prev) =>
      prev.some((parcelTrader) => parcelTrader.custCd === data.custCd)
        ? prev.map((parcelTrader) =>
            parcelTrader.custCd === data.custCd ? data : parcelTrader
          )
        : [...prev, data]
    );
  }

  useEffect(() => {
    fetchTraderListGetApi();
    fetchParcelTraderListAllGetApi();
  }, []);

  function onClickBomCell(custCd: string) {
    const trader =
      traders.find((trader) => trader.CUST_CODE === custCd) || null;
    const parcelTrader =
      parcelTraders.find((parcelTrader) => parcelTrader.custCd === custCd) ||
      null;

    if (!parcelTrader) {
      if (!trader) {
        console.log('거래처 엑셀옵션, 거래처정보 모두를 찾을 수 없음');
        return;
      }
      const newParcel: ParcelTraderResponseType = {
        id: null,
        label: null,
        custCd: custCd,
      };
      setModalTrader(trader);
      setModalParcelTrader(newParcel);
    } else {
      setModalTrader(trader);
      setModalParcelTrader(parcelTrader);
    }
  }

  function saveParcellTrader(item: ParcelTraderResponseType) {
    fetchParcelTraderPostApi(item);
  }

  return (
    <Container>
      {modalTrader && modalParcelTrader && (
        <ParcelTraderEditModal
          parcelTraderProp={modalParcelTrader}
          traderProp={modalTrader}
          onClose={() => {
            setModalTrader(null);
            setModalParcelTrader(null);
          }}
          onSave={(trader: ParcelTraderResponseType) =>
            saveParcellTrader(trader)
          }
        />
      )}
      <h2 style={{ marginTop: '0px' }}>거래처 택배정보 목록</h2>
      <RowContainer>
        <HeaderRow>
          <h4 className='prod-des flex-center'>거래처명</h4>
          <h4 className='label flex-center'>엑셀 변환될 이름</h4>
        </HeaderRow>
        {traders.length > 0 &&
          traders.map((trader, index) => {
            const targetParcelTrader =
              (Array.isArray(parcelTraders) &&
                parcelTraders.length > 0 &&
                parcelTraders.find(
                  (parcelTrader) => parcelTrader.custCd === trader.CUST_CODE
                )) ||
              null;
            const label = targetParcelTrader ? targetParcelTrader.label : null;

            return (
              <Row odd={index % 2 === 1}>
                <h5
                  className='prod-des flex-center'
                  onClick={() => onClickBomCell(trader.CUST_CODE)}
                >
                  {trader.CUST_NAME}
                </h5>
                <h5 className='label flex-center'>{label}</h5>
              </Row>
            );
          })}
        <Row></Row>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div``;

const RowContainer = styled.div`
  /* background-color: white; */
`;

const Row = styled.div<{ odd?: boolean }>`
  display: flex;
  width: 100%;
  height: 32px;
  flex-direction: row;
  align-items: center;

  background-color: ${({ odd, theme }) =>
    odd ? theme.colors.white_odd : theme.colors.white};

  .prod-des {
    flex: 1 0 20%;
    &:hover {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .label {
    flex: 1 0 20%;
  }
`;

const HeaderRow = styled(Row)`
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.charcoal};
  box-sizing: border-box;
  .has-bom {
    &:hover {
      text-decoration: none;
      cursor: auto;
    }
  }
`;
