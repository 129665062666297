import { combineReducers } from 'redux';
import sidebarReducer from './sidebar';
import loginUserDataReducer from './loginUserData';
import socketDataReducer from './socketData';
import headerReducer from './header';

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  header: headerReducer,
  loginUserData: loginUserDataReducer,
  socketData: socketDataReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
