// data.js
export const monthlyData: {
  [key: string]: {
    labels: string[];
    currentProduction: number[];
    targetProduction: number[];
  };
} = {
  '2024-09': {
    labels: [
      '24.09.01',
      '24.09.02',
      '24.09.03',
      '24.09.04',
      '24.09.05',
      '24.09.06',
      '24.09.07',
      '24.09.08',
      '24.09.09',
      '24.09.10',
      '24.09.11',
      '24.09.12',
      '24.09.13',
      '24.09.14',
      '24.09.15',
      '24.09.16',
      '24.09.17',
      '24.09.18',
      '24.09.19',
      '24.09.20',
      '24.09.21',
      '24.09.22',
      '24.09.23',
      '24.09.24',
      '24.09.25',
      '24.09.26',
      '24.09.27',
      '24.09.28',
      '24.09.29',
      '24.09.30',
    ],
    currentProduction: [
      950, 1000, 850, 1200, 1100, 1050, 1150, 1200, 1100, 1250, 950, 1000, 900,
      1300, 1250, 1050, 1150, 1350, 1000, 1200, 1050, 1150, 950, 1200, 1150,
      1100, 1250, 1300, 1150, 1200,
    ],
    targetProduction: Array(30).fill(1000),
  },
  '2024-10': {
    labels: [
      '24.10.01',
      '24.10.02',
      '24.10.03',
      '24.10.04',
      '24.10.05',
      '24.10.06',
      '24.10.07',
      '24.10.08',
      '24.10.09',
      '24.10.10',
      '24.10.11',
      '24.10.12',
      '24.10.13',
      '24.10.14',
      '24.10.15',
      '24.10.16',
      '24.10.17',
      '24.10.18',
      '24.10.19',
      '24.10.20',
      '24.10.21',
      '24.10.22',
      '24.10.23',
      '24.10.24',
      '24.10.25',
      '24.10.26',
      '24.10.27',
      '24.10.28',
      '24.10.29',
      '24.10.30',
      '24.10.31',
    ],
    currentProduction: [
      1150, 1200, 1050, 1250, 1300, 1100, 1250, 1350, 1150, 1250, 1200, 1100,
      1250, 1400, 1200, 1150, 1300, 1250, 1100, 1200, 1150, 1300, 1250, 1350,
      1200, 1150, 1250, 1400, 1200, 1300, 1250,
    ],
    targetProduction: Array(31).fill(1200),
  },
  '2024-11': {
    labels: [
      '24.11.01',
      '24.11.02',
      '24.11.03',
      '24.11.04',
      '24.11.05',
      '24.11.06',
      '24.11.07',
      '24.11.08',
      '24.11.09',
      '24.11.10',
      '24.11.11',
      '24.11.12',
      '24.11.13',
      '24.11.14',
      '24.11.15',
      '24.11.16',
      '24.11.17',
      '24.11.18',
      '24.11.19',
      '24.11.20',
      '24.11.21',
      '24.11.22',
      '24.11.23',
      '24.11.24',
      '24.11.25',
      '24.11.26',
      '24.11.27',
      '24.11.28',
      '24.11.29',
      '24.11.30',
    ],
    currentProduction: [
      1100, 1150, 900, 1300, 1250, 1150, 1200, 1100, 1200, 1250, 1300, 1200,
      1150, 1300, 1250, 1200, 1150, 1100, 1250, 1300, 1200, 1250, 1150, 1200,
      1300, 1250, 1200, 1150, 1300, 1200,
    ],
    targetProduction: Array(30).fill(1100),
  },
};
