import styled from 'styled-components';
import useFetch from '../../../api/useFetch';
import {
  bomListGetApi,
  bomPostApi,
  BomRequestType,
  ItemBomResponseEssType,
  ItemBomResponseType,
  ItemBomType,
  itemListGetApi,
  parcelItemListAllGetApi,
  parcelItemListGetApi,
  parcelItemPostApi,
  ParcelItemResponseType,
  ProductItem,
} from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
import { BomEditModal } from '../../../component/common/modal/ModalComponents/BomEditModal';
import { ParcelItemEditModal } from '../../../component/common/modal/ModalComponents/ParcelItemEditModal';

export const ParcelItemPage = () => {
  const [items, setItems] = useState<ProductItem[]>([]);

  const [parcelItems, setParcelItems] = useState<ParcelItemResponseType[]>([]);

  const [modalItem, setModalItem] = useState<ProductItem | null>(null);

  const [modalParcelItem, setModalParcelItem] =
    useState<ParcelItemResponseType | null>(null);

  //   const { fetch: fetchParcelItemListGet } = useFetch({
  //     fetchFunction: parcelItemListGetApi,
  //     onSuccess: (data: ParcelItemResponseType[]) => setParcelItems(data),
  //   });

  const { fetch: fetchItemListGet } = useFetch({
    fetchFunction: itemListGetApi,
    onSuccess: (data: ProductItem[]) => setItems(data),
  });

  const { fetch: fetchParcelItemPostApi } = useFetch({
    fetchFunction: parcelItemPostApi,
    onSuccess: (data: ParcelItemResponseType) => {
      applyNewParcel(data);
      setModalItem(null);
      setModalParcelItem(null);
    },
  });

  const { fetch: fetchParcelItemListAllGetApi } = useFetch({
    fetchFunction: parcelItemListAllGetApi,
    onSuccess: (data: ParcelItemResponseType[]) => setParcelItems(data),
  });

  function applyNewParcel(data: ParcelItemResponseType) {
    setParcelItems((prev) =>
      prev.some((parcelItem) => parcelItem.prodCd === data.prodCd)
        ? prev.map((parcelItem) =>
            parcelItem.prodCd === data.prodCd ? data : parcelItem
          )
        : [...prev, data]
    );
  }

  useEffect(() => {
    fetchItemListGet();
    fetchParcelItemListAllGetApi();
  }, []);

  function onClickBomCell(prodCd: string) {
    const item = items.find((item) => item.PROD_CD === prodCd) || null;
    const parcellItem =
      parcelItems.find((parcelItem) => parcelItem.prodCd === prodCd) || null;

    if (!parcellItem) {
      if (!item) {
        console.log('품목 엑셀옵션, 품목정보 모두를 찾을 수 없음');
        return;
      }
      const newParcel: ParcelItemResponseType = {
        id: null,
        label: null,
        sizeLabel: null,
        unitLabel: null,
        packageMaxQty: null,
        prodCd: prodCd,
      };
      setModalItem(item);
      setModalParcelItem(newParcel);
    } else {
      setModalItem(item);
      setModalParcelItem(parcellItem);
    }
  }

  function saveParcellItem(item: ParcelItemResponseType) {
    fetchParcelItemPostApi(item);
  }

  return (
    <Container>
      {modalItem && modalParcelItem && (
        <ParcelItemEditModal
          parcelItemProp={modalParcelItem}
          itemProp={modalItem}
          onClose={() => {
            setModalItem(null);
            setModalParcelItem(null);
          }}
          onSave={(item: ParcelItemResponseType) => saveParcellItem(item)}
        />
      )}
      <h2 style={{ marginTop: '0px' }}>품목 택배정보 목록</h2>
      <RowContainer>
        <HeaderRow>
          <h4 className='prod-des flex-center'>제품명</h4>
          <h4 className='label flex-center'>엑셀 변환될 이름</h4>
          <h4 className='size-label flex-center'>엑셀 변환될 규격</h4>
          <h4 className='unit-label flex-center'>엑셀 변환될 단위</h4>
          <h4 className='max-qty flex-center'>포장 최대개수 제한</h4>
        </HeaderRow>
        {items.length > 0 &&
          items.map((item, index) => {
            const targetParcelItem =
              (Array.isArray(parcelItems) &&
                parcelItems.length > 0 &&
                parcelItems.find(
                  (parcelItem) => parcelItem.prodCd === item.PROD_CD
                )) ||
              null;
            const label = targetParcelItem ? targetParcelItem.label : null;
            const sizeLabel = targetParcelItem
              ? targetParcelItem.sizeLabel
              : null;
            const unitLabel = targetParcelItem
              ? targetParcelItem.unitLabel
              : null;
            const maxQty = targetParcelItem
              ? targetParcelItem.packageMaxQty
              : null;
            return (
              <Row odd={index % 2 === 1}>
                <h5
                  className='prod-des flex-center'
                  onClick={() => onClickBomCell(item.PROD_CD)}
                >
                  {item.PROD_DES}
                </h5>
                <h5 className='label flex-center'>{label}</h5>
                <h5 className='size-label flex-center'>{sizeLabel}</h5>
                <h5 className='unit-label flex-center'>{unitLabel}</h5>
                <h5 className='max-qty flex-center'>{maxQty}</h5>
              </Row>
            );
          })}
        <Row></Row>
      </RowContainer>
    </Container>
  );
};

const Container = styled.div``;

const RowContainer = styled.div`
  /* background-color: white; */
`;

const Row = styled.div<{ odd?: boolean }>`
  display: flex;
  width: 100%;
  height: 32px;
  flex-direction: row;
  align-items: center;

  background-color: ${({ odd, theme }) =>
    odd ? theme.colors.white_odd : theme.colors.white};

  .prod-des {
    flex: 0 0 20%;
    &:hover {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .label {
    flex: 0 0 20%;
  }
  .size-label {
    flex: 0 0 20%;
  }
  .unit-label {
    flex: 0 0 20%;
  }
  .max-qty {
    flex: 0 0 20%;
  }
`;

const HeaderRow = styled(Row)`
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.charcoal};
  box-sizing: border-box;
  .has-bom {
    &:hover {
      text-decoration: none;
      cursor: auto;
    }
  }
`;
