import styled from 'styled-components';
import { OrderListType } from '../../../../api/apiConfig';
import ModalBase from '../ModalBase';
import { BiX } from 'react-icons/bi';

export const DeliveryOrderDetailModal = ({
  item,
  onClose,
}: {
  item: OrderListType | null;
  onClose: () => void;
}) => {
  console.log('item on modal', item);
  return (
    <ModalBase>
      <Container>
        <ContainerHeader>
          <BiX
            color={'white'}
            size={40}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
        </ContainerHeader>
        <ContainerBody>
          <Header>주문 상세</Header>
          <Body>
            <div className='row'>
              <div className='row-label'>거래처명</div>
              <div className='row-value'>{item?.CUST_DES}</div>
            </div>
            <div className='row'>
              <div className='row-label'>납기일</div>
              <div className='row-value'>{item?.TIME_DATE}</div>
            </div>
            <div className='row'>
              <div className='row-label'>배송지 주소</div>
              <div className='row-value'>{item?.receiverAddress}</div>
            </div>
            <div className='row'>
              <div className='row-label'>주문 특이사항</div>
              <div className='row-value'>{item?.deliveryRemarks}</div>
            </div>
          </Body>
          <Header>품목 정보</Header>
          <Body>
            {item?.subRows?.map((prod) => (
              <div className='row'>
                <div className='row-value'>
                  {prod.PROD_DES}
                  {prod.SIZE_DES ? ` - ${prod.SIZE_DES}` : ''}
                </div>
                <div className='row-value'>{prod.QTY}개</div>
              </div>
            ))}
          </Body>
        </ContainerBody>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  border-radius: 15px;
  overflow: hidden;
  width: 600px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
`;

const ContainerHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.colors.softBlack};
`;

const ContainerBody = styled.div`
  padding: 0px 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontsize[20]};
  height: 50px;
  align-content: center;
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsize[16]};
  padding: 15px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .row-label {
    justify-content: flex-start;
    width: 110px;
    padding: 8px;
  }

  .row-value {
    justify-content: flex-start;
    /* border: 1px solid ${({ theme }) => theme.colors.charcoal}; */
    padding: 8px;
    /* border-radius: 6px; */
  }
`;
