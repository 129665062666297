// data.ts

export interface Manager {
  id: number;
  name: string;
}

export const sampleManagers: Manager[] = [
  { id: 1, name: '홍길동' },
  { id: 2, name: '김영희' },
  { id: 3, name: '이철수' },
  { id: 4, name: '박미정' },
  { id: 5, name: '최성훈' },
];

export interface SubRow {
  productName: string;
  orderQuantity: number;
  deliveryDate: string;
}

export interface DataRow {
  no: number;
  clientName: string;
  subRows: SubRow[];
  orderNotes: string;
  address: string;
}

export const data: DataRow[] = [
  {
    no: 1,
    clientName: 'ABC 상사',
    subRows: [
      {
        productName: '고급형 노트북',
        orderQuantity: 5,
        deliveryDate: '2024-11-01',
      },
      {
        productName: '무선 마우스',
        orderQuantity: 10,
        deliveryDate: '2024-11-01',
      },
    ],
    orderNotes: '긴급 배송 요청',
    address: '서울특별시 강남구 테헤란로 521',
  },
  {
    no: 2,
    clientName: 'XYZ 유통',
    subRows: [
      {
        productName: '게이밍 키보드',
        orderQuantity: 15,
        deliveryDate: '2024-11-01',
      },
      {
        productName: 'USB 메모리',
        orderQuantity: 100,
        deliveryDate: '2024-11-01',
      },
    ],
    orderNotes: '포장에 신경 써 주세요',
    address: '경기도 수원시 영통구 광교호수공원로 80',
  },
  {
    no: 3,
    clientName: '테크 마트',
    subRows: [
      {
        productName: '4K 모니터',
        orderQuantity: 7,
        deliveryDate: '2024-11-01',
      },
      {
        productName: '외장 하드 드라이브',
        orderQuantity: 20,
        deliveryDate: '2024-11-01',
      },
      {
        productName: 'USB-C 허브',
        orderQuantity: 30,
        deliveryDate: '2024-11-01',
      },
    ],
    orderNotes: '납품 전 연락 바랍니다',
    address: '부산광역시 해운대구 센텀남대로 35',
  },
  {
    no: 4,
    clientName: '온라인샵 24',
    subRows: [
      {
        productName: '스마트 워치',
        orderQuantity: 50,
        deliveryDate: '2024-11-01',
      },
    ],
    orderNotes: '',
    address: '대구광역시 중구 동성로 39',
  },
  {
    no: 5,
    clientName: '오피스 플러스',
    subRows: [
      {
        productName: '컬러 레이저 프린터',
        orderQuantity: 3,
        deliveryDate: '2024-11-01',
      },
      {
        productName: 'A4 복사용지',
        orderQuantity: 100,
        deliveryDate: '2024-11-01',
      },
    ],
    orderNotes: '프린터 설치 요청',
    address: '인천광역시 남동구 인주대로 654',
  },
  {
    no: 6,
    clientName: '스마트 가전',
    subRows: [
      {
        productName: '공기청정기',
        orderQuantity: 25,
        deliveryDate: '2024-11-02',
      },
      {
        productName: '에어컨 필터',
        orderQuantity: 50,
        deliveryDate: '2024-11-02',
      },
    ],
    orderNotes: '가전 설치 관련 문의 필요',
    address: '광주광역시 서구 내방로 100',
  },
  {
    no: 7,
    clientName: '전자랜드',
    subRows: [
      {
        productName: '디지털 카메라',
        orderQuantity: 10,
        deliveryDate: '2024-11-02',
      },
      {
        productName: '메모리 카드',
        orderQuantity: 50,
        deliveryDate: '2024-11-02',
      },
    ],
    orderNotes: '',
    address: '경기도 성남시 분당구 대왕판교로 700',
  },
  {
    no: 8,
    clientName: '모바일월드',
    subRows: [
      {
        productName: '스마트폰 충전기',
        orderQuantity: 200,
        deliveryDate: '2024-11-02',
      },
      {
        productName: '휴대폰 케이스',
        orderQuantity: 300,
        deliveryDate: '2024-11-02',
      },
    ],
    orderNotes: '배송 시간 조정 요청',
    address: '서울특별시 종로구 종로 200',
  },
  {
    no: 9,
    clientName: '오피스 용품몰',
    subRows: [
      {
        productName: '사무용 의자',
        orderQuantity: 5,
        deliveryDate: '2024-11-03',
      },
      { productName: '책상', orderQuantity: 8, deliveryDate: '2024-11-03' },
    ],
    orderNotes: '현장 설치 필요',
    address: '부산광역시 북구 화명로 123',
  },
  {
    no: 10,
    clientName: '스타트업 지원센터',
    subRows: [
      {
        productName: 'LED 모니터',
        orderQuantity: 20,
        deliveryDate: '2024-11-03',
      },
    ],
    orderNotes: 'LED 제품으로 변경 요청',
    address: '대전광역시 유성구 궁동 456',
  },
];
export interface SampleAfterSubRow {
  managerName: string;
  subRow: SampleAfterThirdRow[];
}

export interface SampleAfterThirdRow {
  clientName: string;
  address: string;
}

export interface SampleAfterDataType {
  date: string;
  subRows: SampleAfterSubRow[];
}

export const SampleAfterData: SampleAfterDataType[] = [
  {
    date: '2024-11-01',
    subRows: [
      {
        managerName: '홍길동',
        subRow: [
          {
            clientName: '서울특별시립 노인복지관',
            address: '서울특별시 종로구 삼청동 100',
          },
          {
            clientName: '강남구청 사회복지과',
            address: '서울특별시 강남구 테헤란로 521',
          },
        ],
      },
      {
        managerName: '김영희',
        subRow: [
          {
            clientName: '부산광역시 복지센터',
            address: '부산광역시 해운대구 센텀남대로 35',
          },
          {
            clientName: '인천복지서비스센터',
            address: '인천광역시 남동구 인주대로 654',
          },
        ],
      },
      {
        managerName: '이철수',
        subRow: [
          {
            clientName: '강북구 노인복지관',
            address: '서울특별시 강북구 삼양로 222',
          },
          {
            clientName: '관악구 노인회관',
            address: '서울특별시 관악구 봉천로 234',
          },
        ],
      },
      {
        managerName: '최성훈',
        subRow: [
          {
            clientName: '대전 사회복지센터',
            address: '대전광역시 서구 둔산로 89',
          },
          {
            clientName: '경기도 남양주시 복지시설',
            address: '경기도 남양주시 도농로 50',
          },
        ],
      },
      {
        managerName: '박미정',
        subRow: [
          {
            clientName: '인천 남구 복지관',
            address: '인천광역시 남구 용현동 234',
          },
          {
            clientName: '대구 동구 노인복지센터',
            address: '대구광역시 동구 신천동 123',
          },
        ],
      },
    ],
  },
  {
    date: '2024-11-02',
    subRows: [
      {
        managerName: '유재석',
        subRow: [
          {
            clientName: '서울 중구 복지시설',
            address: '서울특별시 중구 을지로 77',
          },
          {
            clientName: '경기도 부천 복지센터',
            address: '경기도 부천시 소사구 55',
          },
        ],
      },
      {
        managerName: '조민수',
        subRow: [
          {
            clientName: '광주 서구 노인회관',
            address: '광주광역시 서구 내방로 88',
          },
          {
            clientName: '대전 유성구 복지관',
            address: '대전광역시 유성구 궁동 33',
          },
        ],
      },
      {
        managerName: '정윤호',
        subRow: [
          {
            clientName: '강원도 강릉 노인복지센터',
            address: '강원도 강릉시 포남동 99',
          },
          {
            clientName: '경남 진주 복지센터',
            address: '경상남도 진주시 진양호로 78',
          },
        ],
      },
      {
        managerName: '이지은',
        subRow: [
          {
            clientName: '제주 노인회관',
            address: '제주특별자치도 제주시 연동 45',
          },
          {
            clientName: '경북 구미 복지관',
            address: '경상북도 구미시 구미로 111',
          },
        ],
      },
      {
        managerName: '이철수',
        subRow: [
          {
            clientName: '충북 청주 복지센터',
            address: '충청북도 청주시 상당구 상당로 88',
          },
          {
            clientName: '전북 익산 노인복지관',
            address: '전라북도 익산시 무왕로 222',
          },
        ],
      },
    ],
  },
  {
    date: '2024-11-03',
    subRows: [
      {
        managerName: '유재석',
        subRow: [
          {
            clientName: '서울 서대문 복지센터',
            address: '서울특별시 서대문구 통일로 35',
          },
          {
            clientName: '경기도 고양 복지시설',
            address: '경기도 고양시 일산동구 호수로 123',
          },
        ],
      },
      {
        managerName: '조민수',
        subRow: [
          {
            clientName: '대구 수성구 복지관',
            address: '대구광역시 수성구 황금로 200',
          },
          {
            clientName: '울산 북구 노인복지센터',
            address: '울산광역시 북구 태화로 150',
          },
        ],
      },
      {
        managerName: '정윤호',
        subRow: [
          {
            clientName: '충남 논산 복지센터',
            address: '충청남도 논산시 오창읍 고령로 50',
          },
          {
            clientName: '전남 여수 노인복지관',
            address: '전라남도 여수시 봉강로 10',
          },
        ],
      },
      {
        managerName: '이지은',
        subRow: [
          {
            clientName: '인천 계양구 복지시설',
            address: '인천광역시 계양구 경인로 456',
          },
          {
            clientName: '경북 안동 복지관',
            address: '경상북도 안동시 만하동 78',
          },
        ],
      },
      {
        managerName: '최성훈',
        subRow: [
          {
            clientName: '충남 서산 노인복지센터',
            address: '충청남도 서산시 이매면 중앙로 100',
          },
          {
            clientName: '전북 전주 복지시설',
            address: '전라북도 전주시 완산구 풍년로 200',
          },
        ],
      },
    ],
  },
  {
    date: '2024-11-04',
    subRows: [
      {
        managerName: '홍길동',
        subRow: [
          {
            clientName: '서울 동작구 복지센터',
            address: '서울특별시 동작구 흑석로 123',
          },
          {
            clientName: '경기도 성남시 복지시설',
            address: '경기도 성남시 분당구 삼평동 99',
          },
        ],
      },
      {
        managerName: '김영희',
        subRow: [
          {
            clientName: '부산 금정구 복지관',
            address: '부산광역시 금정구 금사로 45',
          },
          {
            clientName: '인천 강화군 노인복지센터',
            address: '인천광역시 강화군 강화읍 새천원로 88',
          },
        ],
      },
      {
        managerName: '이철수',
        subRow: [
          {
            clientName: '대전 대덕구 복지센터',
            address: '대전광역시 대덕구 한밭대로 333',
          },
          {
            clientName: '경북 포항 복지시설',
            address: '경상북도 포항시 북구 대청로 200',
          },
        ],
      },
      {
        managerName: '오하늘',
        subRow: [
          {
            clientName: '서울 강동구 노인복지관',
            address: '서울특별시 강동구 천호대로 100',
          },
          {
            clientName: '경남 창원 복지센터',
            address: '경상남도 창원시 의창구 중앙로 123',
          },
        ],
      },
      {
        managerName: '유재석',
        subRow: [
          {
            clientName: '충남 태안 복지센터',
            address: '충청남도 태안군 안면읍 송산리 77',
          },
          {
            clientName: '전남 목포 노인복지관',
            address: '전라남도 목포시 유달로 50',
          },
        ],
      },
    ],
  },
  {
    date: '2024-11-05',
    subRows: [
      {
        managerName: '박미정',
        subRow: [
          {
            clientName: '서울 서초구 복지센터',
            address: '서울특별시 서초구 반포대로 200',
          },
          {
            clientName: '경기도 화성 복지시설',
            address: '경기도 화성시 동탄대로 150',
          },
        ],
      },
      {
        managerName: '정윤호',
        subRow: [
          {
            clientName: '대구 달서구 노인복지관',
            address: '대구광역시 달서구 두류로 300',
          },
          {
            clientName: '경남 양산 복지센터',
            address: '경상남도 양산시 월영로 100',
          },
        ],
      },
      {
        managerName: '김수정',
        subRow: [
          {
            clientName: '부천 오정구 복지관',
            address: '경기도 부천시 오정로 123',
          },
          {
            clientName: '인천 연수구 노인복지센터',
            address: '인천광역시 연수구 송도동 101',
          },
        ],
      },
      {
        managerName: '최성훈',
        subRow: [
          {
            clientName: '서울 중랑구 복지센터',
            address: '서울특별시 중랑구 동일로 45',
          },
          {
            clientName: '경북 포항시 남구 노인복지시설',
            address: '경상북도 포항시 남구 대도대로 222',
          },
        ],
      },
      {
        managerName: '이지은',
        subRow: [
          {
            clientName: '경남 진해 복지센터',
            address: '경상남도 창원시 마산합포구 진해대로 456',
          },
          {
            clientName: '전북 군산 노인복지관',
            address: '전라북도 군산시 소룡동 78',
          },
        ],
      },
    ],
  },
  {
    date: '2024-11-06',
    subRows: [
      {
        managerName: '유재석',
        subRow: [
          {
            clientName: '서울특별시 강서구 사회복지센터',
            address: '서울특별시 강서구 화곡로 66',
          },
          {
            clientName: '광명시 노인복지회관',
            address: '경기도 광명시 하안동 223',
          },
        ],
      },
      {
        managerName: '이지은',
        subRow: [
          {
            clientName: '성남시 중원구 복지시설',
            address: '경기도 성남시 중원구 상대원로 99',
          },
          {
            clientName: '안양시 복지관',
            address: '경기도 안양시 만안구 안양로 100',
          },
        ],
      },
      {
        managerName: '홍길동',
        subRow: [
          {
            clientName: '서울 동대문 복지센터',
            address: '서울특별시 동대문구 제기동 300',
          },
          {
            clientName: '경기도 수원 복지시설',
            address: '경기도 수원시 팔달구 팔달로 77',
          },
        ],
      },
      {
        managerName: '김영희',
        subRow: [
          {
            clientName: '부산 사하구 노인복지관',
            address: '부산광역시 사하구 낙동대로 500',
          },
          {
            clientName: '인천 남구 복지센터',
            address: '인천광역시 남구 용현로 100',
          },
        ],
      },
      {
        managerName: '이철수',
        subRow: [
          {
            clientName: '대전 중구 노인복지시설',
            address: '대전광역시 중구 은행동 150',
          },
          {
            clientName: '경북 문경 복지관',
            address: '경상북도 문경시 문경로 78',
          },
        ],
      },
    ],
  },
];
