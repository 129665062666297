import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../../api/useFetch';
import {
  itemBomListGetApi,
  ItemBomResponseType,
  ProductionCntIncrease,
  productionCntIncreaseApi,
  ProductionResponseType,
  StockHistoryResponseType,
} from '../../../api/apiConfig';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import styled from 'styled-components';
import { CustomProgressBar } from '../../../component/common/CustomProgressBar';
import { formatEightDigitStringToSixDigitDot } from '../../../function/replaceString';
import { ProductionSquareCard } from '../../../component/common/ProductionSquareCard';

type StockInfoRecordType = Record<number, StockInfoType>;

type StockInfoType = {
  itemDes: string;
  sizeDes: string;
  stockQty: number;
  stockHistoryQty: number;
  stockHistoryQtyEtc: number;
  consumedQty: number;
};

export const ProductionProgressWorker = () => {
  const param = useParams();

  const navigate = useNavigate();

  const socketData = useSelector((state: RootState) => state.socketData);

  const [increaseCount, setIncreaseCount] = useState<number>(1);

  const [stockInfo, setStockInfo] = useState<StockInfoRecordType>();

  const [productionData, setProductionData] =
    useState<ProductionResponseType | null>(null);

  const [bom, setBom] = useState<ItemBomResponseType | null>(null);

  const [filteredStockHistory, setFilteredStockHistory] = useState<
    StockHistoryResponseType[]
  >([]);

  const productionDatas = useSelector(
    (state: RootState) => state.socketData.production
  );

  console.log('bom', bom);
  console.log('filtered', filteredStockHistory);
  console.log('productiondata', productionData);

  const { fetch: fetchProductionCntIncrease } = useFetch({
    fetchFunction: productionCntIncreaseApi,
    onSuccess: () => console.log('increased'),
  });

  useEffect(() => {
    // if (socketData.stock.length < 1) return;
    // if (socketData.stockHistory.length < 1) return;
    if (!bom) return;

    console.log('sh', socketData.stockHistory);

    const stockInfo: StockInfoRecordType = bom.consumedItems.reduce(
      (acc, bomItem) => {
        const matchingStock = socketData.stock.find(
          (stock) => stock.itemId === bomItem.itemId
        );

        const targetStockHistory = socketData.stockHistory.find(
          (stockHistory) =>
            stockHistory.itemId === bomItem.itemId &&
            stockHistory.productionId === productionData?.id
              ? stockHistory
              : null
        );

        const targetStockHistoryEtc = socketData.stockHistory
          .map((stockHistory) =>
            stockHistory.itemId === bomItem.itemId ? stockHistory : null
          )
          .filter((data) => data !== null);
        console.log('bom', bom);
        console.log('socketData.stockHistory', socketData.stockHistory);
        console.log('matchingStock', matchingStock);
        console.log('targetStockHistory', targetStockHistory);
        console.log('targetStockHistoryEtc', targetStockHistoryEtc);

        if (!matchingStock) return acc; // 매칭되는 stock이 없으면 건너뜀

        acc[bomItem.itemId] = {
          itemDes: matchingStock.prodDes,
          sizeDes: matchingStock.sizeDes,
          stockQty: matchingStock.qty,
          stockHistoryQty: targetStockHistory?.changedQuantity || 0,
          stockHistoryQtyEtc: targetStockHistoryEtc?.reduce(
            (acc, data) => acc + data.changedQuantity,
            0
          ),
          consumedQty: bomItem.consumedQty, // bom의 consumedQty 가져오기
        };

        return acc;
      },
      {} as Record<number, StockInfoType>
    ); // 초기값은 빈 객체

    setStockInfo(stockInfo);

    const productionTarget =
      productionDatas.find(
        (production) => Number(production.id) === Number(param.productionId)
      ) || null;

    if (
      productionTarget?.progress === 'C' ||
      productionTarget?.progress === 'E'
    ) {
      window.alert('생산일정이 마감되었습니다. 이전페이지로 돌아갑니다.');
      navigate('/production-progress');
    }

    setProductionData(productionTarget);
  }, [socketData, bom]);

  const { fetch: fetchItemBomListGet } = useFetch({
    fetchFunction: itemBomListGetApi,
    onSuccess: (data: ItemBomResponseType) => setBom(data),
  });

  useEffect(() => {
    console.log('param', param);
    if (!param.productionId) return;
    if (!param.itemId) return;
    fetchItemBomListGet(Number(param.itemId));
  }, [param]);

  function saveHandler() {
    if (!productionData) return;

    const dto: ProductionCntIncrease = {
      productionId: productionData.id,
      increaseCount: increaseCount,
    };

    fetchProductionCntIncrease(dto);
  }

  console.log(stockInfo);

  return (
    <Container>
      <Content>
        <PageHeader>
          {/* <div style={{ width: '150px' }}></div>
        <div className='h1'>생산라인</div>
        <div className='h2' style={{ width: '150px' }}>
          2024.11.25
        </div> */}
          <h3
            style={{ width: '150px', cursor: 'pointer' }}
            onClick={() => navigate('/production-progress')}
          >
            뒤로가기
          </h3>
          <div className='h1'>
            {productionData &&
              formatEightDigitStringToSixDigitDot(productionData?.productionDt)}
          </div>
          <div style={{ width: '150px' }}></div>
        </PageHeader>

        <div className='container flex-center' style={{ marginBottom: '10px' }}>
          <PageBodyTitle className='h2'>화장지작업장</PageBodyTitle>
        </div>
        <PageBody>
          <PageBodyHalfRow widthPercentage={60}>
            <div
              style={{
                alignContent: 'center',
                textAlign: 'center',
              }}
              className='h1'
            >
              {bom?.producedItem.itemDes} / {bom?.producedItem.sizeDes}
            </div>
            <ProgressCard className='container'>
              <div
                style={{
                  width: '77%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <div className='h3'>생산현황</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '4px',
                  }}
                  className='h2'
                >
                  <ProgressRow>
                    <div className='cnt'>달성수량</div>
                    <div className='cnt'>목표수량</div>
                  </ProgressRow>
                  <ProgressRow>
                    <div className='cnt'>{productionData?.productionCnt}</div>
                    <div className='cnt'>{productionData?.productionGoal}</div>
                  </ProgressRow>
                  <ProgressRow>
                    <CustomProgressBar
                      current={
                        productionData ? productionData.productionCnt : 0
                      }
                      goal={productionData ? productionData.productionGoal : 0}
                      style={{
                        width: '100%',
                        height: '20px',
                        borderRadius: '4px',
                      }}
                    />
                  </ProgressRow>
                </div>
              </div>
              <ProgressPercent>
                {productionData
                  ? `${(
                      (productionData?.productionCnt /
                        productionData?.productionGoal) *
                      100
                    ).toFixed()}%`
                  : undefined}
              </ProgressPercent>
            </ProgressCard>
            <BomCard className='container'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div className='h2' style={{ width: '300px' }}>
                  소모품 현황
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className='stock-cell h3'>전일 재고</div>
                  <div className='stock-history-cell h3'>현재작업 변동량</div>
                  <div className='stock-history-cell h3'>총 변동량</div>
                  <div className='bom-cell h3'>현재 재고</div>
                </div>
              </div>
              {bom?.consumedItems.map((item) => (
                <BomCardRow>
                  {stockInfo && stockInfo[item.itemId] && (
                    <>
                      <div>
                        <div className='h3' style={{ width: '300px' }}>
                          {stockInfo[item.itemId].itemDes} /{' '}
                          {stockInfo[item.itemId].sizeDes}
                        </div>
                      </div>
                      <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div className='stock-cell'>
                            {stockInfo[item.itemId].stockQty}
                          </div>
                          <div className='stock-history-cell'>
                            {stockInfo[item.itemId].stockHistoryQty}
                          </div>
                          <div className='stock-history-cell'>
                            {stockInfo[item.itemId].stockHistoryQtyEtc}
                          </div>
                          <div className='bom-cell'>
                            {stockInfo[item.itemId].stockQty +
                              stockInfo[item.itemId].stockHistoryQtyEtc}
                          </div>
                        </div>
                      </div>
                      {/* <div>{stockInfo[item.itemId].stockQty}</div> */}
                    </>
                  )}
                </BomCardRow>
              ))}
            </BomCard>
          </PageBodyHalfRow>
          <PageBodyHalfRow widthPercentage={38}>
            <div
              style={{
                alignContent: 'center',
                textAlign: 'center',
              }}
              className='h1'
            >
              기타 작업 목록
            </div>
            <div
              style={{
                // maxHeight: '50%',
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              {productionDatas
                .filter(
                  (production) =>
                    production.workbayId === productionData?.workbayId
                )
                .map((production) => (
                  <div style={{ width: '100%' }}>
                    <ProductionSquareCard
                      itemName={production.item.PROD_DES}
                      itemSize={production.item.SIZE_DES}
                      workbayName={production.workbayName}
                      current={production.productionCnt}
                      goal={production.productionGoal}
                      progress={production.progress}
                    />
                  </div>
                ))}
            </div>
            {/* {(() => {
                const filteredData = productionData.filter(
                  (production) =>
                    Number(production.workbayId) === Number(workbayId)
                );
                return filteredData.length > 0 ? (
                  <GroupContainer>
                    {filteredData.map((production) => (
                      <div style={{ width: '400px' }}>
                        <ProductionSquareCard
                          itemName={production.item.PROD_DES}
                          itemSize={production.item.SIZE_DES}
                          workbayName={production.workbayName}
                          current={production.productionCnt}
                          goal={production.productionGoal}
                          progress={production.progress}
                        />
                      </div>
                    ))} */}
          </PageBodyHalfRow>
        </PageBody>
      </Content>
      <CountingButtonHolder className='h1'>
        <CountingButton onClick={() => saveHandler()}>수량 추가</CountingButton>
      </CountingButtonHolder>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  padding: 0px 20px;
  height: 100vh;
  color: ${({ theme }) => theme.colors.charcoal};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.lightGray};
  * {
    box-sizing: border-box;
  }

  .container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 6px;
  }
  .h1 {
    font-size: ${({ theme }) => theme.fontsize[27]};
    font-weight: 700;
  }
  .h2 {
    font-size: ${({ theme }) => theme.fontsize[19]};
    font-weight: 700;
  }
  .h3 {
    font-size: ${({ theme }) => theme.fontsize[16]};
    font-weight: 600;
  }
  .cnt {
    min-width: 80px;
    text-align: center;
    align-content: center;
  }
  .stock-cell {
    width: 120px;
    text-align: center;
  }
  .stock-history-cell {
    width: 140px;
  }
  .bom-cell {
    width: 120px;
  }
  .stock-cell,
  .stock-history-cell,
  .bom-cell {
    text-align: center;
    align-content: center;
  }
`;

const Content = styled.div`
  height: 85%;
  width: 100%;
  overflow: hidden;
`;

const PageHeader = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PageBody = styled.div`
  width: 100%;
  max-height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageBodyHalfRow = styled.div<{ widthPercentage: number }>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const PageBodyTitle = styled.div`
  width: 97%;
  height: 45px;
  align-self: center;
  align-content: center;
  text-align: center;
  border-bottom: 3px solid ${({ theme }) => theme.colors.green};
`;

const ProgressCard = styled.div`
  width: 100%;
  padding: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProgressRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProgressPercent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontsize[36]};
`;

const BomCard = styled.div`
  width: 100%;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BomCardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const CountingButtonHolder = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-content: center;
  justify-content: center;
  bottom: 20px;
  left: 0;
`;

const CountingButton = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  width: 95%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;
