const SET_HEADER_OPENSTATE = 'header/SET_HEADER_OPENSTATE' as const;

export interface SidebarUiStatusType {
  open: boolean;
}

export type HeaderAction = {
  type: typeof SET_HEADER_OPENSTATE;
  payload: boolean;
};

export interface HeaderState {
  open: boolean;
}

export const setHeaderOpenstate = (payload: boolean) => ({
  type: SET_HEADER_OPENSTATE,
  payload: payload,
});

const initialState: HeaderState = {
  open: true,
};

const headerReducer = (
  state: HeaderState = initialState,
  action: HeaderAction
) => {
  switch (action.type) {
    case SET_HEADER_OPENSTATE:
      return {
        ...state,
        open: action.payload,
      };

    default:
      return state;
  }
};

export default headerReducer;
