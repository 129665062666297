import './App.css';
import TestPage from './TestPage';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import Sidebar from './Sidebar/Sidebar';
import Table from './component/table/Table';
import { HeaderButtonProvider } from './component/header/HeaderButtonProvider';
import Header from './component/header/Header';
import { Route, Routes } from 'react-router-dom';
import OrderListPage from './pages/OrderListPage';
import TraderListTable from './component/table/TableComponents/TraderListTable';
import DashBoardPage from './pages/DashBoardPage';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <HeaderButtonProvider>
        {/* 임시 */}
        <div
          style={{
            width: '100vw',
            minHeight: '100vh',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
          }}
        >
          <Sidebar />
          <div
            style={{
              width: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              overflowY: 'scroll',
            }}
          >
            <Routes>
              <Route
                path='/'
                element={
                  <div
                    style={{
                      overflowX: 'scroll',
                      minHeight: '100%',
                      padding: '20px',
                      backgroundColor: theme.colors.lightGray,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TraderListTable />
                  </div>
                }
              />
              <Route path='order-list' element={<OrderListPage />} />
              <Route path='dash-board' element={<DashBoardPage />} />
            </Routes>
          </div>
        </div>
      </HeaderButtonProvider>
    </>
  );
}

export default App;
