// lib
import React from 'react';
import styled from 'styled-components';

// component
import DashBoardTemplete from '../component/dashBoard/DashBoardTemplete';

// icons
import { FiRefreshCw } from 'react-icons/fi';
import WeeklyProductionChart from '../component/dashBoard/WeeklyProductionChart';

const Menus = [
  {
    title: '품목 생산 비율',
    component: <></>,
  },
  {
    title: '공지사항',
    component: <></>,
  },
  {
    title: '주간 생산량',
    component: <WeeklyProductionChart />,
  },
  {
    title: '직접배송현황',
    component: <></>,
  },
  {
    title: '금일 생산현황',
    component: <></>,
  },
  {
    title: '결제정보',
    component: <></>,
  },
  {
    title: '서비스 새 소식',
    component: <></>,
  },
  {
    title: '신규서비스',
    component: <></>,
  },
  {
    title: '프로모션',
    component: <></>,
  },
];
const DashBoardPage = () => {
  const handleRefresh = () => {
    console.log('새로고침');
  };

  return (
    <Container>
      <Header>
        <ResetButton onClick={handleRefresh}>
          <FiRefreshCw color="#4c4f4c" />
          새로고침
        </ResetButton>
      </Header>

      <Content>
        {Menus.map((menu, index) => (
          <DashBoardTemplete key={index} title={menu.title}>
            {menu.component}
          </DashBoardTemplete>
        ))}
      </Content>
    </Container>
  );
};

export default DashBoardPage;

const Container = styled.div`
  flex: 1;
`;

const Header = styled.div`
  padding: 10px 30px;
`;

const ResetButton = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
  color: #222;
  font-weight: 600;
  cursor: pointer;
`;

const Content = styled.div`
  background-color: #edeef1;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 15px;
`;
