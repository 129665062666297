import React, { useState, useEffect } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import styled from 'styled-components';
import { useTheme } from 'styled-components';

interface ProgressBarProps {
  current: number; // 현재 값
  goal: number; // 목표 값
  style?: React.CSSProperties;
}

export const CustomProgressBar: React.FC<ProgressBarProps> = ({
  current,
  goal,
  style,
}) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // 현재 값에 따른 비율 계산
    const percentage = (current / goal) * 100;
    setProgress(percentage);
  }, [current, goal]);

  return (
    <div style={{ position: 'relative', overflow: 'hidden', ...style }}>
      <ProgressBarPositionFixer>
        <ProgressBar
          completed={progress}
          customLabelStyles={{ display: 'none' }}
          bgColor={theme.colors.lightBlue} // 목표 초과 시 빨간색, 아니면 초록색
          height='100%'
          width='100%'
          borderRadius='0px'
          transitionDuration='0.2s'
        />
      </ProgressBarPositionFixer>
      {progress > 100 && (
        <ProgressBarPositionFixer>
          <ProgressBar
            completed={progress - 100}
            bgColor='#72fd5f' // 초과 부분은 빨간색
            height='100%'
            width='100%'
            transitionDuration='0.2s'
          />
        </ProgressBarPositionFixer>
      )}
    </div>
  );
};

const ProgressBarPositionFixer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
