import styled, { useTheme } from 'styled-components';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import { useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  StockHistoryResponseType,
  StockResponseType,
} from '../../../api/apiConfig';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/modules';
import * as Styled from '../../../component/table/TableStyled';
import TableConfig from '../../../component/table/TableConfig';

type TableDataType = {
  itemId: number;
  prodCd: string;
  prodDes: string;
  qty: number;
  sizeDes: string;
  stockId: number;
  timeDate: string;
  changedQuantity: number;
};

export const Stock = () => {
  const { setButtons } = useHeaderButtons();

  const theme = useTheme();

  //테이블 옵션값 ~~~
  const [headerOverflowType, setHeaderOverflowType] = useState<'wrap' | 'hide'>(
    'wrap'
  );
  const [bodyOverflowType, setBodyOverflowType] = useState<'wrap' | 'hide'>(
    'wrap'
  );
  const [bodyAlignHoriType, setBodyAlignHoriType] = useState<
    'left' | 'center' | 'right'
  >('center');

  const [showConfig, setShowConfig] = useState<boolean>(false);

  const [cellHoriPaddValue, setCellHoriPaddValue] = useState<number>(5);
  const [bodyVertPaddValue, setBodyVertPaddValue] = useState<number>(5);
  //~~테이블 옵션값

  const stockData: StockResponseType[] = useSelector(
    (state: RootState) => state.socketData.stock
  );

  const stockHistoryData: StockHistoryResponseType[] = useSelector(
    (state: RootState) => state.socketData.stockHistory
  );

  const [data, setData] = useState<TableDataType[]>([]);

  useEffect(() => {
    if (stockData.length < 1) return;
    const combinedData = stockData.map((stock) => {
      const targetStockHistory: StockHistoryResponseType[] =
        stockHistoryData.filter(
          (stockHistory) => stockHistory.stockId === stock.stockId
        );
      const data: TableDataType = {
        itemId: stock.itemId,
        prodCd: stock.prodCd,
        prodDes: stock.prodDes,
        qty: stock.qty,
        sizeDes: stock.sizeDes,
        stockId: stock.stockId,
        timeDate: stock.timeData,
        changedQuantity: targetStockHistory
          .map((history) => history.changedQuantity)
          .reduce((sum, quantity) => sum + quantity, 0),
      };
      return data;
    });
    setData(combinedData);
  }, [stockData, stockHistoryData]);

  const columns = useMemo<ColumnDef<TableDataType>[]>(
    () => [
      {
        header: '품목명',
        accessorKey: 'prodDes',
        size: 140,
      },
      {
        header: '규격명',
        accessorKey: 'sizeDes',
        size: 140,
      },
      {
        header: '재고수량',
        accessorKey: 'qty',
        size: 48,
      },
      {
        header: '금일변동수량',
        accessorKey: 'changedQuantity',
        size: 48,
        cell: ({ getValue, row, column, cell }) => {
          const value = getValue() as number; // getValue()가 number 타입임을 명시적으로 지정
          const style: React.CSSProperties = {
            backgroundColor:
              value < 0
                ? theme.colors.softRed
                : value > 0
                ? theme.colors.softGreen
                : 'transparent',
            color: theme.colors.charcoal,
            width: '100%',
            height: '100%',

            position: 'absolute',
            textAlign: 'center',
            alignContent: 'center',
            // padding은 이미 다른 곳에서 지정된 경우 필요 없을 수 있음
          };

          // 기존 cell 스타일을 확장하여 반환
          return <div style={{ ...style }}>{value}</div>;
        },
      },
      {
        header: '최종수량',
        accessorFn: (row) => row.qty + row.changedQuantity,
        size: 48,
      },
    ],
    []
  );

  const table = useReactTable({
    data: data,
    columns,
    columnResizeMode: 'onChange',
    rowCount: 99,
    getCoreRowModel: getCoreRowModel(),
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  return (
    <Styled.TableContainer>
      {/* <Styled.TableOptionButton onClick={() => setShowConfig(!showConfig)}>
        설정
      </Styled.TableOptionButton> */}
      <TableConfig
        show={showConfig}
        Header_Overflow_Type={headerOverflowType}
        setHeaderOverflowType={(value: 'hide' | 'wrap') =>
          setHeaderOverflowType(value)
        }
        Body_Overflow_Type={bodyOverflowType}
        setBodyOverflowType={(value: 'hide' | 'wrap') =>
          setBodyOverflowType(value)
        }
        Body_Align_Hori_Type={bodyAlignHoriType}
        setBodyAlignHoriType={(value: 'left' | 'center' | 'right') =>
          setBodyAlignHoriType(value)
        }
        Cell_Hori_Padd_Value={cellHoriPaddValue}
        setCellHoriPaddValue={(value: number) => setCellHoriPaddValue(value)}
        Body_Vert_Padd_Value={bodyVertPaddValue}
        setBodyVertPaddValue={(value: number) => setBodyVertPaddValue(value)}
      />
      <Styled.TableDiv
        {...{
          style: {
            ...columnSizeVars,
            width: table.getTotalSize(),
          },
        }}
        headOverflowType={headerOverflowType}
        bodyOverflowType={bodyOverflowType}
        bodyAlignHoriType={bodyAlignHoriType}
        cellHoriPaddValue={cellHoriPaddValue}
        bodyVertPaddValue={bodyVertPaddValue}
      >
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <Styled.ThDiv {...{ key: headerGroup.id }}>
              {headerGroup.headers.map((headerCell) =>
                headerCell.isPlaceholder ? null : (
                  <Styled.ThCell
                    {...{
                      key: headerCell.id,
                      style: {
                        width: `calc(var(--header-${headerCell?.id}-size) * 1px)`,
                      },
                      className: 'th',
                    }}
                  >
                    {headerCell.isPlaceholder
                      ? null
                      : flexRender(
                          headerCell.column.columnDef.header,
                          headerCell.getContext()
                        )}
                    <Styled.ResizeHandler
                      {...{
                        onMouseDown: headerCell.getResizeHandler(),
                        className: `resizer ${
                          headerCell.column.getIsResizing() ? 'isResizing' : ''
                        }`,
                      }}
                    />
                  </Styled.ThCell>
                )
              )}
            </Styled.ThDiv>
          );
        })}
        {table.getRowModel().rows.map((row) => (
          <>
            <Styled.TdDiv
              {...{
                key: row.id,
              }}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <Styled.TdCell
                    {...{
                      key: cell.id,
                      className: 'td',
                      style: {
                        width: `calc(var(--header-${cell.column.id}-size) * 1px)`,
                      },
                    }}
                  >
                    {flexRender(
                      // cell.column.columnDef.aggregatedCell ??
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </Styled.TdCell>
                );
              })}
            </Styled.TdDiv>
          </>
        ))}
      </Styled.TableDiv>
    </Styled.TableContainer>
  );
};
const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: ${({ theme }) => theme.fontsize[15]};
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 600;
  padding: 5px;
`;
