// HeaderButtonContext.tsx
import React, { createContext, useContext, useState } from "react";

//용법
//각 페이지 컴포넌트에서
// useEffect(() => {
//     setButtons(
//       <>
//         <button>Save Order</button>
//         <button>Cancel</button>
//       </>
//     );
//     // 페이지가 바뀌면 버튼 초기화
//     return () => setButtons(null);
//   }, [setButtons]);

const HeaderButtonContext = createContext<any>(null);

export const useHeaderButtons = () => {
  return useContext(HeaderButtonContext);
};

export const HeaderButtonProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [buttons, setButtons] = useState<React.ReactNode>(null);

  return (
    <HeaderButtonContext.Provider value={{ buttons, setButtons }}>
      {children}
    </HeaderButtonContext.Provider>
  );
};
