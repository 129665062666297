import styled, { css } from 'styled-components';
import * as Styled from './SidebarComp';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/image/logo.png';
import {
  SidebarAdminMajorList as SidebarMajorList,
  SidebarAdminMinorList as SidebarMinorList,
} from '../@const/sidebarMenu';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/modules';
import { BiArrowFromRight, BiArrowFromLeft } from 'react-icons/bi';
import {
  setSidebarOpenedCategories,
  setSidebarUiStatus,
  SidebarState,
  toggleSidebar,
} from '../store/modules/sidebar';

export const SidebarAdmin = () => {
  const dispatch = useDispatch();

  const openSidebar = useSelector(
    (state: RootState) => state.sidebar.uiStatus.open
  );

  const openKey: number[] = useSelector(
    (state: RootState) => state.sidebar.uiStatus.openKey
  );

  const onClickMajor = (key: number) => {
    // 서브 메뉴가 없을 경우 페이지 이동
    console.log(SidebarMinorList[key]);
    if (!SidebarMinorList[key] || SidebarMinorList[key].length === 0) {
      const targetPath = SidebarMajorList.find(
        (major) => major.key === key
      )?.path;
      targetPath && navigate(targetPath);
    }
    // 서브 메뉴가 있을경우 서브메뉴 토글
    else {
      const updatedOpenKey = openKey.includes(key)
        ? openKey.filter((existKey) => existKey !== key)
        : [...openKey, key]; // index가 포함되어 있지 않다면 추가  };

      dispatch(setSidebarOpenedCategories(updatedOpenKey));
    }
  };

  console.log(openSidebar);
  const [activates, setActivates] = useState<number[]>([]);
  const navigate = useNavigate();

  // 대분류 카테고리 열림 여부
  const handleCategoryClick = (idx: number) => {
    setActivates((prev) =>
      prev.includes(idx)
        ? prev.filter((activeIdx) => activeIdx !== idx)
        : [...prev, idx]
    );
  };

  // 특정 메뉴 클릭 시 이동할 경로 설정
  const handleMenuItemClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {}, [activates]);

  return (
    <Styled.Container>
      <Styled.Sidebar open={openSidebar}>
        <Styled.HeaderImgDiv
          onClick={() => {
            navigate('/');
          }}
        >
          <img src={logo} alt='Logo' style={{ width: '87%' }} />
        </Styled.HeaderImgDiv>
        <h2 className='flex-center'>관리자메뉴</h2>
        {SidebarMajorList.map((major, idx) => {
          const key = major.key;
          return (
            <div key={idx}>
              <Styled.Menu onClick={() => onClickMajor(key)}>
                {major.name} {/* 메뉴 이름 표시 */}
              </Styled.Menu>
              {SidebarMinorList[key] && SidebarMinorList[key].length > 0 && (
                <Styled.MenuItemWrapper
                  show={openKey.includes(key)}
                  itemLength={SidebarMinorList[key].length}
                >
                  {SidebarMinorList[key].map((minor, idxMinor) => (
                    <Styled.MenuItem
                      key={idxMinor}
                      onClick={() => handleMenuItemClick(minor.path)} // 클릭 시 이동 경로 설정
                    >
                      {minor.name} {/* 서브메뉴 이름 표시 */}
                    </Styled.MenuItem>
                  ))}
                </Styled.MenuItemWrapper>
              )}
            </div>
          );
        })}
      </Styled.Sidebar>
      <Styled.ContainerFlipper onClick={() => dispatch(toggleSidebar())}>
        {openSidebar ? <BiArrowFromRight /> : <BiArrowFromLeft />}
      </Styled.ContainerFlipper>
    </Styled.Container>
  );
};
