import styled, { css, useTheme } from 'styled-components';
import * as Styled from '../../anonymouse/pages/LoginPageStyled';
import {
  productionDaylistFindApi,
  ProductionExistDayReqType,
  productionListFindApi,
  productionPostApi,
  productionProgressUpdatePostApi,
  ProductionProgressUpdateReqType,
  ProductionResponseType,
  ProductionType,
  ProductItem,
  productItemListGetApi,
  workbayListGetApi,
  WorkbayType,
} from '../../../api/apiConfig';
import useFetch from '../../../api/useFetch';
import { useEffect, useRef, useState } from 'react';
import { BiWindowClose } from 'react-icons/bi';
import { BiSolidLeftArrow } from 'react-icons/bi';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useHeaderButtons } from '../../../component/header/HeaderButtonProvider';
import SquareButton from '../../../component/button/SquareButton';
import {
  getYearMonthDateFromDate,
  removeDashFromString,
  splitYYYYMMDD,
} from '../../../function/replaceString';
import { BiCheckboxMinus } from 'react-icons/bi';

export const ProductionAdd = () => {
  const theme = useTheme();
  const { setButtons } = useHeaderButtons();
  //작업장리스트 변수
  const [workbayList, setWorkbayList] = useState<WorkbayType[]>([]);
  //제품 리스트
  const [productItemList, setProductItemList] = useState<ProductItem[]>([]);
  //제품 검색어 변수
  const [productSearchTerm, setProductSearchTerm] = useState<string>('');
  //선택된 작업장
  const [selectedWorkbay, setSelectedWorkbay] = useState<WorkbayType>();
  //선택 날짜/작업장에 존재하는 생산테이블 리스트
  const [productionList, setProductionList] = useState<
    ProductionResponseType[]
  >([]);

  const [minDay, setMinDay] = useState<string | null>(null);

  //삭제예정
  const [productionDate, setProductionDate] = useState<string>();

  const [prdExistingDaylist, setPrdExistingDaylist] = useState<string[]>([]);

  //생산작업 차수
  const [phase, setPhase] = useState<number>(1);

  //생산 진행상태  W:대기중, P:진행중, E:조기마감, C:마감
  const [progress, setProgress] = useState<'W' | 'P'>('W');

  //선택된 품목리스트 및 각각 목표수량
  const [selectedItemData, setSelectedItemData] = useState<
    {
      item: ProductItem;
      goal: number;
    }[]
  >([]);
  //작업장 드롭다운 관리
  const [workbayDropdownOpen, setWorkbayDropdownOpen] =
    useState<boolean>(false);
  //아이템 드롭다운 관리
  const [productItemDropdownOpen, setProductItemDropdownOpen] =
    useState<boolean>(false);
  //작업장 드롭다운 외부클릭 감지용
  const workbayDropdownRef = useRef(null);
  //아이템 드롭다운 외부클릭 감지용
  const itemDropdownRef = useRef(null);
  //작업장 리스트 조회 api
  const { fetch: fetchGetWorkbayList } = useFetch({
    fetchFunction: workbayListGetApi,
    onSuccess: (data: WorkbayType[]) => setWorkbayList(data),
  });

  //품목중 제품들만 리스트 조회
  const { fetch: fetchGetProductItemList } = useFetch({
    fetchFunction: productItemListGetApi,
    onSuccess: (data: ProductItem[]) => setProductItemList(data),
  });

  //생산 저장
  const { fetch: fetchPostProduction } = useFetch({
    fetchFunction: productionPostApi,
    onSuccess: () => {
      window.alert('저장되었습니다');
      window.location.reload();
    },
  });

  //생산일정 존재 날짜 조회
  const { fetch: fetchPrdDaylistFind } = useFetch({
    fetchFunction: productionDaylistFindApi,
    onSuccess: (data: string[]) => setPrdExistingDaylist(data),
  });

  //생산일정 조회
  const { fetch: fetchProductionListFindApi } = useFetch({
    fetchFunction: productionListFindApi,
    onSuccess: (data: ProductionResponseType[]) => setProductionList(data),
  });

  //생산일정 진행상태 업데이트
  const { fetch: fetchProductionProgressUpdatePost } = useFetch({
    fetchFunction: productionProgressUpdatePostApi,
    //응답이 온 차수 + 1로 변경
    onSuccess: (data: ProductionResponseType[]) => {
      setPhase(data[0].productionPhase + 1);
      setProductionList([]);
    },
  });

  useEffect(() => {
    fetchGetWorkbayList();
    fetchGetProductItemList();
    const defaultDate = new Date();
    const dateString = getYearMonthDateFromDate(defaultDate);
    const nowDateString =
      dateString.yearString +
      '-' +
      dateString.monthString +
      '-' +
      dateString.dayString;
    //최소값
    setMinDay(nowDateString);
    //기본값
    setProductionDate(nowDateString);
  }, []);

  const WorkbayDropdownBox = () => {
    return (
      <>
        {workbayList.length > 0 ? (
          workbayList.map((workbay) => (
            <DropdownItem
              onClick={() => {
                setSelectedWorkbay(workbay);
                setWorkbayDropdownOpen(!workbayDropdownOpen);
              }}
              included={
                selectedWorkbay !== undefined &&
                selectedWorkbay.id === workbay.id
              }
            >
              {workbay.workbayName}
            </DropdownItem>
          ))
        ) : (
          <div>작업장 정보가 없습니다</div>
        )}
      </>
    );
  };

  function goalCountChangeHandler(
    targetIndex: number,
    type: 'decrease' | 'increase' | 'none',
    value?: number
  ) {
    setSelectedItemData((prev) =>
      prev.map((item, index) =>
        index === targetIndex
          ? {
              ...item,
              goal:
                value !== undefined
                  ? value
                  : type === 'decrease'
                  ? item.goal - 1
                  : item.goal + 1,
            }
          : item
      )
    );
  }

  function itemRemoveHandler(targetIndex: number) {
    setSelectedItemData((prev) =>
      prev.filter((data, index) => index !== targetIndex)
    );
  }

  function updateProgress() {
    const dto: ProductionProgressUpdateReqType = {
      targetDate: removeDashFromString(productionDate!),
      workbayId: selectedWorkbay!.id,
      progress: 'E', //조기마감
    };
    fetchProductionProgressUpdatePost(dto);
  }

  useEffect(() => {
    if (productionDate && selectedWorkbay) {
      const removeDash = removeDashFromString(productionDate);
      const split = splitYYYYMMDD(removeDash);
      const dto: ProductionExistDayReqType = {
        targetYear: split.year,
        targetMonth: split.month,
        targetDay: split.day,
        targetWorkbayId: selectedWorkbay.id,
      };

      fetchProductionListFindApi(dto);
    }
  }, [productionDate, selectedWorkbay]);

  console.log(productionList);

  console.log(selectedItemData);

  useEffect(() => {
    if (productionList.length === 0) return;
    if (productionList[0].progress === 'P') {
      const userConfirmed = window.confirm(
        `해당일자에 진행중인 생산현황이 존재합니다.\n마감 후 새로운 차수로 생성하시겠습니까?`
      );

      if (userConfirmed) {
        updateProgress();
      } else {
        setSelectedWorkbay(undefined);
        setProductionList([]);
      }
    }
    if (productionList[0].progress === 'W') {
      window.alert(`해당일자에 진행 대기중인 생산현황을 불러왔습니다`);

      setSelectedItemData(
        productionList.map((production) => {
          return {
            item: production.item,
            goal: production.productionGoal,
          };
        })
      );

      setProductionList([]);
    }
  }, [productionList]);

  useEffect(() => {
    setButtons(
      <>
        <SquareButton
          colorType='ACTIVE'
          text='생산 등록'
          onClick={() => saveHandler()}
        />
      </>
    );
    // 페이지가 바뀌면 버튼 초기화
    return () => setButtons(null);
  }, [setButtons, productionDate, selectedWorkbay, selectedItemData]);

  const saveHandler = () => {
    console.log('productionDate', productionDate);
    console.log(selectedWorkbay!);
    if (!productionDate) {
      window.alert('생산일자를 선택해주세요');
      return;
    }
    if (!selectedWorkbay) {
      window.alert('작업장 정보를 선택해주세요');
      return;
    }

    const items: { itemId: number; goal: number }[] = selectedItemData.map(
      (item) => ({
        itemId: item.item.id,
        goal: item.goal,
      })
    );

    if (items.find((item) => item.goal < 1)) {
      window.alert('목표수량을 설정해주세요');
      return;
    }

    if (!items || items.length === 0) {
      window.alert('선택된 생산품목이 없습니다');
      return;
    }
    const dto: ProductionType = {
      productionDt: removeDashFromString(productionDate),
      workbayId: selectedWorkbay.id,
      progress: 'W',
      phase: 1,
      items: items,
    };

    fetchPostProduction(dto);
  };

  return (
    <Container>
      <InputSection>
        <InputRow>
          <div>날짜</div>
          <InputStyler>
            <Styled.Input
              type='date'
              value={productionDate}
              onChange={(e) => {
                setProductionDate(e.target.value);
              }}
              min={minDay ? minDay : undefined}
            />
          </InputStyler>
          <div>{`${phase} 차`}</div>
        </InputRow>
        <InputRow style={{ position: 'relative' }}>
          <div>작업장</div>
          <div
            onClick={() => setWorkbayDropdownOpen(!workbayDropdownOpen)}
            style={{ cursor: 'pointer' }}
          >
            {(selectedWorkbay && selectedWorkbay.workbayName) || '선택'}
          </div>

          <WorkbayDropdownContainer show={workbayDropdownOpen}>
            <WorkbayDropdownBox />
          </WorkbayDropdownContainer>
        </InputRow>
        <InputRow style={{ position: 'relative' }}>
          <div>품목추가</div>
          <div
            onClick={() => setProductItemDropdownOpen(!productItemDropdownOpen)}
            style={{ cursor: 'pointer' }}
          >
            추가하기
          </div>

          <DropdownBase show={productItemDropdownOpen}>
            <ProductDropdownHeader>
              <div
                style={{
                  height: '26px',
                  backgroundColor: '#1f48d4',
                  marginBottom: '3px',
                  textAlign: 'end',
                  alignContent: 'center',
                  paddingRight: '5px',
                }}
              >
                <BiWindowClose
                  size={20}
                  color='white'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setProductItemDropdownOpen(false)}
                />
              </div>
              <div style={{ padding: '3px', display: 'flex' }}>
                <Styled.Input
                  placeholder='제품명 검색'
                  value={productSearchTerm}
                  style={{ marginBottom: '5px', maxHeight: '18px' }}
                  onChange={(e) => setProductSearchTerm(e.target.value)}
                />
              </div>
            </ProductDropdownHeader>
            <ProductDropdownBody>
              {productItemList.length > 0 ? (
                productItemList
                  .filter((product) =>
                    productSearchTerm?.trim()
                      ? product.PROD_DES.includes(productSearchTerm)
                      : true
                  )
                  .map((product) => (
                    <DropdownItem
                      key={product.PROD_DES} // 혹은 적절한 유일 식별자 사용
                      onClick={() => {
                        !selectedItemData.some(
                          (data) => data.item.id === product.id
                        ) &&
                          setSelectedItemData((prev) => [
                            ...prev,
                            { item: product, goal: 0 },
                          ]);
                      }}
                      included={selectedItemData.some(
                        (data) => data.item.id === product.id
                      )}
                    >
                      {product.PROD_DES}
                    </DropdownItem>
                  ))
              ) : (
                <div>작업장 정보가 없습니다</div>
              )}
            </ProductDropdownBody>
          </DropdownBase>
        </InputRow>
      </InputSection>
      <ItemSection>
        <HeaderList>
          <RemoveButtonCell style={{ border: 'none' }} />
          <ProdnameCell>제품명</ProdnameCell>
          <ProdnameCell>규격정보</ProdnameCell>
          <GoalCell>목표수량</GoalCell>
        </HeaderList>
        {selectedItemData.length > 0 &&
          selectedItemData.map((data, index) => (
            <ItemList>
              <RemoveButtonCell
                style={{ cursor: 'pointer' }}
                onClick={() => itemRemoveHandler(index)}
              >
                <BiCheckboxMinus size={26} color={theme.colors.wine} />
              </RemoveButtonCell>
              <ProdnameCell>{data.item.PROD_DES}</ProdnameCell>
              <ProdsizeCell>{data.item.SIZE_DES}</ProdsizeCell>
              <GoalCell>
                <div
                  style={{ flex: 1, textAlign: 'start', cursor: 'pointer' }}
                  onClick={() =>
                    data.goal > 0 && goalCountChangeHandler(index, 'decrease')
                  }
                >
                  <BiSolidLeftArrow />
                </div>
                <input
                  value={data.goal}
                  onChange={(e) => {
                    let value = e.target.value.trim();
                    // 숫자만 남도록 필터링
                    value = value.replace(/[^0-9]/g, ''); // 숫자 외 문자 제거

                    // 앞의 '0' 제거
                    value = value.replace(/^0+/, '');

                    // 빈 값이면 0으로 설정
                    if (value === '' || value === null) {
                      goalCountChangeHandler(index, 'none', 0);
                    } else {
                      goalCountChangeHandler(index, 'none', Number(value));
                    }
                  }}
                  type='text'
                  style={{ width: '40px', textAlign: 'center' }}
                />
                <div
                  style={{ flex: 1, textAlign: 'end', cursor: 'pointer' }}
                  onClick={() => goalCountChangeHandler(index, 'increase')}
                >
                  <BiSolidRightArrow />
                </div>
              </GoalCell>
            </ItemList>
          ))}
      </ItemSection>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: ${({ theme }) => theme.fontsize[15]};
  color: ${({ theme }) => theme.colors.charcoal};
  font-weight: 600;
  padding: 5px;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ItemSection = styled.div`
  padding: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const ItemList = styled.div`
  display: flex;

  padding: 5px 0px;
  flex: 1;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  * {
    box-sizing: border-box;
  }
`;

const HeaderList = styled(ItemList)`
  div {
    border: 1px solid black;
  }
`;

const CellBase = styled.div`
  overflow: wrap;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const RemoveButtonCell = styled(CellBase)`
  width: 40px;
`;

const ProdnameCell = styled(CellBase)`
  width: 200px;
`;

const ProdsizeCell = styled(CellBase)`
  width: 200px;
`;

const GoalCell = styled(CellBase)`
  width: 100px;
`;

const InputRow = styled.div`
  width: fit-content;
  display: flex;
  flex: 0;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

const DropdownBase = styled.div<{ show: boolean }>`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  background-color: white;
  left: 100%;
  margin-left: 15px;
  top: 0px;
  z-index: 10;
  box-shadow: 0 2px 8px rgba(71, 71, 71, 0.678);
  transform: ${({ show }) => (show ? 'scale(1)' : 'scale(0)')};
  transform-origin: top left;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
`;

const WorkbayDropdownContainer = styled(DropdownBase)`
  padding: 5px;
  gap: 6px;
  width: 150px;
`;

const ProductDropdownBody = styled.div`
  position: relative;
  gap: 6px;
  margin: 7px;
  display: flex;
  flex-direction: column;
  width: 220px;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 350px;
  max-height: 500px;
  background-color: ${({ theme }) => theme.colors.whiteGray};
`;

const ProductDropdownHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DropdownItem = styled.div<{ included: boolean }>`
  width: 100%;
  padding: 4px 1px;
  ${({ included }) =>
    included
      ? css`
          background-color: ${({ theme }) => theme.colors.lime};
        `
      : css`
          cursor: pointer;
          &:hover {
            background-color: ${({ theme }) => theme.colors.lightGray};
          }
        `}
`;

const InputStyler = styled.div`
  input {
    font-size: ${({ theme }) => theme.fontsize[13]};
    font-weight: 600;
  }
`;
