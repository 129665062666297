import styled from 'styled-components';
import {
  OrderListUpdateRequestDto,
  TraderType,
} from '../../../../api/apiConfig';
import ModalBase from '../ModalBase';
import { BiWindowClose } from 'react-icons/bi';
import { BsFiletypeXls } from 'react-icons/bs';
import { BsFiletypeXlsx } from 'react-icons/bs';
import { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { OrderListType } from '../../../../api/apiConfig';
import {
  removeSlashFromDateString,
  replaceOrderUploadserno,
} from '../../../../function/replaceString';
import toast from 'react-hot-toast';

type ParcelUploadModalType = {
  onClose: () => void;
  onSelect: (items: ParcelOriginType[]) => void;
};

export type ParcelOriginType = {
  orderId: string;
  postCd: string;
  timeDate: string;
  progress: string;
  custName: string;
  custCd: string;
  address: string;
  addressDetail: string;
  receiverInfo: string;
  receiverTelNo: string;
  empName: string;
  prodCd: string;
  prodDes: string;
  sizeDes: string;
  qty: number;
  deliveryRemarks: string;
};

export const ParcelUploadModal = ({
  onClose,
  onSelect,
}: ParcelUploadModalType) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const [originFileName, setOriginFileName] = useState<string>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [originData, setOrigintData] = useState<ParcelOriginType[]>([]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange(files[0]);
    }
  };

  const handleFileChange = (file: File) => {
    // if (!e.target.files || e.target.files.length === 0) {
    //   return;
    // }

    // const file = e.target.files[0];
    setOriginFileName(file.name);

    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target) return;

      // 헤더의 행 인덱스를 찾기 위한 배열
      const headerKeywords = [
        '일자-No.',
        // '주소1 우편번호',
        '납기일자',
        '진행상태',
        '거래처명',
        '거래처코드',
        '배송지주소',
        '수령인 정보',
        '수령인 연락처',
        '배송지주소 상세',
        '담당자명',
        '품목코드',
        '품목명',
        '규격명',
        '수량',
        '배송특이사항',
      ];

      // 헤더 인덱스 선언
      let headerIndex = -1;

      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // 첫 번째 시트
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // 엑셀 데이터를 JSON으로 변환. header:1을 사용하여 헤더지정 없이 초기에는 모든 데이터를 배열로 가져옴
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      for (let i = 0; i < jsonData.length; i++) {
        const row = jsonData[i];
        if (
          headerKeywords.some(
            (keyword) => Array.isArray(row) && row.includes(keyword)
          )
        ) {
          headerIndex = i;
          break;
        }
      }

      if (headerIndex === -1) {
        console.log('header error');
        return;
      }

      // 헤더와 데이터를 분리
      const [headers, ...rows] = jsonData.slice(headerIndex);

      if (Array.isArray(headers)) {
        const mappedHeaders = headers.map((header) => {
          switch (header) {
            case '일자-No.':
              return 'orderId';
            // case '주소1 우편번호':
            //   return 'postCd';
            case '납기일자':
              return 'timeDate';
            case '진행상태':
              return 'progress';
            case '거래처명':
              return 'custName';
            case '거래처코드':
              return 'custCd';
            case '수령인 정보':
              return 'receiverInfo';
            case '수령인 연락처':
              return 'receiverTelNo';
            case '배송지주소':
              return 'address';
            case '배송지주소 상세':
              return 'addressDetail';
            case '담당자명':
              return 'empName';
            case '품목코드':
              return 'prodCd';
            case '품목명':
              return 'prodDes';
            case '규격명':
              return 'sizeDes';
            case '수량':
              return 'qty';
            case '배송특이사항':
              return 'deliveryRemarks';
            default:
              return null;
          }
        });

        const formattedData = rows
          .filter((row) => {
            if (Array.isArray(row)) {
              const orderId = row[mappedHeaders.indexOf('orderId')];
              const custCd = row[mappedHeaders.indexOf('custCd')];
              const prodCd = row[mappedHeaders.indexOf('prodCd')];
              return orderId && custCd && prodCd; // 둘 다 값이 존재할 경우에만
            }
            return false; // 배열이 아닌 경우 false
          })
          .map((row) => {
            if (Array.isArray(row)) {
              // 각 행을 객체로 변환
              return row.reduce(
                (acc, value, index) => {
                  //acc : row(row array)
                  //value : cell value(화장지)
                  //key : key value(PROD_DES ...)
                  const key = mappedHeaders[index];
                  if (key) {
                    //우편번호는 주소검색api를 통해 입력
                    if (value) {
                      acc[key] = value;
                    }

                    delete acc[index]; //기존 숫자인덱스는 삭제
                  }

                  return acc;
                },
                { postCd: '' }
              );
            }
            return;
          });
        console.log(formattedData);
        setOrigintData(formattedData);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <ModalBase>
      <Container>
        <HeaderSection>
          거래처등록
          <HeaderButtonSection>
            <div
              className='button'
              onClick={() => {
                if (originData) {
                  onSelect(originData);
                  onClose();
                } else {
                  console.log('엑셀정보 없음');
                }
              }}
            >
              업로드
            </div>
            <BiWindowClose
              size={20}
              className='button'
              onClick={() => {
                onClose();
              }}
            />
          </HeaderButtonSection>
        </HeaderSection>
        <UploadSection
          isDragOver={isDragOver}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <IconSection>
            <BsFiletypeXls size={42} color='var(--green)' />
            <BsFiletypeXlsx size={42} color='var(--green)' />
          </IconSection>
          <div style={{ height: '30px' }} />
          <Title>파일을 드래그하여 업로드합니다.</Title>
          <div style={{ height: '30px' }} />
          <SubTitle>
            혹은, 탐색기에서{' '}
            <strong
              style={{
                textDecoration: 'underline',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() =>
                fileInputRef.current && fileInputRef.current.click()
              }
            >
              직접 선택
            </strong>
            합니다.
          </SubTitle>
          {originFileName && (
            <SubTitle style={{ marginTop: '8px' }}>
              선택된 파일:{originFileName}
            </SubTitle>
          )}
          <input
            type='file'
            ref={fileInputRef}
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) {
                return;
              }
              handleFileChange(e.target.files[0]);
            }}
            style={{ display: 'none' }}
            accept='.xls, .xlsx'
          />
        </UploadSection>
      </Container>
    </ModalBase>
  );
};

const Container = styled.div`
  width: 600px;
  /* height: 700px; */
  border-radius: 8px;
  z-index: 101;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const HeaderSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 32px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontsize.table_header};
  background-color: ${({ theme }) => theme.colors.brightNavy};
  color: ${({ theme }) => theme.colors.white};
`;

const HeaderButtonSection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .button {
    cursor: pointer;
  }
`;

const UploadSection = styled.div<{ isDragOver: boolean }>`
  /* width: 370px; */
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--green);
  border-radius: 10px;
  background-color: ${(props) =>
    props.isDragOver ? '#ada8a8' : 'transparent'};
`;

const IconSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-size: 22px;
  color: black;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: 18px;
  color: gray;
  font-weight: 700;
`;
