// 카카오 주소 검색 결과 항목 타입 정의
interface AddressSearchResult {
  road_address: {
    address_name: string;
    zone_no: string;
  };
}

// 상태 코드 정의 (카카오 API에서 제공하는 상태 코드 사용)
type AddressSearchStatus = 'OK' | 'ZERO_RESULT' | 'ERROR' | 'INVALID_REQUEST';

// Kakao 주소 API에서 우편번호를 가져오는 함수
export const fetchPostCodeFromKakaoAPI = async (
  address: string[]
): Promise<{ zoneCode: string; address: string }[]> => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.kakao.maps.services.Geocoder();

    geocoder.addressSearch(
      address,
      (result: AddressSearchResult[], status: AddressSearchStatus) => {
        if (status === 'OK') {
          console.log('result', result);
          resolve(
            result.map((data) => ({
              zoneCode: data.road_address.zone_no,
              address: data.road_address.address_name,
            }))
          ); // 우편번호 반환
        } else {
          reject(new Error('Failed to fetch postcode'));
        }
      }
    );
  });
};

// 주소 검색 창 열기 (사용자가 직접 검색)
export const openAddressSearch = (
  callback: (address: string, postCode: string) => void
) => {
  new window.daum.Postcode({
    oncomplete: (data) => {
      callback(data.address, data.zonecode); // 주소와 우편번호를 콜백으로 전달
    },
  }).open();
};
